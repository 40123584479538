import { createSlice } from '@reduxjs/toolkit'
 
const initialState = {
    inputBox: "mb-[5px] border border-2 border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
}
 
export const styleConfigSlice = createSlice({
  name: 'styleConfig',
  initialState:initialState,
  reducers: {
    setInputbox: (state, action) => {
        state.inputBox = action.payload
    },
    setLoginInputbox: (state, action) => {
        state.loginInputBox = action.payload
    },
  },
})
 
// Action creators are generated for each case reducer function
export const { setInputbox, setLoginInputbox } = styleConfigSlice.actions
 
export default styleConfigSlice.reducer