import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { productListEndPoint, salesExecutivesEndPoint, targetTypeListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { useKeyPress } from "../../../hooks/useKeyPress";
import SalesExecutiveDetails from "./salesexecutivedetails";
import SalesTargetDetails from "./salestargetdetails";
import Popup from "../../../components/Popup";
import { priceTargetType, productQuantityTargetType, quantityTargetType } from "../../../constant";
import { isValidForm } from "../../../utilities/formvalidation";
import { converttojson } from "../../../utilities/jsonvalidation";
import { toast } from "react-hot-toast";

export default function ViewSalesExecutiveDetails() {

    const navigate = useNavigate();

    const [currentExecutive, setCurrentExecutive] = useState({});

    const [tab, setTab] = useState(1);
    const [targetPopup, setTargetPopup] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [targetTypeDropDown, setTargetTypeDropDown] = useState([])
    const [prodctDropDown, setProductDropDown] = useState([]);

    const [selectedTargetType, setSelectedTargetType] = useState("")
    const [selectedProduct, setSelectedProduct] = useState({})

    const [particulars, setParticulars] = useState([]);

    const [targetFormValues, setTargetFormValues] = useState({
        from_date: "",
        to_date: "",
        target_type: "",
        price: "",
        quantity: "",
        particulars: []
    })

    const [targetFormErrors, setTargetFormErrors] = useState({})

    function closeTargetPopup() {
        setTargetPopup(!targetPopup)
    }

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            setTargetPopup(!targetPopup)
        } else if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('mainformsubmit').click()
        } else if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('productformsubmit').click()
        }
    };

    useKeyPress(['Escape', 'm', 's', 'Enter'], onKeyPress);

    useEffect(() => {
        getSalesExecutiveDetails()
        getTargetTypeDropDown()
        getProductDropDown()
    }, [])

    const getSalesExecutiveDetails = async () => {
        var response = await getAxios({
            url: salesExecutivesEndPoint + localStorage.getItem('sales_executive_id') + '/'
        })

        if (response != null) {
            setCurrentExecutive(response.data)
            setIsLoading(false)
        }
    }

    const getTargetTypeDropDown = async () => {
        var response = await getAxios({
            url: targetTypeListEndPoint
        })

        if (response != null) {
            setTargetTypeDropDown(response.data.list)
        }
    }

    const getProductDropDown = async () => {
        var response = await getAxios({
            url: productListEndPoint
        })

        if (response != null) {
            setProductDropDown(response.data.list)
        }
    }

    const addProduct = (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setTargetFormErrors(err)

        if (isValidForm(formDataObject)) {
            let selected_data = {
                product: converttojson(formDataObject.product)?.id,
                product_code: converttojson(formDataObject.product)?.product_code,
                product_name: converttojson(formDataObject.product)?.product_name,
                nt_weight: converttojson(formDataObject.product)?.nt_weight,
                measurement: converttojson(formDataObject.product)?.measurement,
                image: converttojson(formDataObject.product)?.image,
                quantity: formDataObject.quantity
            }

            setParticulars([...particulars, selected_data])
            setSelectedProduct({
                product: "",
                quantity: ""
            })
        }
    }

    const onValueUpdate = (text) => {
        const { name, value } = text;

        if (name === 'product') {
            setSelectedProduct((prevState) => ({
                ...prevState,
                [name]: JSON.parse(value)
            }))

            setTargetFormErrors((prevState) => ({
                ...prevState,
                [name]: JSON.parse(value).length === 0 ? `${name} field is required` : ""
            }));
        } else {
            setSelectedProduct((prevState) => ({
                ...prevState,
                [name]: value
            }))

            setTargetFormErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ""
            }));
        }
    }

    const onTargetFormValueUpdate = (text) => {
        const { name, value } = text;

        if (name === 'target_type') {
            setTargetFormValues((prevState) => ({
                ...prevState,
                [name]: value
            }))

            setSelectedTargetType(value)

            setTargetFormErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ""
            }));
        } else {
            setTargetFormValues((prevState) => ({
                ...prevState,
                [name]: value
            }))

            setTargetFormErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ""
            }));
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        if (String(formDataObject.target_type) === String(priceTargetType)) {
            formDataObject['price'] = targetFormValues.price 
        } else if (String(formDataObject.target_type) === String(quantityTargetType)) {
            formDataObject['quantity'] = targetFormValues.quantity 
        }

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setTargetFormErrors(err)

        if (isValidForm(formDataObject)) {
            let selected_data = {
                from_date: formDataObject.from_date,
                to_date: formDataObject.to_date,
                target_type: formDataObject.target_type,
                executive: localStorage.getItem('sales_executive_id')
            }

            if (String(formDataObject.target_type) === String(priceTargetType)) {
                selected_data['price'] = formDataObject.price 
            } else if (String(formDataObject.target_type) === String(quantityTargetType)) {
                selected_data['quantity'] = formDataObject.quantity 
            } else if (String(formDataObject.target_type) === String(productQuantityTargetType)) {
                selected_data['particulars'] = particulars 
            } 
            
            createTarget(selected_data)
        }
    }

    const createTarget = async (body) => {
        var response = await postAxiosWithToken({
            url: salesExecutivesEndPoint,
            body: body
        })

        if (response !== null) {
            clearTargetFormValues()
            setTargetPopup(!targetPopup)
            setTab(2)
            getSalesExecutiveDetails();
            toast.success(response.message)
        }
    }

    const clearTargetFormValues = () => {
        setTargetFormValues({
            from_date: "",
            to_date: "",
            target_type: "",
            price: "",
            quantity: "",
            particulars: []
        })
        setParticulars([])
    }

    return (
        <div>
            <div className="flex gap-[10px] border p-[5px] sm:p-[10px] mb-[25px] rounded-lg">
                <div onClick={() => setTab(1)} className={`${tab === 1 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Customer Details</div>
                <div onClick={() => setTab(2)} className={`${tab === 2 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Target History</div>
            </div>

            <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Sales Executive Details</p>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px] font-[600]">Sales Executive</p>
                        <div>{currentExecutive?.first_name}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Mobile No</p>
                        <div>{currentExecutive?.phone}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Email</p>
                        <div>{currentExecutive.email}</div>
                    </div>
                </div>
            </div>

            {
                tab === 1 && <SalesExecutiveDetails executiveDetails={currentExecutive} />
            }

            {
                tab === 2 && <SalesTargetDetails isLoading={isLoading} getData={getSalesExecutiveDetails} executiveDetails={currentExecutive} targetPopup={targetPopup} setTargetPopup={setTargetPopup} />
            }

            <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 mb-[50px]">
                <button
                    id="backnav"
                    className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                    onClick={(e) => { e.preventDefault(); navigate(-1) }}
                >close<span className="lowercase">(esc)</span></button>
            </div>

            <div>
                <Popup isOpen={targetPopup} closeModal={closeTargetPopup} title={"Assign Target"}>
                    <div className="relative h-[83vh] ">
                        <form onSubmit={onSubmit} id="mainform">
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Target Type</p>
                                <select
                                    placeholder="target type"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="target_type"
                                    value={targetFormValues.target_type}
                                    onChange={(e) => onTargetFormValueUpdate(e.target)}
                                >
                                    <option value={""}>--select type--</option>
                                    {
                                        targetTypeDropDown.map((option) => (
                                            <option className="capitalize" key={"target_type" + option.name} value={option.id}>{option.target_type_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{targetFormErrors.target_type}</p>
                            </div>
                            <div className="min-[435px]:flex items-center gap-[10px]">
                                <div className="my-[5px] w-full">
                                    <p className="mb-[5px] text-[15px]">From Date</p>
                                    <input
                                        type="date"
                                        placeholder="from date"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="from_date"
                                        value={targetFormValues.from_date}
                                        onChange={(e) => onTargetFormValueUpdate(e.target)}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{targetFormErrors.from_date}</p>
                                </div>

                                <div className="my-[5px] w-full">
                                    <p className="mb-[5px] text-[15px]">To Date</p>
                                    <input
                                        type="date"
                                        placeholder="to date"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="to_date"
                                        value={targetFormValues.to_date}
                                        onChange={(e) => onTargetFormValueUpdate(e.target)}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{targetFormErrors.to_date}</p>
                                </div>
                            </div>

                            <div className="min-[435px]:flex gap-[15px] absolute bottom-0 w-full">
                                <button
                                    onClick={(e) => { e.preventDefault(); setTargetPopup(!targetPopup); }}
                                    className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                                >Close<span className="lowercase">(ctrl+m)</span></button>
                                <button
                                    type="submit"
                                    id="mainformsubmit"
                                    className="max-[435px]:mt-[15px] capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                                >save<span className="lowercase">(ctrl+s)</span></button>
                            </div>
                        </form>
                        <form id="pricequantityform" className={`${String(selectedTargetType) !== String(productQuantityTargetType) && selectedTargetType !== "" ? "block" : "hidden"}`} >

                            <div className={`${String(selectedTargetType) === String(priceTargetType) ? "block" : "hidden"} my-[5px]`}>
                                <p className="mb-[5px] text-[15px]">Price</p>
                                <input
                                    type="text"
                                    placeholder="price"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="price"
                                    value={targetFormValues.price}
                                    onChange={(e) => onTargetFormValueUpdate(e.target)}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{targetFormErrors.price}</p>
                            </div>

                            <div className={`${String(selectedTargetType) === String(quantityTargetType) ? "block" : "hidden"} my-[5px]`}>
                                <p className="mb-[5px] text-[15px]">Quantity</p>
                                <input
                                    type="text"
                                    placeholder="quantity"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="quantity"
                                    value={targetFormValues.quantity}
                                    onChange={(e) => onTargetFormValueUpdate(e.target)}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{targetFormErrors.quantity}</p>
                            </div>


                        </form>

                        <form id="productquantityform" onSubmit={addProduct} className={`${String(selectedTargetType) === String(productQuantityTargetType) && selectedTargetType !== "" ? "block" : "hidden"}`}>


                            <div className={` my-[5px]`}>

                                <div className="sm:my-[5px] ">
                                    <p className="mb-[5px] text-[15px]">Product</p>
                                    <select
                                        placeholder="product"
                                        className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                        name="product"
                                        value={JSON.stringify(selectedProduct?.product)}
                                        onChange={(e) => onValueUpdate(e.target)}
                                    >
                                        <option value={""}>--select product--</option>
                                        {
                                            prodctDropDown.map((option) => (
                                                <option className="capitalize flex justify-between items-center w-full" key={"product" + option.name} value={JSON.stringify(option)}>
                                                    <div>{option.product_name} ({option.nt_weight} {option.measurement})</div>
                                                    <div>#{option.product_code}</div>
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <p className="text-red-500 font-[400] text-[13px]">{targetFormErrors.product}</p>
                                </div>

                                <div className={` my-[5px]`}>
                                    <p className="mb-[5px] text-[15px]">Quantity</p>
                                    <input
                                        type="text"
                                        placeholder="quantity"
                                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                        name="quantity"
                                        value={selectedProduct?.quantity}
                                        onChange={(e) => onValueUpdate(e.target)}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{targetFormErrors.quantity}</p>
                                </div>
                            </div>

                            <button
                                id="productformsubmit"
                                className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            >Add Product<span className="lowercase">(enter)</span></button>
                        </form>

                        <div className={`${String(selectedTargetType) === String(productQuantityTargetType) && selectedTargetType !== "" ? "block" : "hidden"} mt-[15px] h-[25%] overflow-y-scroll`}>
                            {
                                particulars.map((item, index) => (
                                    <div key={'particulars' + index} className="flex gap-[15px] my-[10px]">
                                        <div className="border rounded-lg bg-gray-400 shadow-lg min-w-[50px] max-w-[50px] h-[50px]">
                                            <img src={item.image} alt={item.image} width={"100%"} />
                                        </div>

                                        <div className="capitalize">
                                            <p>{item.product_name}</p>
                                            <p>#{item.product_code}</p>
                                        </div>
                                        <div>
                                            <p>Target Quantity</p>
                                            <p>{item.quantity}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>


                </Popup>
            </div>
        </div>
    );
}