import { useEffect, useRef, useState } from "react";
import { userRoleEndPoint, userRoleListEndPoint, userRoleStatusEndPoint } from "../../../service/api";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import UserRoleForm from "./userroleform";
import { toast } from "react-hot-toast";
import Popup from "../../../components/Popup";
import SwitchButton from "../../../components/Switch";

export default function UserRole() {

    const [userRolesList, setUserRoleList] = useState([]);
    const [curUserRole, setCurrentUserRole] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getUserRoleData({})
    }, [])

    const getUserRoleData = async (body) => {
        var response = await postAxiosWithToken({
            url: userRoleListEndPoint,
            body: body
        })

        if (response !== null) {
            setUserRoleList(response.data.list)
            setIsLoading(false)
        }
    }

    const onStatusChange = async (id) => {
        var response = await getAxios({
            url: userRoleStatusEndPoint + id + '/'
        })

        if (response !== null) {
            getUserRoleData()
            toast.success(response.message)
        }
    }

    const deleteUserRole = async () => {
        var response = await deleteAxiosWithToken({
            url: userRoleEndPoint + curUserRole.id + '/'
        })

        if (response !== null) {
            getUserRoleData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    return (
        <>
            <div>
                <div>
                    <UserRoleForm getData={getUserRoleData} formMode={formMode} setFormMode={setFormMode} curUserRole={curUserRole} setCurrentUserRole={setCurrentUserRole} />
                </div>
                <div className="my-[10px] w-full grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-5">
                    {
                        isLoading ? 
                        <div className="text-center">Loading...</div> :
                    userRolesList.length === 0 ? <div className="text-center">No Data</div> :
                        userRolesList.map((item) => (
                            <div className="flex min-w-[290px] md:min-w-[23%] h-[60px] justify-between items-center border p-[10px] shadow-lg rounded-lg">
                                <div className="min-w-[150px]">{item.role_name}</div>
                                <div onClick={() => onStatusChange(item.id)}>
                                                        <SwitchButton enabled={item.is_active} />
                                                    </div>
                                <div className="flex gap-3">
                                    <div onClick={() => { setCurrentUserRole(item); setFormMode('update'); document.getElementById('forminit').focus() }} className="cursor-pointer hover:scale-125">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                    </div>
                                    <div onClick={() => { setCurrentUserRole(item);  setIsDeleteOpen(true) }} className="cursor-pointer hover:scale-125">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div>
                    <Popup title={'Delete User Role'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                        <div className="mt-[10px]">
                            <p className="font-400 text-[15px]">Do you want to delete the {curUserRole?.role_name} role?</p>
                            <div className="mt-[10px] flex gap-3 justify-end items-center">
                                <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                                <button type="submit" onClick={() => { deleteUserRole() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                            </div>
                        </div>
                    </Popup>
                </div>
            </div>
        </>
    );
}