import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAxios } from "../../../service/apiservice";
import { staffEndPoint } from "../../../service/api";
import { converttojson } from "../../../utilities/jsonvalidation";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function ViewStaffDetails() {

    const navigate = useNavigate();

    const [currentStaffDetails, setCurrentStaffDetails] = useState({});

    useEffect(() => {
        getStaffDetails()
    },[])

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            navigate(-1)
        }
    };


    useKeyPress(['Escape'], onKeyPress);

    const getStaffDetails = async () => {
        var response = await getAxios({
            url: staffEndPoint + localStorage.getItem('staff_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                first_name: response.data?.first_name,
                last_name: response.data?.last_name,
                email: response.data?.email,
                phone: response.data?.phone,
                city: response.data?.city,
                state: response.data?.state,
                country: response.data?.country,
                address: response.data?.address,
                pincode: response.data?.pincode,
                aadhar_card: response.data?.aadhar_card,
                pan_card: response.data?.pan_card,
                location: response.data?.location_name,
                branch: response.data?.branch_name,
                department: response.data?.department_name,
                designation: response.data?.designation_name,
            }

            setCurrentStaffDetails(tempDetails)
        }
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">User Details</p>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Staff Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">First Name</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.first_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Last Name</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.last_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Email</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.email}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Phone no</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.phone}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Location</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.location}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Branch</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.branch}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Department</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.department}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Designation</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.designation}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Address Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Address</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.address}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Country</p>
                                <p className=" font-[400] text-[15px]">{converttojson(currentStaffDetails.country)?.name}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">State</p>
                                <p className=" font-[400] text-[15px]">{converttojson(currentStaffDetails.state)?.name}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">city</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.city}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Pincode</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.pincode}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Other Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Pan no</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.pan_card}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Aadhar no</p>
                                <p className=" font-[400] text-[15px]">{currentStaffDetails.aadhar_card}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); navigate(-1) }}
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                        >close<span className="lowercase">(esc)</span></button>
                    </div>
            </div>
        </>
    );
}