import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useEffect, useRef, useState } from "react";
import { productListBySupplierEndPoint, purchaseOrderByBatchEndPoint, purchaseReturnEndPoint, supplierListEndPoint } from "../../../service/api";
import { isValidForm } from "../../../utilities/formvalidation";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { toast } from "react-hot-toast";

export default function PurchaseReturnForm() {

    const navigate = useNavigate();

    const gstDropDowRef = useRef(null);

    const [formMode, setFormMode] = useState('create');
    const [currentPurchase, setCurrentPurchase] = useState({
        supplier: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        order_req_date: ""
    });

    const [supplierDropDown, setSupplierDropDown] = useState([]);
    const [productDropDown, setProductDropDown] = useState([]);

    const [currentPurchaseErrors, setCurrentPurchaseErrors] = useState({});

    const [selectedParticulars, setSelectedParticulars] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState({
        product: "",
        product_name: "",
        division_name: "",
        category_name: "",
        sub_category_name: "",
        brand_name: "",
        measurement: "",
        purchase_price: "",
        quantity: "",
        nt_weight: "",
        gst_value: [],
        gst_percent: 0,
        total_price: ""
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('purcahseformsubmit').click()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            clearData();
        } else if (event.key === 'Enter') {
            document.getElementById('productsubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['s', 'e', 'Enter', 'Escape'], onKeyPress);

    useEffect(() => {
        getSupplierDropdownData();
    }, [])

    const getSupplierDropdownData = async () => {
        var response = await getAxios({
            url: supplierListEndPoint
        })

        if (response !== null) {
            setSupplierDropDown(response.data.list)
        }
    }

    const getProductListDropDown = async (value) => {
        var response = await getAxios({
            url: productListBySupplierEndPoint + value?.id + '/'
        })

        if (response !== null) {
            setProductDropDown(response.data.list)
            setCurrentPurchase({ ...currentPurchase, supplier: value })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (localStorage.getItem('purchase_return_form_mode') === 'create') {

            let formFields = new FormData(e.target);
            let formDataObject = Object.fromEntries(formFields.entries());

            var err = {}

            for (var field of Object.entries(formDataObject)) {
                if (field[1].length === 0) {
                    err[field[0]] = `${field[0]} field is required`
                } else {
                    err[field[0]] = ""
                }
            }

            setCurrentPurchaseErrors(err)
            console.log(isValidForm(formDataObject))

            if (isValidForm(formDataObject) && selectedParticulars.length > 0) {

                let createBody = {
                    supplier: currentPurchase?.supplier.id,
                    total_items: selectedParticulars.length,
                    particulars: []
                }

                for (var i of selectedParticulars) {
                    var tempDict = {
                        stock_id: i.stock_id,
                        quantity: i.return_quantity
                    }
                    createBody.particulars.push(tempDict)
                }

                addPurchaseReturn(createBody)
            }
        } else {

        }
    }

    const clearData = () => {
        setSelectedProduct({
            product: "",
            product_name: "",
            division_name: "",
            category_name: "",
            sub_category_name: "",
            brand_name: "",
            measurement: "",
            purchase_price: "",
            quantity: "",
            nt_weight: "",
            gst_value: [],
            gst_percent: 0,
            total_price: "",
            batch_no: "",
            mfg_date: "",
            exp_date: ""
        })
    }

    useEffect(() => {
        window.addEventListener('click', function handleClickOutsideBox(event) {
            const box = document.getElementById('gstinput');

            if (!box?.contains(event.target)) {
                if (gstDropDowRef.current) {

                    gstDropDowRef.current.className = 'hidden'
                }
            }
        });
    })

    const addProduct = (e) => {
        e.preventDefault();

        if (selectedProduct?.product_name !== "") {

            let body = {
                batch_no: selectedProduct.batch_no,
                exp_date: selectedProduct.exp_date,
                mfg_date: selectedProduct.mfg_date,
                product: selectedProduct.id
            }
            getPurchaseOrders(body)
            
        }


    }

    const getPurchaseOrders = async (body) => {
        var response = await postAxiosWithToken({
            url: purchaseOrderByBatchEndPoint,
            body: body
        })

        if (response !== null) {
            
            if (response.data.length !== 0) {

                var tempList = []
                for (var i of response.data) {
                    var item = selectedProduct
                    item['purchase_order'] = i.purchase_order
                    item['purchase_order_id'] = i.purchase_order__purchase_order_id
                    item['quantity'] = i.quantity
                    item['stock_id'] = i.id
                    tempList.push(item)
                }
                let tempData = []

                for (var i of tempList) {
                    const isContains = (object) => {
                        return (object.purchase_order === i.purchase_order && object.batch_no === i.batch_no && object.mfg_date === i.mfg_date && object.exp_date === i.exp_date && object.product === i.product) ? true : false;
                    }

                    if (selectedParticulars.find(isContains)) {
                        console.log(i)
                    } else {
                        tempData.push(i)
                    }
                }
                
                setSelectedParticulars([...selectedParticulars, ...tempData]);
                clearData()
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }

        }
    }

    const removeParticular = (index) => {
        const data = [...selectedParticulars];
        data.splice(index, 1);
        setSelectedParticulars(data);
    }

    const addPurchaseReturn = async (body) => {
        var response = await postAxiosWithToken({
            url: purchaseReturnEndPoint,
            body: body
        })

        if (response !== null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    return (
        <>
            <div className="mb-[50px]  pb-[70px] relative">
            <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Purchase Return</p>
                <form onSubmit={onSubmit}>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Supplier</p>
                                <select
                                    placeholder="supplier"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="supplier"
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            getProductListDropDown(JSON.parse(e.target.value))
                                        } else {
                                            setCurrentPurchase({})
                                        }
                                    }}
                                    value={currentPurchase.supplier === "" ? "" : JSON.stringify(currentPurchase.supplier)}
                                >
                                    <option value={""}>--select supplier--</option>
                                    {
                                        supplierDropDown.map((option) => (
                                            <option className="capitalize" key={"supplier" + option.supplier_name} value={JSON.stringify(option)}>{option.supplier_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentPurchaseErrors.supplier}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Supplier Id</p>
                                <input
                                    type="text"
                                    placeholder="supplier id"
                                    className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="supplier_id"
                                    value={currentPurchase?.supplier?.supplier_id}
                                    disabled
                                />
                                
                            </div>
                        </div>
                    </div>

                    <div className="absolute bottom-0 right-0 flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            onClick={(e) => { e.preventDefault(); clearData(); navigate(-1) }}
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="purcahseformsubmit"
                            className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>

                <form onSubmit={addProduct}>
                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Add Particulars</p>
                        <div className="grid sm:grid-cols-2 gap-5">
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Product</p>
                                <select
                                    placeholder="product"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="product"
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            var tempData = JSON.parse(e.target.value)
                                            setSelectedProduct({ ...tempData, quantity: 1, gst_percent: 0, total_price: tempData.purchase_price });
                                        } else {
                                            setSelectedProduct("");
                                        }
                                    }}
                                    value={selectedProduct.product}
                                >
                                    <option value={""}>--select product--</option>
                                    {
                                        productDropDown.map((option) => (
                                            <option className="capitalize" key={"product" + option.product_name} value={JSON.stringify(option)}>{option.product_name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Batch No</p>
                                <input
                                    type="text"
                                    placeholder="batch no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="batch_no"
                                    onChange={(e) => {setSelectedProduct({...selectedProduct, batch_no: e.target.value})}}
                                    value={selectedProduct.batch_no}
                                />
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Mfg Date</p>
                                <input
                                    type="date"
                                    placeholder="mfg date"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="mfg_date"
                                    onChange={(e) => {setSelectedProduct({...selectedProduct, mfg_date: e.target.value})}}
                                    value={selectedProduct.mfg_date}
                                />
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Expiry Date</p>
                                <input
                                    type="date"
                                    placeholder="expiry date"
                                    className=" sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="exp_date"
                                    onChange={(e) => {setSelectedProduct({...selectedProduct, exp_date: e.target.value})}}
                                    value={selectedProduct.exp_date}
                                />
                            </div>

                        </div>
                        <div className="min-[423px]:flex gap-4 mt-0 sm:mt-[35px]">

                            <button
                                onClick={(e) => { e.preventDefault(); clearData(); }}
                                className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                            >Reset<span className="lowercase">(ctrl+e)</span></button>

                            <button
                                id="productsubmit"
                                type="submit"
                                className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                            >Add<span className="lowercase">(enter)</span></button>
                        </div>
                    </div>
                </form>

                <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Particulars</p>
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr className="border">
                                <td className="p-[10px] w-[50px] text-center">Actions</td>
                                <td className="p-[10px] w-[200px]">Product</td>
                                <td className="p-[10px] w-[100px]">Batch No</td>
                                <td className="p-[10px] w-[100px]">Mfg Date</td>
                                <td className="p-[10px] w-[100px]">Expiry Date</td>
                                <td className="p-[10px] w-[100px]">Purchase Id</td>
                                <td className="p-[10px] w-[100px]">Available</td>
                                <td className="p-[10px] w-[100px]">Quantity</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedParticulars.map((item, index) => (
                                    <tr className="border">
                                        <td className="p-[10px] w-[50px] ">
                                            <div onClick={() => removeParticular(index)} className="cursor-pointer hover:scale-125 w-fit mx-auto">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                            </div>
                                        </td>
                                        <td className="p-[10px] w-[200px]">{item.product_name}</td>
                                        <td className="p-[10px] w-[100px]">{item.batch_no}</td>
                                        <td className="p-[10px] w-[100px]">{item.mfg_date}</td>
                                        <td className="p-[10px] w-[100px]">{item.exp_date}</td>
                                        <td className="p-[10px] w-[100px]">{item.purchase_order_id}</td>
                                        <td className="p-[10px] w-[100px]">{item.quantity}</td>
                                        <td className="p-[10px] w-[100px]">
                                            <input 
                                            type="number"
                                            className="border rounded-lg p-[5px] w-[70px]"
                                            name="return_quantity"
                                            onChange={(e) => {
                                                var data = [...selectedParticulars]
                                                data[index][e.target.name] = e.target.value
                                                setSelectedParticulars(data)
                                            }}
                                            value={item.return_quantity}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}