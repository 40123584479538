import { useEffect, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { brandEndPoint, brandListEndPoint, brandStatusEndPoint   } from "../../../service/api";
import { datetimetodate } from "../../../utilities/datetime";
import { toast } from "react-hot-toast";
import Popup from "../../../components/Popup";
import SwitchButton from "../../../components/Switch";
import Pagination from "../../../components/Pagination";
import BrandForm from "./brandform";

export default function Brand() {

    const [subCategoryList, setBrandList] = useState([]);
    const [currentBrand, setCurrentBrand] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [search, setSearch] = useState("");
    const [searchDivision, setSearchDivision] = useState("");
    const [searchCategory, setSearchCategory] = useState("");
    const [searchSubCategory, setSearchSubCategory] = useState("");
    const [status, setStatus] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        let defaultBody = {
            active_status: status === "" ? null : JSON.parse(status),
            division: searchDivision,
            category: searchCategory,
            sub_category: searchSubCategory,
            search: search,
            page: currentPage,
            items_per_page: perPage
        }
        getSubCategoryListData(defaultBody)
    }, [])

    const getSubCategoryListData = async (body) => {
        var response = await postAxiosWithToken({
            url: brandListEndPoint,
            body: body
        })

        if (response !== null) {
            setBrandList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    const onStatusChange = async (id) => {
        var response = await getAxios({
            url: brandStatusEndPoint + id + '/'
        })

        if (response !== null) {
            if (response.status === 200) {
                getSubCategoryListData()
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
        }
    }

    const deleteBrand = async () => {
        var response = await deleteAxiosWithToken({
            url: brandEndPoint + currentBrand.id + '/'
        })

        if (response !== null) {
            getSubCategoryListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    // Filter Functionalities

    const statusHandler = async (e) => {
        let statusBody = {
            active_status: e.target.value === "" ? null : JSON.parse(e.target.value),
            division: searchDivision,
            category: searchCategory,
            sub_category: searchSubCategory,
            search: search,
            page: 1,
            items_per_page: perPage
        }
        setStatus(e.target.value === "" ? null : JSON.parse(e.target.value))
        setCurrentPage(1)
        getSubCategoryListData(statusBody)
    }

    const divisionSearchHandler = async (e) => {
        let statusBody = {
            active_status: status === "" ? null : JSON.parse(status),
            division: e.target.value,
            category: searchCategory,
            sub_category: searchSubCategory,
            search: search,
            page: 1,
            items_per_page: perPage
        }
        setSearchDivision(e.target.value)
        setCurrentPage(1)
        getSubCategoryListData(statusBody)
    }

    const categorySearchHandler = async (e) => {
        let statusBody = {
            active_status: status === "" ? null : JSON.parse(status),
            division: searchDivision,
            category: e.target.value,
            sub_category: searchSubCategory,
            search: search,
            page: 1,
            items_per_page: perPage
        }
        setSearchCategory(e.target.value)
        setCurrentPage(1)
        getSubCategoryListData(statusBody)
    }

    const subCategorySearchHandler = async (e) => {
        let statusBody = {
            active_status: status === "" ? null : JSON.parse(status),
            division: searchDivision,
            category: searchCategory,
            sub_category: e.target.value,
            search: search,
            page: 1,
            items_per_page: perPage
        }
        setSearchCategory(e.target.value)
        setCurrentPage(1)
        getSubCategoryListData(statusBody)
    }

    const searchHandler = async (e) => {
        let statusBody = {
            active_status: status === "" ? null : JSON.parse(status),
            division: searchDivision,
            category: searchCategory,
            sub_category: searchSubCategory,
            search: e.target.value,
            page: 1,
            items_per_page: perPage
        }
        setSearch(e.target.value)
        setCurrentPage(1)
        getSubCategoryListData(statusBody)
    }



    // Pagination

    const pageHandler = async (page) => {
        let statusBody = {
            active_status: status === "" ? null : JSON.parse(status),
            division: searchDivision,
            category: searchCategory,
            sub_category: searchSubCategory,
            search: search,
            page: page,
            items_per_page: perPage
        }
        setCurrentPage(page)
        getSubCategoryListData(statusBody)
    }

    const entriesHandler = async (count) => {
        let statusBody = {
            active_status: status === "" ? null : JSON.parse(status),
            division: searchDivision,
            category: searchCategory,
            sub_category: searchSubCategory,
            search: search,
            page: 1,
            items_per_page: count
        }
        setPerPage(count)
        setCurrentPage(1)
        getSubCategoryListData(statusBody)
    }

    return (
        <>
            <div>
                <div>
                    <BrandForm getData={getSubCategoryListData} formMode={formMode} setFormMode={setFormMode} currentBrand={currentBrand} setCurrentBrand={setCurrentBrand} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px]">Brand Name</td>
                                <td className="p-[10px]">Division</td>
                                <td className="p-[10px]">Category</td>
                                <td className="p-[10px]">SubCategory</td>
                                <td className="p-[10px]">Status</td>
                                <td className="p-[10px]">Created At</td>
                                <td className="p-[10px]">Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='border'>
                                <td className="p-[10px]"></td>
                                <td className="p-[10px]">
                                    <input
                                        type="text"
                                        value={search}
                                        onChange={searchHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px]">
                                    <input
                                        type="text"
                                        value={searchDivision}
                                        onChange={divisionSearchHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px]">
                                    <input
                                        type="text"
                                        value={searchCategory}
                                        onChange={categorySearchHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px]">
                                    <input
                                        type="text"
                                        value={searchSubCategory}
                                        onChange={subCategorySearchHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px]">
                                    <select className="w-full border bg-white outline-none rounded-md py-[7px] px-[10px]" value={status} onChange={statusHandler}>
                                        <option value={""}>All</option>
                                        <option value={true}>Active</option>
                                        <option value={false}>Inactive</option>
                                    </select>
                                </td>
                                <td className="p-[10px]"></td>
                                <td className="p-[10px]"></td>
                            </tr>
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={8}>Loading...</td>
                                </tr> :
                                    subCategoryList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                        subCategoryList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px] capitalize">{item.brand_name}</td>
                                                <td className="p-[10px] capitalize">{item.division_name}</td>
                                                <td className="p-[10px] capitalize">{item.category_name}</td>
                                                <td className="p-[10px] capitalize">{item.sub_category_name}</td>
                                                <td className="p-[10px]">
                                                    <div onClick={() => onStatusChange(item.id)}>
                                                        <SwitchButton enabled={item.is_active} />
                                                    </div>
                                                </td>
                                                <td className="p-[10px]">{datetimetodate(item.created_at)}</td>
                                                <td className="p-[10px]">
                                                    <div className="flex gap-3">
                                                        <div onClick={() => { setCurrentBrand(item); setFormMode('update'); document.getElementById('forminit').focus() }} className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div>
                                                        <div onClick={() => { setCurrentBrand(item); setIsDeleteOpen(true) }} className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>

                <div className="mb-[50px]">
                    <Pagination
                    totalPages={totalPages} 
                    currentPage={currentPage} 
                    setCurrentPage={pageHandler} 
                    entries={perPage} 
                    setEntries={entriesHandler}
                     />
                </div>
            </div>
            <div>
                <Popup title={'Delete User Role'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentBrand?.brand_name} brand?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteBrand() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}