import { statusTypeListEndPoint } from "./api"
import { postAxiosWithToken } from "./apiservice"

export const getStatusTypeDropdownData = async (body, setState) => {
    var response = await postAxiosWithToken({
        url: statusTypeListEndPoint,
        body: body
    })

    if (response !== null) {
        setState(response.data.list)
    } else {
        setState([])
    }
}
