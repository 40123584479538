import Branch from "../pages/organization/branches"
import Department from "../pages/organization/department"
import Designation from "../pages/organization/designation"
import Locations from "../pages/organization/locations"
import Staff from "../pages/organization/staff"
import StaffForm from "../pages/organization/staff/staffform"
import User from "../pages/organization/user"
import UserForm from "../pages/organization/user/userform"
import Brand from "../pages/product/brand"
import Category from "../pages/product/category"
import Division from "../pages/product/division"
import SubCategory from "../pages/product/subcategory"
import GstValue from "../pages/product/gstvalue"
import Unit from "../pages/product/unit"
import Menu from "../pages/settings/menu"
import MenuGroup from "../pages/settings/menugroup"
import MenuPermission from "../pages/settings/menupermission"
import UserRole from "../pages/settings/userrole"
import ProductForm from "../pages/product/product/productform"
import SupplierForm from "../pages/vendor/supplier/supplierform"
import Supplier from "../pages/vendor/supplier"
import Product from "../pages/product/product"
import ApprovalType from "../pages/approval/approvaltype"
import ApprovalRule from "../pages/approval/approvalrule"
import PurchaseRequirements from "../pages/purchase/purchaserequirements"
import PurchaseRequirementsForm from "../pages/purchase/purchaserequirements/purchaserequirementsform"
import ViewPurchaseRequirements from "../pages/purchase/purchaserequirements/viewpurchaserequirements"
import PurchaseRequirementsApproval from "../pages/approval/purchaserequirementsapproval"
import ViewPurchaseRequirementsApproval from "../pages/approval/purchaserequirementsapproval/viewpurchaserequirementsapproval"
import PurchaseOrdersForm from "../pages/purchase/purchaseorder/purchaseordersform"
import ViewPurchaseOrders from "../pages/purchase/purchaseorder/viewpurchaseorders"
import PurchaseOrders from "../pages/purchase/purchaseorder"
import PoPayment from "../pages/finance/popayment"
import PoPaymentForm from "../pages/finance/popayment/popaymentform"
import InStockForm from "../pages/stockmanagement/instock/instockform"
import ViewSupplierDetails from "../pages/vendor/supplier/viewsupplierdetails"
import InStock from "../pages/stockmanagement/instock"
import ViewInstockDetails from "../pages/stockmanagement/instock/viewinstockdetails"
import Stock from "../pages/stockmanagement/stock"
import ProductWiseStockDetails from "../pages/stockmanagement/stock/productwisestockdetails"
import ViewUserDetails from "../pages/organization/user/viewuserdetails"
import ViewStaffDetails from "../pages/organization/staff/viewstaffdetails"
import PurchaseReturn from "../pages/purchase/purchasereturn"
import PurchaseReturnForm from "../pages/purchase/purchasereturn/purchasereturnform"
import ViewProductDetails from "../pages/product/product/viewproductdetails"
import ViewPurchaseReturnDetails from "../pages/purchase/purchasereturn/viewpurchasereturndetails"
import Customers from "../pages/customers/customer"
import ViewCustomerDetails from "../pages/customers/customer/viewcustomer"
import CustomerForm from "../pages/customers/customer/customerform"
import SalesRequirementsForm from "../pages/sales/salesrequirements/salesrequirementform"
import SalesRequirements from "../pages/sales/salesrequirements"
import SalesRequirementsApproval from "../pages/approval/salesrequirementsapproval.jsx"
import ViewSalesRequirementsApproval from "../pages/approval/salesrequirementsapproval.jsx/viewsalesrequirementsapproval"
import SalesExecutive from "../pages/salesexecutive/salesexecutive"
import ViewSalesExecutiveDetails from "../pages/salesexecutive/salesexecutive/viewsalesexectivedetails"
import ViewSalesRequirements from "../pages/sales/salesrequirements/viewsalesrequirements"
import SalesOrder from "../pages/sales/salesorder"
import ViewSalesOrder from "../pages/sales/salesorder/viewsalesorder"
import SoPayment from "../pages/finance/sopayment"
import SoPaymentForm from "../pages/finance/sopayment/sopaymentform"
import OutStock from "../pages/stockmanagement/outstock"
import OutStockForm from "../pages/stockmanagement/outstock/outstockform"
import ViewOutstockDetails from "../pages/stockmanagement/outstock/viewinstockdetails"


export const AddedAuthenticatedRoutes = [
    {
        path: "/organizations/staff/staff-form",
        component: <StaffForm />
    },
    {
        path: "/organizations/staff/view-staff-details",
        component: <ViewStaffDetails />
    },
    {
        path: "/organizations/user/user-form",
        component: <UserForm />
    },
    {
        path: "/organizations/user/view-user-details",
        component: <ViewUserDetails />
    },
    {
        path: "/products/product/product-form",
        component: <ProductForm />
    },
    {
        path: "/products/product/view-product",
        component: <ViewProductDetails />
    },
    {
        path: "/vendors/supplier/supplier-form",
        component: <SupplierForm />
    },
    {
        path: "/vendors/supplier/view-supplier",
        component: <ViewSupplierDetails />
    },
    {
        path: "/purchase/purchase-requirements/purchase-requirements-form",
        component: <PurchaseRequirementsForm />
    },
    {
        path: "/purchase/purchase-requirements/view-purchase-requirements",
        component: <ViewPurchaseRequirements />
    },
    {
        path: "/approval/po-approval/view-purchase-requirements-approval",
        component: <ViewPurchaseRequirementsApproval />
    },
    {
        path: "/purchase/purchase-order/purchase-order-form",
        component: <PurchaseOrdersForm />
    },
    {
        path: "/purchase/purchase-order/view-purchase-order",
        component: <ViewPurchaseOrders />
    },
    {
        path: "/purchase/purchase-return/purchase-return-form",
        component: <PurchaseReturnForm />
    },
    {
        path: "/purchase/purchase-return/view-purchase-return",
        component: <ViewPurchaseReturnDetails />
    },
    {
        path: "/finance/po-payment/po-payment-form",
        component: <PoPaymentForm />
    },
    {
        path: "/stock-management/in-stock/in-stock-form",
        component: <InStockForm />
    },
    {
        path: "/stock-management/in-stock/view-in-stock-details",
        component: <ViewInstockDetails />
    },
    {
        path: "/stock-management/stock/view-stock-details",
        component: <ProductWiseStockDetails />
    },
    {
        path: "/stock-management/out-stock/out-stock-form",
        component: <OutStockForm />
    },
    {
        path: "/stock-management/out-stock/view-out-stock-details",
        component: <ViewOutstockDetails />
    },
    {
        path: "/customers/customers/customer-form",
        component: <CustomerForm />
    },
    {
        path: "/customers/customers/view-customer",
        component: <ViewCustomerDetails />
    },
    {
        path: "/sales/sales-requirements/sales-requirements-form",
        component: <SalesRequirementsForm />
    },
    {
        path: "/sales/sales-requirements/view-sales-requirements",
        component: <ViewSalesRequirements />
    },
    {
        path: "/sales/sales-order/view-sales-order",
        component: <ViewSalesOrder />
    },
    {
        path: "/approval/so-approval/view-sales-requirements-approval",
        component: <ViewSalesRequirementsApproval />
    },
    {
        path: "/finance/so-payment/so-payment-form",
        component: <SoPaymentForm />
    },
    {
        path: "/sales-executive/sales-executive/view-sales-executive",
        component: <ViewSalesExecutiveDetails />
    },
]


export const AuthenticatedComponents =  [
    {
        path: "menu-permission",
        component: <MenuPermission />
    },
    {
        path: "menu",
        component: <Menu />
    },
    {
        path: "user-role",
        component: <UserRole />
    },
    {
        path: "menu-group",
        component: <MenuGroup />
    },
    {
        path: "location",
        component: <Locations />
    },
    {
        path: "department",
        component: <Department />
    },
    {
        path: "designation",
        component: <Designation />
    },
    {
        path: "branch",
        component: <Branch />
    },
    {
        path: "staff",
        component: <Staff />
    },
    {
        path: "user",
        component: <User />
    },
    {
        path: "division",
        component: <Division />
    },
    {
        path: "category",
        component: <Category />
    },
    {
        path: "sub-category",
        component: <SubCategory />
    },
    {
        path: "brand",
        component: <Brand />
    },
    {
        path: "unit",
        component: <Unit />
    },
    {
        path: "gst-value",
        component: <GstValue />
    },
    {
        path: "product",
        component: <Product />
    },
    {
        path: "supplier",
        component: <Supplier />
    },
    {
        path: "approval-type",
        component: <ApprovalType />
    },
    {
        path: "approval-rule",
        component: <ApprovalRule />
    },
    {
        path: "po-approval",
        component: <PurchaseRequirementsApproval />
    },
    {
        path: "purchase-requirements",
        component: <PurchaseRequirements />
    },
    {
        path: "purchase-order",
        component: <PurchaseOrders />
    },
    {
        path: "purchase-return",
        component: <PurchaseReturn />
    },
    {
        path: "po-payment",
        component: <PoPayment />
    },
    {
        path: "in-stock",
        component: <InStock />
    },
    {
        path: "stock",
        component: <Stock />
    },
    {
        path: "out-stock",
        component: <OutStock />
    },
    {
        path: "customers",
        component: <Customers />
    },
    {
        path: "sales-requirements",
        component: <SalesRequirements />
    },
    {
        path: "sales-order",
        component: <SalesOrder />
    },
    {
        path: "so-approval",
        component: <SalesRequirementsApproval />
    },
    {
        path: "so-payment",
        component: <SoPayment />
    },
    {
        path: "sales-executive",
        component: <SalesExecutive />
    },
]
