import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { brandListEndPoint, categoryListEndPoint, divisionListEndPoint, gstTypeListEndPoint, productEndPoint, s3ImageUploadEndPoint, subCategoryListEndPoint, supplierListEndPoint, unitListEndPoint } from "../../../service/api";
import { getAxios, postAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";

export default function ProductForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentProduct, setCurrentProduct] = useState({});

    const [divisionDropdown, setDivisionDropdown] = useState([]);
    const [categoryDropdown, setCategoryDropdown] = useState([]);
    const [subCategoryDropdown, setSubCategoryDropdown] = useState([]);
    const [brandDropdown, setBrandDropdown] = useState([]);
    const [unitDropdown, setUnitDropdown] = useState([]);
    const [supplierDropdown, setSupplierDropdown] = useState([]);
    const [gstTypeListInput, setGstTypeListInput] = useState([]);

    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState('');

    const [gstDetails, setGstDetails] = useState({});

    const [imageData, setimageData] = useState();
    const [imageUrl, setimageUrl] = useState('');


    const schema = yup
        .object({
            product_name: yup.string(),
            product_code: yup.string(),
            nt_weight: yup.string(),
            hsn: yup.string(),
            purchase_price: yup.string(),
            mrp: yup.string(),
            unit: yup.string(),
            division: yup.string(),
            category: yup.string(),
            supplier: yup.string(),
            sub_category: yup.string(),
            brand: yup.string(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            product_name: currentProduct?.product_name,
            product_code: currentProduct?.product_code,
            nt_weight: currentProduct?.nt_weight,
            hsn: currentProduct?.hsn,
            purchase_price: currentProduct?.purchase_price,
            mrp: currentProduct?.mrp,
            unit: currentProduct?.unit,
            division: currentProduct?.division,
            category: currentProduct?.category,
            supplier: currentProduct?.supplier,
            sub_category: currentProduct?.sub_category,
            brand: currentProduct?.brand,
        } : {
            product_name: "",
            product_code: "",
            nt_weight: "",
            hsn: "",
            purchase_price: "",
            mrp: "",
            unit: "",
            division: "",
            category: "",
            supplier: "",
            sub_category: "",
            brand: "",
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            reset()
            setFormMode('create')
            navigate(-1)
        }
    };


    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getDivisionDropdownData();
        getUnitDropdownData();
        getSupplierDropdownData();
        getGstDropdownData({});

        setFormMode(localStorage.getItem('product_form_mode'))

        if (localStorage.getItem('product_form_mode') === 'update') {
            getProductDetails()
        }
    }, [])

    useEffect(() => {
        if (gstDetails !== undefined && gstDetails != {}) {
            for (var i of gstTypeListInput) {
                i['value'] = gstDetails[i.type_name]
                console.log(i)
            }
        }
    },[gstDetails])

    const getProductDetails = async () => {
        var response = await getAxios({
            url: productEndPoint + localStorage.getItem('product_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                product_name: response.data?.product_name,
                product_code: response.data?.product_code,
                nt_weight: response.data?.nt_weight,
                hsn: response.data?.hsn,
                purchase_price: response.data?.purchase_price,
                mrp: response.data?.mrp,
                unit: response.data?.unit,
                division: response.data?.division,
                category: response.data?.category,
                supplier: response.data?.supplier,
                sub_category: response.data?.sub_category,
                brand: response.data?.brand,
                image: response.data?.image,
            }

            setGstDetails(response.data?.gst_value)

            setSelectedDivision(response.data?.division)
            getCategoryDropdownData(response.data?.division)

            setSelectedCategory(response.data?.category)
            getSubCategoryDropdownData(response.data?.category)

            setSelectedSubCategory(response.data?.sub_category)
            getBrandDropdownData(response.data?.sub_category)

            setSelectedBrand(response.data?.brand)

            setCurrentProduct(tempDetails)

            // setimageData(response.data?.image)
            setSelectedSupplier(response.data?.supplier)

            getGstDropdownData(response.data);

            
        }
    }

    const getGstDropdownData = async (product) => {
        var response = await getAxios({
            url: gstTypeListEndPoint
        })

        if (response !== null) {
            setGstTypeListInput(response.data.list)
        }
    }

    const getDivisionDropdownData = async () => {
        var response = await getAxios({
            url: divisionListEndPoint
        })

        if (response !== null) {
            setDivisionDropdown(response.data.list)
        }
    }

    const getCategoryDropdownData = async (value) => {
        var response = await getAxios({
            url: categoryListEndPoint + "?division=" + value
        })

        if (response !== null) {
            setSelectedDivision(value)
            setCategoryDropdown(response.data.list)
        }
    }

    const getSubCategoryDropdownData = async (value) => {
        var response = await getAxios({
            url: subCategoryListEndPoint + "?division=" + selectedDivision + "&?category=" + value
        })

        if (response !== null) {
            setSelectedCategory(value)
            setSubCategoryDropdown(response.data.list)
        }
    }

    const getBrandDropdownData = async (value) => {
        var response = await getAxios({
            url: brandListEndPoint + "?division=" + selectedDivision + "&?category=" + selectedCategory + "&?sub_category=" + value
        })

        if (response !== null) {
            setSelectedSubCategory(value)
            setBrandDropdown(response.data.list)
        }
    }

    const getUnitDropdownData = async () => {
        var response = await getAxios({
            url: unitListEndPoint
        })

        if (response !== null) {
            setUnitDropdown(response.data.list)
        }
    }

    const getSupplierDropdownData = async () => {
        var response = await getAxios({
            url: supplierListEndPoint
        })

        if (response !== null) {
            setSupplierDropdown(response.data.list)
        }
    }

    const onSubmit = async (data) => {
        if (localStorage.getItem('product_form_mode') === 'create') {

            if (imageUrl.length > 0) {
                var response = await putAxiosWithToken({
                    url: s3ImageUploadEndPoint + currentProduct.image.spit('/')[currentProduct.image.spit('/').length - 1] + "/",
                    body: new FormData(document.getElementById('image-form'))
                })

                if (response != null) {

                    data['image'] = response.url
                    setimageUrl(response.url)
                }
            } else {
                var response = await postAxios({
                    url: s3ImageUploadEndPoint,
                    body: new FormData(document.getElementById('image-form'))
                })
    
                if (response != null) {
    
                    data['image'] = response.url
                    setimageUrl(response.url)
                }
            }

            

                var gstdata = {}
                for (var item of gstTypeListInput) {
                    var value = document.getElementById(item.type_name + 'value').value
                    // if (value.length !== 0) {
                    gstdata[item.type_name] = value
                    //     document.getElementById(item.type_name+"error").className = "text-red-500 font-[400] text-[13px] hidden"
                    // } else {
                    //     document.getElementById(item.type_name+"error").className = "text-red-500 font-[400] text-[13px] block"
                    // }

                }

                data['gst_value'] = gstdata

                createProduct(data)
            

        } else {

            if (imageData?.startsWith('https') || imageData === undefined || imageData === null) {
                data['image'] = imageData
            } else {
                var response = await putAxiosWithToken({
                    url: s3ImageUploadEndPoint + currentProduct.image?.split('/')[currentProduct.image?.split('/').length - 1] + "/",
                    body: new FormData(document.getElementById('image-form'))
                })

                if (response != null) {

                    data['image'] = response.url
                }
            }


                var gstdata = {}
                for (var item of gstTypeListInput) {
                    var value = document.getElementById(item.type_name + 'value').value
                    // if (value.length !== 0) {
                    gstdata[item.type_name] = value
                    //     document.getElementById(item.type_name+"error").className = "text-red-500 font-[400] text-[13px] hidden"
                    // } else {
                    //     document.getElementById(item.type_name+"error").className = "text-red-500 font-[400] text-[13px] block"
                    // }

                }

                data['gst_value'] = gstdata

                updateProduct(data)
            
        }
    }

    const createProduct = async (body) => {
        var response = await postAxiosWithToken({
            url: productEndPoint,
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/products/product')
        }
    }

    const updateProduct = async (body) => {
        var response = await putAxiosWithToken({
            url: productEndPoint + localStorage.getItem('product_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            localStorage.removeItem('product_form_id')
            localStorage.setItem('product_form_mode', 'create')
            toast.success(response.message)
            navigate('/products/product')
        }
    }

    const clearData = () => {
        reset()
        setCurrentProduct({
            product_name: "",
            product_code: "",
            nt_weight: "",
            hsn: "",
            purchase_price: "",
            mrp: "",
            unit: "",
            division: "",
            category: "",
            supplier: "",
            sub_category: "",
            brand: "",
        })
    }

    const uploadImage = (value) => {
        var file = value.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setimageData(e.target.result)
        };
    }


    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Product</p>
                <form id="image-form">
                    <input type="file" id="imageupload" name="image" onChange={uploadImage} hidden />
                </form>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Product Image</p>
                        {
                            imageData !== null && imageData !== undefined ?
                                <div className="w-full">
                                    <div className="w-fit mx-auto">
                                        <img src={imageData} alt="no-image" />
                                    </div>
                                    <label className="text-blue-600 cursor-pointer underline" htmlFor="imageupload">Edit Image</label>
                                </div> : currentProduct?.image !== null && currentProduct?.image !== undefined ?
                                <div className="w-full">
                                    <div className="w-fit mx-auto">
                                        <img src={currentProduct?.image} alt="no-image" />
                                    </div>
                                    <label className="text-blue-600 cursor-pointer underline" htmlFor="imageupload">Edit Image</label>
                                </div> :

                                <div className="w-full h-[150px] border rounded-lg flex flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                    </svg>

                                    <label className="text-blue-600 cursor-pointer underline" htmlFor="imageupload">Upload Image</label>
                                </div>
                        }
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                        <div className="w-full">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Supplier</p>
                                <select
                                    placeholder="supplier"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="supplier"
                                    {...register('supplier')}
                                    value={selectedSupplier}
                                    onChange={(e) => setSelectedSupplier(e.target.value)}
                                >
                                    <option value={""}>--select supplier--</option>
                                    {
                                        supplierDropdown.map((option) => (
                                            <option className="capitalize" key={"supplier" + option.supplier_name} value={option.id}>{option.supplier_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.supplier?.message}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Product Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Product Name</p>
                                <input
                                    type="text"
                                    placeholder="product name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="product_name"
                                    id="forminit"
                                    {...register('product_name')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.product_name?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Division</p>
                                <select
                                    placeholder="division"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="division"
                                    {...register('division')}
                                    value={selectedDivision}
                                    onChange={(e) => getCategoryDropdownData(e.target.value)}
                                >
                                    <option value={""}>--select division--</option>
                                    {
                                        divisionDropdown.map((option) => (
                                            <option className="capitalize" key={"division" + option.division_name} value={option.id}>{option.division_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.division?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Category</p>
                                <select
                                    placeholder="category"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="category"
                                    {...register('category')}
                                    value={selectedCategory}
                                    onChange={(e) => getSubCategoryDropdownData(e.target.value)}
                                >
                                    <option value={""}>--select category--</option>
                                    {
                                        categoryDropdown.map((option) => (
                                            <option className="capitalize" key={"category" + option.category_name} value={option.id}>{option.category_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.category?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Sub Category</p>
                                <select
                                    placeholder="sub_category"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="sub_category"
                                    {...register('sub_category')}
                                    value={selectedSubCategory}
                                    onChange={(e) => getBrandDropdownData(e.target.value)}
                                >
                                    <option value={""}>--select category--</option>
                                    {
                                        subCategoryDropdown.map((option) => (
                                            <option className="capitalize" key={"category" + option.category_name} value={option.id}>{option.sub_category_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.category?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Brand</p>
                                <select
                                    placeholder="brand"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="brand"
                                    {...register('brand')}
                                    value={selectedBrand}
                                    onChange={(e) => setSelectedBrand(e.target.value)}
                                >
                                    <option value={""}>--select brand--</option>
                                    {
                                        brandDropdown.map((option) => (
                                            <option className="capitalize" key={"brand" + option.brand_name} value={option.id}>{option.brand_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.brand?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Product Code</p>
                                <input
                                    type="text"
                                    placeholder="product code"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="product_code"
                                    {...register('product_code')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.product_code?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">HSN</p>
                                <input
                                    type="text"
                                    placeholder="hsn"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="hsn"
                                    {...register('hsn')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.hsn?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Net Weight</p>
                                <input
                                    type="text"
                                    placeholder="net weight"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="nt_weight"
                                    {...register('nt_weight')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.nt_weight?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Unit</p>
                                <select
                                    placeholder="unit"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="unit"
                                    {...register('unit')}
                                >
                                    <option value={""}>--select unit--</option>
                                    {
                                        unitDropdown.map((option) => (
                                            <option className="capitalize" key={"unit" + option.unit_name} value={option.id}>{option.measurement}({option.unit})</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.unit?.message}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Price Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Purchase price</p>
                                <input
                                    type="text"
                                    placeholder="purchase price"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="purchase_price"
                                    {...register('purchase_price')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.purchase_price?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Price</p>
                                <input
                                    type="text"
                                    placeholder="price"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="mrp"
                                    {...register('mrp')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.mrp?.message}</p>
                            </div>
                            {
                                gstTypeListInput.map((item, index) => (
                                    <div key={"input" + index} className="my-[5px]">
                                        <p className="mb-[5px] text-[15px]">{item.type_name}</p>
                                        <input
                                            type="number"
                                            step={0.01}
                                            placeholder={`${item.type_name}`}
                                            className="sm:mb-[5px] border border-gray-300 w-full rounded-lg w-full p-[10px] text-[17px] outline-none"
                                            name={item.type_name}
                                            id={`${item.type_name}value`}
                                            defaultValue={gstDetails[item.type_name]}
                                        />
                                        <p id={`${item.type_name}error`} className="text-red-500 font-[400] text-[13px] hidden">{item?.type_name}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); reset(); navigate(-1) }}
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}