import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customerListEndPoint, customerProductEndPoint, purchaseRequirementEndPoint, salesRequirementEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { City, Country, State } from "country-state-city";
import { useKeyPress } from "../../../hooks/useKeyPress";
import DatePicker from "../../../components/DatePicker";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { isValidForm } from "../../../utilities/formvalidation";
import { useGeolocated } from "react-geolocated";


export default function SalesRequirementsForm() {
    const navigate = useNavigate();

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

    const [formMode, setFormMode] = useState('create');
    const [currentRequirement, setCurrentRequirement] = useState({
        customer: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        order_req_date: ""
    });

    const [currentRequirementErrors, setCurrentRequirementErrors] = useState({});

    const [customerDropDown, setCustomerDropDown] = useState([]);
    const [productDropDown, setProductDropDown] = useState([]);
    const [countryDropdown, setCountryDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [cityDropdown, setCityDropdown] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [selectedProduct, setSelectedProduct] = useState({
        product: "",
        product_name: "",
        division_name: "",
        category_name: "",
        sub_category_name: "",
        brand_name: "",
        measurement: "",
        purchase_price: "",
        quantity: "",
        nt_weight: "",
        gst_values: [],
        gst_percent: 0,
        total_price: ""
    });
    const [selectedParticulars, setSelectedParticulars] = useState([]);

    const [requirementsCost, setRequirementCost] = useState({
        grandTotal: 0,
        gstTotal: 0,
        total: 0
    });

    const [orderReqDate, setOrderReqDate] = useState({
        startDate: null,
        endDate: null
    })
    const [datePopupOpen, setDatePopupOpen] = useState(false)
    const [addGst, setAddGst] = useState(true)

    const [apiBody, setApiBody] = useState({});

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('requirementformsubmit').click()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            clearData();
        } else if (event.key === 'Enter') {
            addProduct(event)
        } else if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['s', 'e', 'Enter', 'Escape'], onKeyPress);

    useEffect(() => {
        getCustomerDropdown();

        var countryTempList = []
        Country.getAllCountries().map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            countryTempList.push(dictData)
        })

        setCountryDropdown(countryTempList)

        setFormMode(localStorage.getItem('sales_req_form_mode'))

        // if (localStorage.getItem('staff_form_mode') === 'update') {
        //     getStaffDetails()
        // }
    }, [])

    useEffect(() => {
        if (selectedParticulars.length > 0) {
            for (var i of selectedParticulars) {
                setRequirementCost({
                    ...requirementsCost,
                    grandTotal: Number(requirementsCost.grandTotal) + Number(i.grand_total),
                    gstTotal: Number(requirementsCost.gstTotal) + Number(i.gst_total),
                    total: Number(requirementsCost.total) + Number(i.total_price),
                })
            }
        } else {
            setRequirementCost({
                grandTotal: 0.00,
                gstTotal: 0.00,
                total: 0.00
            })
        }
    }, [selectedParticulars])

    const getCustomerDropdown = async () => {
        var response = await getAxios({
            url: customerListEndPoint
        })

        if (response !== null) {
            setCustomerDropDown(response.data.list)
        }
    }

    const getStateDropdown = (value) => {
        var stateTempList = []
        State.getStatesOfCountry(JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            stateTempList.push(dictData)
        })
        setStateDropdown(stateTempList)
    }

    const getCityDropdown = useCallback((value) => {
        var cityTempList = []
        City.getCitiesOfState(selectedCountry.isoCode, JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name
            }
            cityTempList.push(dictData)
        })

        setCityDropdown(cityTempList)
    }, [selectedState, selectedCountry]);

    const getProductListDropDown = async (value) => {
        var response = await getAxios({
            url: customerProductEndPoint + value?.id + '/'
        })

        if (response !== null) {
            setProductDropDown(response.data.list)
            setCurrentRequirement({ ...currentRequirement, customer: value })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (localStorage.getItem('sales_req_form_mode') === 'create') {

            let formFields = new FormData(e.target);
            let formDataObject = Object.fromEntries(formFields.entries());

            var err = {}

            for (var field of Object.entries(formDataObject)) {
                if (field[1].length === 0) {
                    err[field[0]] = `${field[0]} field is required`
                } else {
                    err[field[0]] = ""
                }
            }

            setCurrentRequirementErrors(err)

            if (isValidForm(formDataObject) && selectedParticulars.length > 0) {

                var items = []
                for (var i of selectedParticulars) {
                    var dictData = {
                        customer_product: i.id,
                        quantity: i.quantity,
                    }
                    items.push(dictData)
                }
                let createBody = {
                    customer: JSON.parse(formDataObject.customer).id,
                    address: formDataObject.address,
                    country: formDataObject.country,
                    state: formDataObject.state,
                    city: formDataObject.city,
                    pincode: formDataObject.pincode,
                    particulars: items,
                    order_req_date: "",
                }
                setApiBody(createBody)
                setDatePopupOpen(!datePopupOpen)
            }
        } else {

        }
    }

    const addProduct = (e) => {
        e.preventDefault();

        if (selectedProduct.product_name !== "" && selectedProduct.gst_values != []) {
            setSelectedParticulars([...selectedParticulars, selectedProduct]);
            clearData()
        }

    }

    const removeParticular = (index) => {
        const data = [...selectedParticulars];
        data.splice(index, 1);
        setSelectedParticulars(data);
    }

    const addQuantity = (value) => {
        var totalPercent = 0
        for (var i of Object.values(selectedProduct.gst_values)) {
            totalPercent = (Number(i.value) + Number(totalPercent)).toFixed(2)
        }

        var grandTotal = (selectedProduct?.selling_price * value) + (value * selectedProduct.gst_amount)
        setSelectedProduct({
            ...selectedProduct,
            quantity: value,
            gst_percent: totalPercent,
            gst_total: (value * selectedProduct.gst_amount).toFixed(2),
            total_price: (selectedProduct?.selling_price * value).toFixed(2),
            grand_total: grandTotal.toFixed(2)
        })
    }

    const clearData = () => {
        setSelectedProduct({
            product: "",
            product_name: "",
            division_name: "",
            category_name: "",
            sub_category_name: "",
            brand_name: "",
            measurement: "",
            purchase_price: "",
            quantity: "",
            nt_weight: "",
            gst_values: [],
            gst_percent: 0,
            total_price: "",
            selling_price: "",
            gst_total: "",
            grand_total: ""
        })
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target

        if (name === 'country') {
            getStateDropdown(value);
            setSelectedCountry(JSON.parse(e.target.value));
            setCurrentRequirement((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'state') {
            setSelectedState(JSON.parse(e.target.value));
            setCurrentRequirement((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            getCityDropdown(value)
        } else if (name === 'city') {
            setSelectedCity(value)
            setCurrentRequirement((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setCurrentRequirement((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

        setCurrentRequirementErrors((prevState) => ({
            ...prevState,
            [name]: value.length === 0 ? `${name} field is required` : ''
        }));
    }
    
    const createRequirement = async () => {
        let body = apiBody
        body['order_req_date'] = orderReqDate?.startDate
        body['with_gst'] = true
        if (isGeolocationAvailable) {
            if (isGeolocationEnabled) {
                body["lattitude"] = coords.latitude
                body["longitude"] = coords.longitude

                var response = await postAxiosWithToken({
                    url: salesRequirementEndPoint,
                    body: body
                })

                if (response != null) {
                    toast.success(response.message);
                    navigate('/sales/sales-requirements')
                }
            } else {
                toast.error('Enable the location')
            }
        } else {
            toast.error('Enable the location')
        }
    }

    return (
        <>
            <div className="mb-[50px]  pb-[70px] relative">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Sales Requirement</p>
                <form onSubmit={onSubmit}>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Customer Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Customer</p>
                                <select
                                    placeholder="customer"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="customer"
                                    onChange={(e) => getProductListDropDown(JSON.parse(e.target.value))}
                                    value={JSON.stringify(currentRequirement.customer)}
                                >
                                    <option value={""}>--select customer--</option>
                                    {
                                        customerDropDown.map((option) => (
                                            <option className="capitalize" key={"customer" + option.customer_name} value={JSON.stringify(option)}>{option.customer_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.customer}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Customer Id</p>
                                <input
                                    type="text"
                                    placeholder="customer id"
                                    className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="customer_id"
                                    value={currentRequirement?.customer?.customer_id}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Delivery Address Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Address</p>
                                <input
                                    type="text"
                                    placeholder="address"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="address"
                                    value={currentRequirement.address}
                                    onChange={(e) => onValueUpdate(e)}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.address}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Country</p>
                                <select
                                    placeholder="country"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="country"
                                    onChange={(e) => onValueUpdate(e)}
                                // onChange={(e) => { setSelectedCountry(JSON.parse(e.target.value)); getStateDropdown(e.target.value) }}
                                >
                                    <option value={""}>--select country--</option>
                                    {
                                        countryDropdown.map((option) => (
                                            <option className="capitalize" key={"country" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.country}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">State</p>
                                <select
                                    placeholder="state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="state"
                                    onChange={(e) => onValueUpdate(e)}
                                // onChange={(e) => { setSelectedState(JSON.parse(e.target.value)); getCityDropdown(e.target.value) }}
                                >
                                    <option value={""}>--select state--</option>
                                    {
                                        stateDropdown.map((option) => (
                                            <option className="capitalize" key={"state" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.state}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">City</p>
                                <select
                                    placeholder="city"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="city"
                                    value={selectedCity}
                                    onChange={(e) => onValueUpdate(e)}
                                >
                                    <option value={""}>--select city--</option>
                                    {
                                        cityDropdown.map((option) => (
                                            <option className="capitalize" key={"city" + option.name} value={option.name}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.city}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pincode</p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                    value={currentRequirement.pincode}
                                    onChange={(e) => onValueUpdate(e)}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.pincode}</p>
                            </div>
                        </div>
                    </div>

                    <div className="absolute bottom-0 right-0 flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            onClick={(e) => { e.preventDefault(); clearData(); navigate(-1) }}
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="requirementformsubmit"
                            className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>

                <form onSubmit={addProduct}>
                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Add Particulars</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Product</p>
                                <select
                                    placeholder="product"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="product"
                                    onChange={(e) => {
                                        var tempData = JSON.parse(e.target.value)
                                        setSelectedProduct({ ...tempData, product: tempData, quantity: 1, gst_percent: 0, total_price: tempData.selling_price, gst_total: tempData.gst_amount });
                                    }}
                                    value={JSON.stringify(selectedProduct.product)}
                                >
                                    <option value={""}>--select product--</option>
                                    {
                                        productDropDown.map((option) => (
                                            <option className="capitalize" key={"product" + option.product_name} value={JSON.stringify(option)}>{option.product_name}</option>
                                        ))
                                    }
                                </select>
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.product?.message}</p> */}
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Price</p>
                                <input
                                    type="text"
                                    placeholder="price"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="price"
                                    value={selectedProduct?.selling_price}
                                    readOnly
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.total_price?.message}</p> */}
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Quantity</p>
                                <input
                                    type="number"
                                    placeholder="quantity"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="quantity"
                                    value={selectedProduct?.quantity}
                                    onChange={(e) => { addQuantity(e.target.value); }}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.quantity?.message}</p> */}
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Total Price</p>
                                <input
                                    type="text"
                                    placeholder="total price"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="total_price"
                                    value={selectedProduct?.total_price}
                                    readOnly
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.total_price?.message}</p> */}
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Total Gst Price</p>
                                <input
                                    type="text"
                                    placeholder="total gst price"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="gst_total"
                                    value={selectedProduct?.gst_total}
                                    readOnly
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.gst_total?.message}</p> */}
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Grand Total</p>
                                <input
                                    type="text"
                                    placeholder="grand total"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="grand_total"
                                    value={selectedProduct?.grand_total}
                                    readOnly
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.grand_total?.message}</p> */}
                            </div>

                        </div>
                        <div className="min-[423px]:flex gap-4 mt-0 sm:mt-[35px]">

                            <button
                                onClick={(e) => { e.preventDefault(); clearData(); }}
                                className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                            >Reset<span className="lowercase">(ctrl+e)</span></button>

                            <button
                                type="submit"
                                className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                            >Add<span className="lowercase">(enter)</span></button>
                        </div>
                    </div>
                </form>

                <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Particulars</p>
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr className="border">
                                <td className="p-[10px] w-[50px] text-center">Actions</td>
                                <td className="p-[10px] w-[50px]">S.No</td>
                                <td className="p-[10px] w-[200px]">Product</td>
                                <td className="p-[10px] w-[100px]">Rate</td>
                                <td className="p-[10px] w-[100px]">Order Qty</td>
                                <td className="p-[10px] w-[100px]">Net. Weight</td>
                                <td className="p-[10px] w-[100px]">GST %</td>
                                <td className="p-[10px] w-[100px]">GST Total</td>
                                <td className="p-[10px] w-[100px]">Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedParticulars.map((item, index) => (
                                    <tr className="border">
                                        <td className="p-[10px] w-[50px] ">
                                            <div onClick={() => removeParticular(index)} className="cursor-pointer hover:scale-125 w-fit mx-auto">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                            </div>
                                        </td>
                                        <td className="p-[10px] w-[50px]">{index + 1}</td>
                                        <td className="p-[10px] w-[200px]">{item.product_name}</td>
                                        <td className="p-[10px] w-[100px]">{item.selling_price}</td>
                                        <td className="p-[10px] w-[100px]">{item.quantity}</td>
                                        <td className="p-[10px] w-[100px]">{item.nt_weight} {item.measurement}</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_percent} %</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_total}</td>
                                        <td className="p-[10px] w-[100px]">{item.grand_total}</td>

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {
                        selectedParticulars.length > 0 && <div>
                            <div className="flex flex-col items-end ">
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Amount</div>
                                    <div>{requirementsCost.total}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Gst Amount</div>
                                    <div>{requirementsCost.gstTotal}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Grand Total</div>
                                    <div>{requirementsCost.grandTotal}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>


                <Popup isOpen={datePopupOpen} closeModal={() => setDatePopupOpen(!datePopupOpen)} title={'Purchase Requirement'}>
                    <div className="my-[15px]">
                        <DatePicker value={orderReqDate} handleValueChange={(value) => setOrderReqDate(value)} />
                    </div>
                    {/* <div className="mb-2">GST</div> */}
                    {/* <div className="flex gap-5 mb-5">
                        <div className="flex gap-1 items-center">
                            <div onClick={() => changeGstValue(true)} className="w-[18px] h-[18px] border rounded-full border-gray-300 flex justify-center items-center">
                                <div className={`${addGst ? 'block' : 'hidden'} w-[11px] h-[11px] rounded-full bg-primary`}></div>
                            </div>
                            <div>With GST</div>
                        </div>
                        <div className="flex gap-1 items-center">
                            <div onClick={() => changeGstValue(false)} className="w-[18px] h-[18px] border rounded-full border-gray-300 flex justify-center items-center">
                                <div className={`${!addGst ? 'block' : 'hidden'} w-[11px] h-[11px] rounded-full bg-primary`}></div>
                            </div>
                            <div>Without GST</div>
                        </div>
                    </div> */}

                    {
                        selectedParticulars.length > 0 && <div className="my-[25px] border p-[7px]">
                            <div className="flex flex-col items-end ">
                                <div className="flex w-full justify-between items-center p-[5px]">
                                    <div>Amount</div>
                                    <div>{requirementsCost.total}</div>
                                </div>
                                <div className="flex w-full justify-between items-center p-[5px]">
                                    <div>Gst Amount</div>
                                    <div>{requirementsCost.gstTotal}</div>
                                </div>
                                <div className="flex w-full justify-between items-center p-[5px]">
                                    <div>Grand Total</div>
                                    <div>{requirementsCost.grandTotal}</div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="flex gap-[15px] items-center">
                        <button
                            onClick={(e) => { e.preventDefault(); setDatePopupOpen(!datePopupOpen); }}
                            className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Close</button>

                        <button
                        onClick={(e) => {e.preventDefault(); createRequirement()}}
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >Send</button>
                    </div>
                </Popup>

            </div>
        </>
    );
}