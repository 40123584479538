import { useEffect, useState } from "react";
import { getAxios } from "../../../service/apiservice";
import { customerEndPoint } from "../../../service/api";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { converttojson } from "../../../utilities/jsonvalidation";
import AddCustomerProducts from "./addcustomerproducts";
import CustomerStockDetails from "./customerstockdetails";

export default function ViewCustomerDetails() {

    const navigate = useNavigate();

    const [currentCustomer, setCurrentCustomer] = useState({});
    const [customerProducts, setCustomerProducts] = useState([]);
    const [customerStock, setCustomerStock] = useState([]);

    const [gstEnabled, setGstEnabled] = useState(false);
    const [bankEnabled, setBankEnabled] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [tab, setTab] = useState(1);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {
        getCustomerDetails();
    }, [])

    const getCustomerDetails = async () => {
        var response = await getAxios({
            url: customerEndPoint + localStorage.getItem('customer_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                customer_name: response.data?.customer_details?.customer_name,
                email: response.data?.customer_details?.email,
                mobile: response.data?.customer_details?.mobile,
                due_amount: response.data?.customer_details?.due_amount,
                outstanding_amount: response.data?.customer_details?.outstanding_amount,
                address: response.data?.customer_details?.address,
                country: response.data?.customer_details?.country,
                state: response.data?.customer_details?.state,
                city: response.data?.customer_details?.city,
                pincode: response.data?.customer_details?.pincode,
                shop_name: response.data?.customer_details?.shop_name,
                shop_address: response.data?.customer_details?.shop_address,
                shop_country: response.data?.customer_details?.shop_country,
                shop_state: response.data?.customer_details?.shop_state,
                shop_city: response.data?.customer_details?.shop_city,
                shop_pincode: response.data?.customer_details?.shop_pincode,
                pan_no: response.data?.customer_gst_details?.pan_no,
                gst_no: response.data?.customer_gst_details?.gst_no,
                registered_name: response.data?.customer_gst_details?.registered_name,
                gst_status: response.data?.customer_gst_details?.gst_status,
                tax_payer_type: response.data?.customer_gst_details?.tax_payer_type,
                bussiness_type: response.data?.customer_gst_details?.bussiness_type,
                acc_holder_name: response.data?.customer_bank_details?.acc_holder_name,
                account_no: response.data?.customer_bank_details?.account_no,
                ifsc_code: response.data?.customer_bank_details?.ifsc_code,
                bank_name: response.data?.customer_bank_details?.bank_name,
                branch_name: response.data?.customer_bank_details?.branch_name,
                micr_code: response.data?.customer_bank_details?.micr_code,
                executive_name: response.data?.customer_promoter_details?.executive_name,
                has_promoter: response.data?.customer_promoter_details?.has_promoter,
                promoter_name: response.data?.customer_promoter_details?.promoter_name,
            }
            setCustomerProducts(response.data?.customer_product_details)
            setCurrentCustomer(tempDetails)
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div className="flex gap-[10px] border p-[5px] sm:p-[10px] mb-[25px] rounded-lg">
                <div onClick={() => setTab(1)} className={`${tab === 1 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Customer Details</div>
                <div onClick={() => setTab(2)} className={`${tab === 2 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Product Detials</div>
                <div onClick={() => setTab(3)} className={`${tab === 3 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Stock Details</div>
            </div>
            {
                tab === 1 && <div>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Customer Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Customer Name</p>
                                <div>{currentCustomer?.customer_name}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Email</p>
                                <div>{currentCustomer?.email}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Phone no</p>
                                <div>{currentCustomer?.mobile}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Customer Due</p>
                                <div>{currentCustomer?.due_amount}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Customer Outstanding</p>
                                <div>{currentCustomer?.outstanding_amount}</div>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Address Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Address</p>
                                <div>{currentCustomer?.address}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Country</p>
                                <div>{converttojson(currentCustomer?.country)?.name}</div>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[600]">State</p>
                                <div>{converttojson(currentCustomer?.state)?.name}</div>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[600]">City</p>
                                <div>{currentCustomer?.city}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Pincode</p>
                                <div>{currentCustomer?.pincode}</div>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Shop Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Shop Name</p>
                                <div>{currentCustomer?.shop_name}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Address</p>
                                <div>{currentCustomer?.shop_address}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Country</p>
                                <div>{converttojson(currentCustomer?.shop_country)?.name}</div>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[600]">State</p>
                                <div>{converttojson(currentCustomer?.shop_state)?.name}</div>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[600]">City</p>
                                <div>{currentCustomer?.shop_city}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Pincode</p>
                                <div>{currentCustomer?.shop_pincode}</div>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2 flex justify-between">
                            <div>GST Details</div>
                            <div onClick={() => setGstEnabled(!gstEnabled)} className={`${gstEnabled ? '' : 'rotate-180'} w-fit h-fit`} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path fill="currentColor" d="M3.2 10.26a.75.75 0 0 0 1.06.04L8 6.773l3.74 3.527a.75.75 0 1 0 1.02-1.1l-4.25-4a.75.75 0 0 0-1.02 0l-4.25 4a.75.75 0 0 0-.04 1.06Z" /></svg>
                            </div>
                        </p>
                        <div className={`${gstEnabled ? 'block' : 'hidden'} grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5`}>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Pan Number</p>
                                <div>{currentCustomer?.pan_no}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">GST NO</p>
                                <div>{currentCustomer?.gst_no}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">GST Status</p>
                                <div>{currentCustomer?.gst_status}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">GST Type</p>
                                <div>{currentCustomer?.tax_payer_type}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Bussiness Type</p>
                                <div>{currentCustomer?.bussiness_type}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Registered Name</p>
                                <div>{currentCustomer?.registered_name}</div>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2 flex justify-between">
                            <div>Bank Details</div>
                            <div onClick={() => setBankEnabled(!bankEnabled)} className={`${bankEnabled ? '' : 'rotate-180'} w-fit h-fit`} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path fill="currentColor" d="M3.2 10.26a.75.75 0 0 0 1.06.04L8 6.773l3.74 3.527a.75.75 0 1 0 1.02-1.1l-4.25-4a.75.75 0 0 0-1.02 0l-4.25 4a.75.75 0 0 0-.04 1.06Z" /></svg>
                            </div>
                        </p>
                        <div className={`${bankEnabled ? 'block' : 'hidden'} grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5`}>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Account Holder Name</p>
                                <div>{currentCustomer?.acc_holder_name}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Account No</p>
                                <div>{currentCustomer?.account_no}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">IFSC Code</p>
                                <div>{currentCustomer?.ifsc_code}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Bank Name</p>
                                <div>{currentCustomer?.bank_name}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Branch Name</p>
                                <div>{currentCustomer?.branch_name}</div>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">MICR No</p>
                                <div>{currentCustomer?.micr_code}</div>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Assigned Head Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[600]">Sales Executive</p>
                                <div>{currentCustomer?.executive_name}</div>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[600]">Are Promoter?</p>
                                <div>{currentCustomer?.has_promoter ? 'Yes' : 'No'}</div>
                            </div>
                            <div className={`${currentCustomer.has_promoter ? 'block' : 'hidden'} my-[5px]`}>
                                <p className="mb-[5px] text-[15px] font-[600]">Promoter Name</p>
                                <div>{currentCustomer?.promoter_name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                tab === 2 && <div>
                    <AddCustomerProducts customerProductList={customerProducts} isLoading={isLoading} getData={getCustomerDetails} />
                </div>
            }

            {
                tab === 3 && <div>
                    <CustomerStockDetails customerStockList={customerStock} isLoading={isLoading} getData={getCustomerDetails} />
                </div>
            }

            <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                <button
                    id="backnav"
                    className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                    onClick={(e) => { e.preventDefault(); navigate(-1) }}
                >close<span className="lowercase">(esc)</span></button>
            </div>
        </div>
    );
}