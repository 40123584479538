import { useEffect, useState } from "react";
import { instockEndPoint, purchaseOrderEndPoint, purchaseOrderListEndPoint, s3ImageUploadEndPoint } from "../../../service/api";
import { getAxios, postAxios, postAxiosWithToken } from "../../../service/apiservice";
import { datetimetodate } from "../../../utilities/datetime";
import ParticularsListForm from "./particularlistform";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { deliveryEntryType, invoiceEntryType } from "../../../constant";
import { converttojson } from "../../../utilities/jsonvalidation";

export default function InStockForm() {

    const navigate = useNavigate();

    const [purchaseDropDown, setPurchaseDropDown] = useState([]);
    const [purchaseDetails, setPurchaseDetails] = useState();
    const [productList, setProductList] = useState([]);

    const [imageData, setimageData] = useState();

    const [selectedEntryType, setSelectedEntryType] = useState(deliveryEntryType);
    const [entryNo, setEntryNo] = useState('');

    useEffect(() => {
        getPurchaseDropdownData();
    }, [])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('instockformsubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    const getPurchaseDropdownData = async () => {
        var response = await getAxios({
            url: purchaseOrderListEndPoint
        })

        if (response !== null) {
            setPurchaseDropDown(response.data.list)
        }
    }

    const getPurchaseOrderDetails = async (value) => {
        var response = await getAxios({
            url: purchaseOrderEndPoint + value.id + '/'
        })

        if (response != null) {
            setPurchaseDetails(response.data)
            setProductList(response.data.particulars)
        }
    }

    const uploadImage = (value) => {
        var file = value.target.files[0];
        var reader = new FileReader();

        try {
            reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setimageData(e.target.result)
            };
        } catch (Err) {

        }
    }

    const addStock = async () => {
        let createBody = {
            purchase_order: purchaseDetails.id,
            entry_type: selectedEntryType,
            entry_no: entryNo,
            particulars: []
        }
        if (imageData !== undefined && imageData !== null) {
            var response = await postAxios({
                url: s3ImageUploadEndPoint,
                body: new FormData(document.getElementById('image-form'))
            })
    
            if (response != null) {
                createBody['image'] =  response.url
            }
        }


        for (var item of productList) {
            var dictData = {
                purchase_order: purchaseDetails.id,
                product: item.product,
                recieved_quantity: item.recieved_quantity,
                batch_no: item.batch_no,
                mfg_date: item.mfg_date,
                exp_date: item.exp_date,
                shortage_quantity: item.shortage_quantity,
                is_completed: item.is_completed
            }
            createBody.particulars.push(dictData)
        }

        console.log(createBody)

        var response = await postAxiosWithToken({
            url: instockEndPoint,
            body: createBody
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    return (
        <>
            <form id="image-form">
                <input type="file" id="imageupload" name="image" onChange={uploadImage} hidden />
            </form>

            <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                <div className="w-full">
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">Purchase Id</p>
                        <select
                            placeholder="purchase id"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="purchase"
                            onChange={(e) => getPurchaseOrderDetails(JSON.parse(e.target.value))}
                        >
                            <option value={""}>--select purchase order--</option>
                            {
                                purchaseDropDown.map((option) => (
                                    <option className="capitalize" key={"purchase" + option.purchase_id} value={JSON.stringify(option)}>{option.purchase_order_id}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>

            {
                purchaseDetails && <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Purchase Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Purchase Id</p>
                            <div>{purchaseDetails?.purchase_order_id}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Order Date</p>
                            <div>{purchaseDetails?.created_at && datetimetodate(purchaseDetails?.created_at)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Purchase Created By</p>
                            <div>{purchaseDetails?.created_by}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Order Status</p>
                            <div style={{ color: purchaseDetails?.order_status_color }} className="capitalize w-fit">{purchaseDetails?.order_status_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Required Date</p>
                            <div>{purchaseDetails?.order_req_date && datetimetodate(purchaseDetails?.order_req_date)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                            <div>{purchaseDetails?.with_gst ? 'With' : 'Without'}</div>
                        </div>
                    </div>
                </div>
            }
            {
                purchaseDetails && <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier</p>
                            <div>{purchaseDetails?.supplier_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier Id</p>
                            <div>{purchaseDetails?.supplier_id}</div>
                        </div>
                    </div>
                </div>
            }
            {
                purchaseDetails && <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Delivery Address Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Address</p>
                            <div>{purchaseDetails?.address}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Country</p>
                            <div>{converttojson(purchaseDetails?.country)?.name}</div>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">State</p>
                            <div>{converttojson(purchaseDetails?.state)?.name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">City</p>
                            <div>{purchaseDetails?.city}</div>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Pincode</p>
                            <div>{purchaseDetails?.pincode}</div>
                        </div>
                    </div>
                </div>
            }

            {
                purchaseDetails && <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Delivery Details</p>
                    <div className={`sm:flex justify-between items-center`}>
                        <div className="flex flex-col sm:flex-row sm:items-center gap-5">
                            <div onClick={() => setSelectedEntryType(deliveryEntryType)} className="flex gap-3 items-center cursor-pointer">
                                <div className="w-[20px] h-[20px] rounded-full border border-primary flex items-center justify-center">
                                    <div className={`${selectedEntryType === deliveryEntryType ? 'block' : 'hidden'} w-[12px] h-[12px] rounded-full bg-primary`}></div>
                                </div>
                                <div>Delivery</div>
                            </div>
                            <div onClick={() => setSelectedEntryType(invoiceEntryType)} className="flex gap-3 items-center cursor-pointer">
                                <div className="w-[20px] h-[20px] rounded-full border border-primary flex items-center justify-center">
                                    <div className={`${selectedEntryType === invoiceEntryType ? 'block' : 'hidden'} w-[12px] h-[12px] rounded-full bg-primary`}></div>
                                </div>
                                <div>Invoice</div>
                            </div>
                        </div>

                        <div className="my-[15px] sm:my-[5px]">
                            <p className="mb-[5px] text-[15px]">Delivery No</p>
                            <input
                                type="text"
                                placeholder="entry no"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none min-w-[400px]"
                                name="entry_no"
                                onChange={(e) => setEntryNo(e.target.value)}
                                value={entryNo}
                            />
                            {/* <p className="text-red-500 font-[400] text-[13px]">{currentPurchaseErrors.pincode}</p> */}
                        </div>
                    </div>
                    <div>
                        {
                            imageData ?
                                <div className="w-full relative border-2 border-dashed border-blue-600 rounded-lg overflow-hidden bg-blue-50 flex items-center justify-center gap-3">
                                    <div className="w-[300px]">
                                        <img src={imageData} alt="no-image" />
                                    </div>
                                    <label className="text-blue-600 cursor-pointer underline absolute" htmlFor="imageupload">Upload file</label>
                                </div>

                                :
                                <div className="w-full border-2 border-dashed border-blue-600 rounded-lg h-[200px] overflow-hidden bg-blue-50 flex items-center justify-center">
                                    <label className="text-blue-600 cursor-pointer underline" htmlFor="imageupload">Upload file</label>
                                </div>
                        }
                    </div>
                </div>
            }

            <div className="mb-[50px]">
                <ParticularsListForm particulars={productList} setParticulars={setProductList} />
            </div>

            {
                purchaseDetails && <div className="min-[423px]:flex gap-4 mb-[50px]">

                    <button
                        id="backnav"
                        onClick={(e) => { e.preventDefault(); navigate(-1); }}
                        className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                    >Back<span className="lowercase">(ctrl+e)</span></button>

                    <button
                    onClick={addStock}
                        type="submit"
                        id="instockformsubmit"
                        className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Submit<span className="lowercase">(ctrl+s)</span></button>
                </div>
            }


        </>
    );
}