import LogoImage from "../../assets/images/logo.svg";
import { logoutEndPoint, notificationCountEndPoint, notificationListEndPoint } from "../../service/api";
import { getAxios, postAxiosWithToken } from "../../service/apiservice";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDark, setMenuOption } from "../../redux/themeconfigslice";
import addNotification from "react-push-notification";
import Logo from "../../assets/images/logo.svg";
import { useGeolocated } from "react-geolocated";
import { useNavigate } from "react-router-dom";

export default function Header({ logoVisible = false }) {

    const navigate = useNavigate();

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

    const [notificationCount, setNotificationCount] = useState(0);
    const [notificationList, setNotificationList] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);

    const isDark = useSelector((state) => state.themeConfig.isDark)

    const dispatch = useDispatch();

    useEffect(() => {


        let darkStorage = JSON.parse(localStorage.getItem('dark'))
        let layoutStorage = localStorage.getItem('layout')

        if (darkStorage !== null) {
            dispatch(setIsDark(darkStorage))
        }

        if (layoutStorage !== null) {
            dispatch(setMenuOption(layoutStorage))
        }
    }, [])

    useEffect(() => {
        if (isDark) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [isDark]);

    useEffect(() => {
        // setInterval(() => {
        //     getNotificationCount()
        // }, 5000)
    }, [])

    const getNotificationCount = async () => {
        var response = await getAxios({
            url: notificationCountEndPoint
        })

        if (response != null) {
            setNotificationCount(response.data)
            if (response.data > 0) {
                getNotificationList()
            }
        }
    }

    const getNotificationList = async () => {
        var response = await getAxios({
            url: notificationListEndPoint
        })

        if (response != null) {
            setNotificationList(response.data)

            // for (var item of response.data) {
            //     if (!item.is_read) {

            //         addNotification({
            //             title: item.title,
            //             message: item.message,
            //             duration: 2000,
            //             icon: Logo,
            //             native: true
            //         })
            //     }
            // }

        }
    }


    const logout = async () => {
        if (isGeolocationAvailable) {
            if (isGeolocationEnabled) {
                var body = {}
                body["lattitude"] = coords.latitude
                body["longitude"] = coords.longitude
                var response = await postAxiosWithToken({
                    url: logoutEndPoint,
                    body: body
                })

                if (response !== null) {
                    if (response.status === 200) {
                        toast.success(response.message)
                        localStorage.removeItem('login')
                        localStorage.setItem('authenticated', JSON.parse(false))
                        window.location.reload()
                    } else {
                        toast.error(response.message)
                    }
                }
            } else {
                toast.error('Enable the location')
            }
        } else {
            toast.error('Enable the location')
        }
    }

    return (
        <>
            <div className="flex justify-between px-[15px] py-[5px] border-b h-[65px] shadow-lg">
                <div className={`flex gap-2 items-center `}>
                    <div onClick={() => {
                        document.getElementById('menubar').className = 'fixed top-0 left-0 z-[5] block lg:block'
                    }}
                        className={`${logoVisible ? "md:hidden" : "lg:hidden"}`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="48" d="M88 152h336M88 256h336M88 360h336" />
                        </svg>
                    </div>
                    <div className={`h-[55px] w-[150px] ${logoVisible ? 'block' : 'hidden'}`}>
                        <img src={LogoImage} alt="no-logo" />
                    </div>
                </div>

                <div className="flex gap-5 items-center">

                    <div className="relative">
                        <div onClick={() => setShowNotifications(!showNotifications)} className=" rounded-lg border p-[5px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24"><path fill="currentColor" d="M10 21h4c0 1.1-.9 2-2 2s-2-.9-2-2m11-2v1H3v-1l2-2v-6c0-3.1 2-5.8 5-6.7V4c0-1.1.9-2 2-2s2 .9 2 2v.3c3 .9 5 3.6 5 6.7v6l2 2m-4-8c0-2.8-2.2-5-5-5s-5 2.2-5 5v7h10v-7Z" /></svg>
                        </div>
                        <div className="absolute -top-2 -right-2 w-[20px] h-[20px] flex justify-center items-center rounded-full bg-primary text-[12px] text-white">{notificationCount}</div>

                        <div className={`${showNotifications ? "block" : "hidden"} hover:bg-secondary bg-white shadow-lg border rounded-lg absolute top-10 right-0 w-[250px]`}>
                            {
                                notificationList.map((item, index) => (
                                    <div key={"notification" + index} className="px-[10px] py-[5px] border-b">
                                        <p className="text-primary font-[500]">{item.title}</p>
                                        <p className="text-[12px]">{item.message}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div onClick={() => dispatch(setIsDark(!isDark))} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M0 0h24v24H0z" /><path fill={isDark ? "rgb(255, 243, 232)" : "#ff7a00"} d="M4 11a1 1 0 0 1 .117 1.993L4 13H3a1 1 0 0 1-.117-1.993L3 11h1zm8-9a1 1 0 0 1 .993.883L13 3v1a1 1 0 0 1-1.993.117L11 4V3a1 1 0 0 1 1-1zm9 9a1 1 0 0 1 .117 1.993L21 13h-1a1 1 0 0 1-.117-1.993L20 11h1zM4.893 4.893a1 1 0 0 1 1.32-.083l.094.083l.7.7a1 1 0 0 1-1.32 1.497l-.094-.083l-.7-.7a1 1 0 0 1 0-1.414zm12.8 0a1 1 0 0 1 1.497 1.32l-.083.094l-.7.7a1 1 0 0 1-1.497-1.32l.083-.094l.7-.7zM14 18a1 1 0 0 1 1 1a3 3 0 0 1-6 0a1 1 0 0 1 .883-.993L10 18h4zM12 6a6 6 0 0 1 3.6 10.8a1 1 0 0 1-.471.192L15 17H9a1 1 0 0 1-.6-.2A6 6 0 0 1 12 6z" /></g></svg>
                    </div>

                    <div className="flex gap-4 items-center">
                        <div className="flex gap-4">
                            <div className="w-[35px] md:w-[40px] h-[35px] md:h-[40px] rounded-full bg-gray-300">
                                {/* <img src={LogoImage} alt="no-logo" /> */}
                            </div>
                            <div className="hidden min-[420px]:block text-[13px] md:text-[15px]">
                                <p>Dhinagaran</p>
                                <p>Admin</p>
                            </div>
                        </div>
                        <div onClick={() => logout()} className="border rounded p-[5px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2"><path stroke-dasharray="32" stroke-dashoffset="32" d="M12 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H12"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.4s" values="32;0" /></path><path stroke-dasharray="12" stroke-dashoffset="12" d="M9 12h11.5" opacity="0"><set attributeName="opacity" begin="0.5s" to="1" /><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.5s" dur="0.2s" values="12;0" /></path><path stroke-dasharray="6" stroke-dashoffset="6" d="M20.5 12l-3.5 -3.5M20.5 12l-3.5 3.5" opacity="0"><set attributeName="opacity" begin="0.7s" to="1" /><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.7s" dur="0.2s" values="6;0" /></path></g></svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}