import { useNavigate } from "react-router-dom";
import { purchaseReturnEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { datetimetodate } from "../../../utilities/datetime";
import { useEffect, useState } from "react";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function ViewPurchaseReturnDetails() {
    const navigate = useNavigate();

    const [returnDetails, setReturnDetails] = useState({});

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {
        getPurchaseReturnDetails()
    }, [])

    const getPurchaseReturnDetails = async () => {
        var response = await getAxios({
            url: purchaseReturnEndPoint + localStorage.getItem('purchase_return_id') + '/'
        })

        if (response != null) {
            setReturnDetails(response.data)
        }
    }

    return (
        <>
        <div className="my-[50px]">
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Purrchase Return Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Return Id</p>
                            <div>{returnDetails?.return_id}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Total Items</p>
                            <div>{returnDetails?.total_items}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Amount</p>
                            <div className="capitalize w-fit">{returnDetails?.amount}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                            <div>{returnDetails?.gst_amount}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Created By</p>
                            <div>{returnDetails?.created_by_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Created At</p>
                            <div>{returnDetails.created_at && datetimetodate(returnDetails.created_at)}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier</p>
                            <div>{returnDetails?.supplier_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier Id</p>
                            <div>{returnDetails?.supplier_id}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Particular Details</p>
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr className="border">
                                <td className="p-[10px] w-[50px]">S.No</td>
                                <td className="p-[10px] w-[200px]">Product</td>
                                <td className="p-[10px] w-[100px]">Rate</td>
                                <td className="p-[10px] w-[100px]">Order Qty</td>
                                <td className="p-[10px] w-[100px]">Net. Weight</td>
                                <td className="p-[10px] w-[100px]">GST Total</td>
                                <td className="p-[10px] w-[100px]">Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                returnDetails?.particulars?.map((item, index) => (
                                    <tr className="border">
                                        <td className="p-[10px] w-[50px]">{index + 1}</td>
                                        <td className="p-[10px] w-[200px]">{item.product_name}</td>
                                        <td className="p-[10px] w-[100px]">{item.purchase_price}</td>
                                        <td className="p-[10px] w-[100px]">{item.quantity}</td>
                                        <td className="p-[10px] w-[100px]">{item.nt_weight} {item.measurement}</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_amount}</td>
                                        <td className="p-[10px] w-[100px]">{item.total_amount}</td>

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {
                        returnDetails?.particulars?.length > 0 && <div>
                            <div className="flex flex-col items-end ">
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Gst Amount</div>
                                    <div>{returnDetails.gst_amount}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Grand Total</div>
                                    <div>{returnDetails.amount}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                    <button
                        id="backnav"
                        className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                        onClick={(e) => { e.preventDefault(); navigate(-1) }}
                    >close<span className="lowercase">(esc)</span></button>
                </div>
            </div>
        </>
    );
}