export const converttojson = (data) => {
    try {
        return JSON.parse(data)
    } catch (err) {
        return {}
    }
}

export const isCherries = (fruit) => {
    return fruit.name === "cherries" && fruit.quantity === 5;
  }