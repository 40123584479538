import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { branchListEndPoint, departmentListEndPoint, designationListEndPoint, floorListEndPoint, gstVerifyEndPoint, ifscVerifyEndPoint, locationListEndPoint, supplierEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from 'country-state-city';
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import SwitchButton from "../../../components/Switch";
import { isValid } from "date-fns";
import { isOptionalValidForm } from "../../../utilities/formvalidation";

const gstRequiredList = ["pan_no", "gst_no", "registered_name", "gst_status", "tax_payer_type", "bussiness_type"]
const accountRequiredList = ["acc_holder_name", "account_no", "ifsc_code", "bank_name", "branch_name", "micr_code"]

export default function SupplierForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');

    const [countryDropdown, setCountryDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [cityDropdown, setCityDropdown] = useState([]);

    const [gstEnabled, setGstEnabled] = useState(false);
    const [bankEnabled, setBankEnabled] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [currentSupplierErrors, setCurrentSupplierErrors] = useState({});

    const [currentSupplier, setCurrentSupplier] = useState({
        supplier_name: "",
        email: "",
        phone: "",
        due_amount: "",
        outstanding_amount: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        pan_no: "",
        gst_no: "",
        registered_name: "",
        gst_status: "",
        tax_payer_type: "",
        bussiness_type: "",
        acc_holder_name: "",
        account_no: "",
        ifsc_code: "",
        bank_name: "",
        branch_name: "",
        micr_code: "",
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };


    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {

        var countryTempList = []
        Country.getAllCountries().map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            countryTempList.push(dictData)
        })

        setCountryDropdown(countryTempList)

        setFormMode(localStorage.getItem('supplier_form_mode'))

        if (localStorage.getItem('supplier_form_mode') === 'update') {
            getSupplierDetails()
        }
    }, [])

    const getSupplierDetails = async () => {
        var response = await getAxios({
            url: supplierEndPoint + localStorage.getItem('supplier_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                supplier_name: response.data?.supplier_details?.supplier_name,
                email: response.data?.supplier_details?.email,
                mobile: response.data?.supplier_details?.mobile,
                due_amount: response.data?.supplier_details?.due_amount,
                outstanding_amount: response.data?.supplier_details?.outstanding_amount,
                address: response.data?.supplier_details?.address,
                country: response.data?.supplier_details?.country,
                state: response.data?.supplier_details?.state,
                city: response.data?.supplier_details?.city,
                pincode: response.data?.supplier_details?.pincode,
                pan_no: response.data?.supplier_gst_details?.pan_no,
                gst_no: response.data?.supplier_gst_details?.gst_no,
                registered_name: response.data?.supplier_gst_details?.registered_name,
                gst_status: response.data?.supplier_gst_details?.gst_status,
                tax_payer_type: response.data?.supplier_gst_details?.tax_payer_type,
                bussiness_type: response.data?.supplier_gst_details?.bussiness_type,
                acc_holder_name: response.data?.supplier_bank_details?.acc_holder_name,
                account_no: response.data?.supplier_bank_details?.account_no,
                ifsc_code: response.data?.supplier_bank_details?.ifsc_code,
                bank_name: response.data?.supplier_bank_details?.bank_name,
                branch_name: response.data?.supplier_bank_details?.branch_name,
                micr_code: response.data?.supplier_bank_details?.micr_code,
            }

            setGstEnabled(true)
            setBankEnabled(true)

            if (response.data?.supplier_details?.country !== undefined && response.data?.supplier_details?.country !== null && response.data?.supplier_details?.country.length !== 0) {
                setSelectedCountry(JSON.parse(response.data?.supplier_details?.country))
                getStateDropdown(response.data?.supplier_details?.country)
            }

            if (response.data?.supplier_details?.state !== undefined && response.data?.supplier_details?.state !== null && response.data?.supplier_details?.state.length !== 0) {
                setSelectedState(JSON.parse(response.data?.supplier_details?.state))
                setSelectedCity(response.data?.supplier_details?.city)
            }

            setCurrentSupplier(tempDetails)
        }
    }

    useEffect(() => {
        getCityDropdown(JSON.stringify(selectedState))
    }, [selectedState])

    const getStateDropdown = (value) => {
        var stateTempList = []
        State.getStatesOfCountry(JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            stateTempList.push(dictData)
        })
        setStateDropdown(stateTempList)
    }

    const getCityDropdown = useCallback((value) => {
        var cityTempList = []
        City.getCitiesOfState(selectedCountry.isoCode, JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name
            }
            cityTempList.push(dictData)
        })
        setCityDropdown(cityTempList)
    }, [selectedState]);

    const onSubmit = async (e) => {
        e.preventDefault();

        let notRequiredList = []

        if (!gstEnabled) {
            notRequiredList = notRequiredList + gstRequiredList
        }

        if (!bankEnabled) {
            notRequiredList = notRequiredList + accountRequiredList
        }

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0 && !notRequiredList.includes(field[0])) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setCurrentSupplierErrors(err)        

        if (isOptionalValidForm(formDataObject, notRequiredList)) {
        if (localStorage.getItem('supplier_form_mode') === 'create') {
            
            if (formDataObject['country'] !== undefined && formDataObject['country'] !== null && formDataObject['country'].length !== 0) {
                formDataObject['country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.country).name,
                    "isoCode": JSON.parse(formDataObject.country).isoCode
                })
            }
            if (formDataObject['state'] !== undefined && formDataObject['state'] !== null && formDataObject['state'].length !== 0) {
                formDataObject['state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.state).name,
                    "isoCode": JSON.parse(formDataObject.state).isoCode
                })
            }

            let createBody = {}
            createBody['gst_enabled'] = gstEnabled
            createBody['bank_enabled'] = bankEnabled
            createBody['supplier_details'] = {
                supplier_name: formDataObject.supplier_name,
                email: formDataObject.email,
                mobile: formDataObject.mobile,
                address: formDataObject.address,
                country: formDataObject.country,
                state: formDataObject.state,
                city: formDataObject.city,
                pincode: formDataObject.pincode
            }
            createBody['supplier_gst_details'] = gstEnabled ? {
                pan_no: formDataObject.pan_no,
                gst_no: formDataObject.gst_no,
                registered_name: formDataObject.registered_name,
                gst_status: formDataObject.gst_status,
                tax_payer_type: formDataObject.tax_payer_type,
                bussiness_type: formDataObject.bussiness_type
            } : {}

            createBody['supplier_bank_details'] = bankEnabled ? {
                acc_holder_name: formDataObject.acc_holder_name,
                account_no: formDataObject.account_no,
                ifsc_code: formDataObject.ifsc_code,
                bank_name: formDataObject.bank_name,
                branch_name: formDataObject.branch_name,
                micr_code: formDataObject.micr_code
            } : {}

            createSupplier(createBody)

        } else {
            if (formDataObject['country'] !== undefined && formDataObject['country'] !== null && formDataObject['country'].length !== 0) {
                formDataObject['country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.country).name,
                    "isoCode": JSON.parse(formDataObject.country).isoCode
                })
            }
            if (formDataObject['state'] !== undefined && formDataObject['state'] !== null && formDataObject['state'].length !== 0) {
                formDataObject['state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.state).name,
                    "isoCode": JSON.parse(formDataObject.state).isoCode
                })
            }

            let updateBody = {}
            updateBody['gst_enabled'] = gstEnabled
            updateBody['bank_enabled'] = bankEnabled
            updateBody['supplier_details'] = {
                supplier_name: formDataObject.supplier_name,
                email: formDataObject.email,
                mobile: formDataObject.mobile,
                address: formDataObject.address,
                country: formDataObject.country,
                state: formDataObject.state,
                city: formDataObject.city,
                pincode: formDataObject.pincode
            }
            updateBody['supplier_gst_details'] = gstEnabled ? {
                pan_no: formDataObject.pan_no,
                gst_no: formDataObject.gst_no,
                registered_name: formDataObject.registered_name,
                gst_status: formDataObject.gst_status,
                tax_payer_type: formDataObject.tax_payer_type,
                bussiness_type: formDataObject.bussiness_type
            } : {}

            updateBody['supplier_bank_details'] = bankEnabled ? {
                acc_holder_name: formDataObject.acc_holder_name,
                account_no: formDataObject.account_no,
                ifsc_code: formDataObject.ifsc_code,
                bank_name: formDataObject.bank_name,
                branch_name: formDataObject.branch_name,
                micr_code: formDataObject.micr_code
            } : {}

            updateSupplier(updateBody)
        }
        }
    }

    const createSupplier = async (body) => {
        var response = await postAxiosWithToken({
            url: supplierEndPoint,
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/vendors/supplier')
        }
    }

    const updateSupplier = async (body) => {
        var response = await putAxiosWithToken({
            url: supplierEndPoint + localStorage.getItem('supplier_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            localStorage.removeItem('supplier_form_id')
            localStorage.setItem('supplier_form_mode', 'create')
            toast.success(response.message)
            navigate('/vendors/supplier')
        }
    }

    const clearData = () => {
        setCurrentSupplier({
            supplier_name: "",
            email: "",
            phone: "",
            due_amount: "",
            outstanding_amount: "",
            address: "",
            country: "",
            state: "",
            city: "",
            pincode: "",
            pan_no: "",
            gst_no: "",
            registered_name: "",
            gst_status: "",
            tax_payer_type: "",
            bussiness_type: "",
            acc_holder_name: "",
            account_no: "",
            ifsc_code: "",
            bank_name: "",
            branch_name: "",
            micr_code: "",
        })
    }

    const getGstDetails = async (gstId) => {
        var response = await getAxios({
            url: gstVerifyEndPoint + gstId + "/",
        })

        if (response != null) {
            setCurrentSupplier((state) => ({
                ...state,
                ['registered_name']: response.data.registered_name,
                ['gst_status']: response.data.gst_status,
                ['tax_payer_type']: response.data.tax_payer_type,
                ['bussiness_type']: response.data.bussiness_type
            }))
            setCurrentSupplierErrors((state) => ({
                ...state,
                ['registered_name']: "",
                ['gst_status']: "",
                ['tax_payer_type']: "",
                ['bussiness_type']: ""
            }));
        }
    }

    const getBankDetails = async (ifsc) => {
        var response = await getAxios({
            url: ifscVerifyEndPoint + ifsc + "/",
        })

        if (response != null) {
            setCurrentSupplier({
                ...currentSupplier,
                ...{
                    bank_name: response.data.bank_name,
                    branch_name: response.data.branch_name,
                    micr_code: response.data.micr_code,
                }
            })
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target

        if (name === 'country') {
            getStateDropdown(value)
            setSelectedCountry(JSON.parse(value))
            setCurrentSupplier((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'state') {
            getCityDropdown(value)
            setSelectedState(JSON.parse(value))
            setCurrentSupplier((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'city') {
            setSelectedCity(value)
            setCurrentSupplier((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setCurrentSupplier((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

        setCurrentSupplierErrors((prevState) => ({
            ...prevState,
            [name]: value.length === 0 ? `${name} field is required` : ''
        }));
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Supplier</p>
                <form onSubmit={onSubmit}>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Supplier Name</p>
                                <input
                                    type="text"
                                    placeholder="supplier name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="supplier_name"
                                    value={currentSupplier.supplier_name}
                                    onChange={onValueUpdate}
                                    id="forminit"
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.supplier_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Email</p>
                                <input
                                    type="text"
                                    placeholder="email"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="email"
                                    value={currentSupplier.email}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.email}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Phone no</p>
                                <input
                                    type="text"
                                    placeholder="Phone no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="mobile"
                                    value={currentSupplier.mobile}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.mobile}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Supplier Due</p>
                                <input
                                    type="text"
                                    placeholder="Supplier Due"
                                    className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="due_amount"
                                    value={currentSupplier.due_amount}
                                    disabled
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.due_amount}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Supplier Outstanding</p>
                                <input
                                    type="text"
                                    placeholder="supplier outstanding"
                                    className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="outstanding_amount"
                                    value={currentSupplier.outstanding_amount}
                                    disabled
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.outstanding_amount}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Address Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Address</p>
                                <input
                                    type="text"
                                    placeholder="address"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="address"
                                    value={currentSupplier.address}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.address}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Country</p>
                                <select
                                    placeholder="country"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="country"
                                    value={currentSupplier.country}
                                    onChange={onValueUpdate}

                                >
                                    <option value={""}>--select country--</option>
                                    {
                                        countryDropdown.map((option) => (
                                            <option className="capitalize" key={"country" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.country}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">State</p>
                                <select
                                    placeholder="state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="state"
                                    value={currentSupplier.state}
                                    onChange={onValueUpdate}
                                // onChange={(e) => { setSelectedState(JSON.parse(e.target.value)); getCityDropdown(e.target.value) }}
                                >
                                    <option value={""}>--select state--</option>
                                    {
                                        stateDropdown.map((option) => (
                                            <option className="capitalize" key={"state" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.state}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">City</p>
                                <select
                                    placeholder="city"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="city"
                                    value={selectedCity}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select city--</option>
                                    {
                                        cityDropdown.map((option) => (
                                            <option className="capitalize" key={"city" + option.name} value={option.name}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.city}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pincode</p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                    value={currentSupplier.pincode}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.pincode}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2 flex justify-between">
                            <div>GST Details</div>
                            <div>
                                <SwitchButton enabled={gstEnabled} setEnabled={setGstEnabled} />
                            </div>
                        </p>
                        <div className={`${gstEnabled ? 'block' : 'hidden'} grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5`}>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pan Number</p>
                                <input
                                    type="text"
                                    placeholder="pan no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pan_no"
                                    value={currentSupplier.pan_no}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.pan_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST NO</p>
                                <div className="bg-white sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] flex justify-between items-center">
                                    <input
                                        type="text"
                                        placeholder="gst no"
                                        className=" outline-none w-full"
                                        name="gst_no"
                                        id="gst_no"
                                        value={currentSupplier.gst_no}
                                        onChange={onValueUpdate}
                                    />
                                    <button type="button" className="cursor-pointer text-primary" onClick={() => getGstDetails(document.getElementById('gst_no')?.value)}>verify</button>
                                </div>
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.gst_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST Status</p>
                                <input
                                    type="text"
                                    placeholder="gst status"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="gst_status"
                                    value={currentSupplier.gst_status}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.gst_status}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST Type</p>
                                <input
                                    type="text"
                                    placeholder="gst type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="tax_payer_type"
                                    id="tax_payer_type"
                                    value={currentSupplier.tax_payer_type}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.tax_payer_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Bussiness Type</p>
                                <input
                                    type="text"
                                    placeholder="bussiness type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="bussiness_type"
                                    id="bussiness_type"
                                    value={currentSupplier.bussiness_type}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.bussiness_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Registered Name</p>
                                <input
                                    type="text"
                                    placeholder="registered name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="registered_name"
                                    id="registered_name"
                                    value={currentSupplier.registered_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.registered_name}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2 flex justify-between">
                            <div>Bank Details</div>
                            <div>
                                <SwitchButton enabled={bankEnabled} setEnabled={setBankEnabled} />
                            </div>
                        </p>
                        <div className={`${bankEnabled ? 'block' : 'hidden'} grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5`}>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Account Holder Name</p>
                                <input
                                    type="text"
                                    placeholder="account holder name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="acc_holder_name"
                                    value={currentSupplier.acc_holder_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.acc_holder_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Account No</p>
                                <input
                                    type="text"
                                    placeholder="account no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="account_no"
                                    value={currentSupplier.account_no}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.account_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">IFSC Code</p>
                                <div className={`bg-white sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] flex justify-between items-center`}>
                                    <input
                                        type="text"
                                        placeholder="ifsc code"
                                        className=" outline-none w-full"
                                        name="ifsc_code"
                                        id={"ifsccode"}
                                        value={currentSupplier.ifsc_code}
                                        onChange={onValueUpdate}
                                    />
                                    <button type="button" className="cursor-pointer text-primary" onClick={() => getBankDetails(document.getElementById("ifsccode")?.value)}>verify</button>
                                </div>
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.ifsc_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Bank Name</p>
                                <input
                                    type="text"
                                    placeholder="bank name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="bank_name"
                                    value={currentSupplier.bank_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.bank_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Branch Name</p>
                                <input
                                    type="text"
                                    placeholder="branch name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="branch_name"
                                    value={currentSupplier.branch_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.branch_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">MICR No</p>
                                <input
                                    type="text"
                                    placeholder="micr no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="micr_code"
                                    value={currentSupplier.micr_code}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentSupplierErrors.micr_code}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}