const baseUrl = 'https://api.vikkys.co';

export const iconUploadEndPoint = baseUrl + '/settings/icon-upload/';

export const loginEndPoint = baseUrl + '/accounts/login/';
export const logoutEndPoint = baseUrl + '/accounts/logout/';
export const changePasswordEndPoint = baseUrl + '/accounts/change-password/';

export const menuPermissionEndPoint = baseUrl + '/settings/menu-permission/';
export const activeUserPermissionEndPoint = baseUrl + '/settings/active-user-menu-permission/';
export const gstVerifyEndPoint = baseUrl + '/settings/gst-verification/';
export const ifscVerifyEndPoint = baseUrl + '/settings/ifsc-verification/';

export const statusTypeListEndPoint = baseUrl + '/settings/status-type-list/';
export const utrTypeListEndPoint = baseUrl + '/settings/utr-type-list/';

export const userRoleListEndPoint = baseUrl + '/settings/user-role-list/';
export const userRoleEndPoint = baseUrl + '/settings/user-role/';
export const userRoleStatusEndPoint = baseUrl + '/settings/change-user-role-status/';

export const menuListEndPoint = baseUrl + '/settings/menu-list/';
export const menuEndPoint = baseUrl + '/settings/menu/';
export const menuStatusEndPoint = baseUrl + '/settings/change-menu-status/';

export const menuGroupListEndPoint = baseUrl + '/settings/menu-group-list/';
export const menuGroupEndPoint = baseUrl + '/settings/menu-group/';
export const menuGroupStatusEndPoint = baseUrl + '/settings/change-menu-group-status/';

export const locationListEndPoint = baseUrl + '/organizations/location-list/';
export const locationEndPoint = baseUrl + '/organizations/location/';
export const locationStatusEndPoint = baseUrl + '/organizations/change-location-status/';

export const branchListEndPoint = baseUrl + '/organizations/branch-list/';
export const branchEndPoint = baseUrl + '/organizations/branch/';
export const branchStatusEndPoint = baseUrl + '/organizations/change-branch-status/';

export const departmentListEndPoint = baseUrl + '/organizations/department-list/';
export const departmentEndPoint = baseUrl + '/organizations/department/';
export const departmentStatusEndPoint = baseUrl + '/organizations/change-department-status/';

export const designationListEndPoint = baseUrl + '/organizations/designation-list/';
export const designationEndPoint = baseUrl + '/organizations/designation/';
export const designationStatusEndPoint = baseUrl + '/organizations/change-designation-status/';

export const staffListEndPoint = baseUrl + '/organizations/staff-list/';
export const staffEndPoint = baseUrl + '/organizations/staff/';
export const staffStatusEndPoint = baseUrl + '/organizations/change-staff-status/';

export const userListEndPoint = baseUrl + '/organizations/user-list/';
export const userEndPoint = baseUrl + '/organizations/user/';
export const userStatusEndPoint = baseUrl + '/organizations/change-user-status/';
export const userListByRoleEndPoint = baseUrl + '/organizations/user-list-by-role/';

export const divisionListEndPoint = baseUrl + '/product/division-list/';
export const divisionEndPoint = baseUrl + '/product/division/';
export const divisionStatusEndPoint = baseUrl + '/product/change-division-status/';

export const categoryListEndPoint = baseUrl + '/product/category-list/';
export const categoryEndPoint = baseUrl + '/product/category/';
export const categoryStatusEndPoint = baseUrl + '/product/change-category-status/';

export const subCategoryListEndPoint = baseUrl + '/product/sub-category-list/';
export const subCategoryEndPoint = baseUrl + '/product/sub-category/';
export const subCategoryStatusEndPoint = baseUrl + '/product/change-sub-category-status/';

export const brandListEndPoint = baseUrl + '/product/brand-list/';
export const brandEndPoint = baseUrl + '/product/brand/';
export const brandStatusEndPoint = baseUrl + '/product/change-brand-status/';

export const unitListEndPoint = baseUrl + '/product/unit-list/';
export const unitEndPoint = baseUrl + '/product/unit/';
export const unitStatusEndPoint = baseUrl + '/product/change-unit-status/';

export const gstPercentListEndPoint = baseUrl + '/product/gst-percent-list/';
export const gstPercentEndPoint = baseUrl + '/product/gst-percent/';
export const gstPercentStatusEndPoint = baseUrl + '/product/change-gst-percent-status/';

export const productListEndPoint = baseUrl + '/product/product-list/';
export const productEndPoint = baseUrl + '/product/product/';
export const productStatusEndPoint = baseUrl + '/product/change-product-status/';
export const productListBySupplierEndPoint = baseUrl + '/product/product-list-by-supplier/';

export const gstTypeListEndPoint = baseUrl + '/settings/gst-type-list/';

export const supplierListEndPoint = baseUrl + '/supplier/supplier-list/';
export const supplierEndPoint = baseUrl + '/supplier/supplier/';
export const supplierStatusEndPoint = baseUrl + '/supplier/change-supplier-status/';

export const approvalTypeListEndPoint = baseUrl + '/approval/approval-type-list/';
export const approvalTypeEndPoint = baseUrl + '/approval/approval-type/';
export const approvalTypeStatusEndPoint = baseUrl + '/approval/change-approval-type-status/';

export const approvalRuleListEndPoint = baseUrl + '/approval/approval-rule-list/';
export const approvalRuleEndPoint = baseUrl + '/approval/approval-rule/';
export const approvalRuleStatusEndPoint = baseUrl + '/approval/change-approval-rule-status/';

export const purchaseRequirementApprovalListEndPoint = baseUrl + '/approval/purchase-requirements-approval-list/';
export const salesRequirementApprovalListEndPoint = baseUrl + '/approval/sales-requirements-approval-list/';

export const purchaseRequirementListEndPoint = baseUrl + '/purchase/purchase-requirements-list/';
export const purchaseRequirementEndPoint = baseUrl + '/purchase/purchase-requirements/';
export const purchaseRequirementStatusEndPoint = baseUrl + '/purchase/change-purchase-requirements-status/';

export const purchaseOrderListEndPoint = baseUrl + '/purchase/purchase-order-list/';
export const purchaseOrderEndPoint = baseUrl + '/purchase/purchase-order/';
export const purchaseOrderStatusEndPoint = baseUrl + '/purchase/change-purchase-order-status/';

export const purchaseOrderByBatchEndPoint = baseUrl + "/purchase/purchase-order-by-batch/";

export const purchaseReturnListEndPoint = baseUrl + '/purchase/purchase-return-list/';
export const purchaseReturnEndPoint = baseUrl + '/purchase/purchase-return/';

export const poPaymentListEndPoint = baseUrl + '/finance/po-payment-list/';
export const poPaymentEndPoint = baseUrl + '/finance/po-payment/';
export const poPaymentStatusEndPoint = baseUrl + '/finance/change-po-payment-status/';

export const instockListEndPoint = baseUrl + '/stock-management/in-stock-list/';
export const instockEndPoint = baseUrl + '/stock-management/in-stock/';

export const stockListEndPoint = baseUrl + '/stock-management/stock-list/';
export const productWiseStockListEndPoint = baseUrl + '/stock-management/product-wise-stock-list/';

export const s3ImageUploadEndPoint = baseUrl + '/miscellaneous/s3-upload/';

export const notificationCountEndPoint = baseUrl + '/notifications/notification-count/';
export const notificationListEndPoint = baseUrl + '/notifications/notification-list/';

export const customerEndPoint = baseUrl + '/customer/customer/';
export const customerListEndPoint = baseUrl + '/customer/customer-list/';
export const customerStatusEndPoint = baseUrl + '/customer/change-customer-status/';
export const customerProductEndPoint = baseUrl + '/customer/customer-product/';
export const productListByCustomerEndPoint = baseUrl + '/customer/product-list-by-customer/';

export const salesRequirementListEndPoint = baseUrl + '/sales/sales-requirements-list/';
export const salesRequirementEndPoint = baseUrl + '/sales/sales-requirements/';
export const salesRequirementStatusEndPoint = baseUrl + '/sales/change-sales-requirements-status/';

export const salesOrderListEndPoint = baseUrl + '/sales/sales-order-list/';
export const salesOrderEndPoint = baseUrl + '/sales/sales-order/';
export const salesOrderStatusEndPoint = baseUrl + '/sales/change-sales-order-status/';

export const soPaymentListEndPoint = baseUrl + '/finance/so-payment-list/';
export const soPaymentEndPoint = baseUrl + '/finance/so-payment/';
export const soPaymentStatusEndPoint = baseUrl + '/finance/change-so-payment-status/';

export const salesExecutivesListEndPoint = baseUrl + '/sales-executive/sales-executives-list/';
export const salesExecutivesEndPoint = baseUrl + '/sales-executive/sales-executives/';

export const targetTypeListEndPoint = baseUrl + "/sales-executive/target-type-list/";

export const stockByBatchCheckEndPoint = baseUrl + "/stock-management/stock-by-batch/";

export const outstockEndPoint = baseUrl + "/stock-management/out-stock/";
export const outstockListEndPoint = baseUrl + '/stock-management/out-stock-list/';
export const outStockStatusEndPoint = baseUrl + '/stock-management/change-out-stock-status/';