import { useEffect, useState } from "react";
import { gstTypeListEndPoint, productEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function ViewProductDetails() {

    const navigate = useNavigate();

    const [currentProduct, setCurrentProduct] = useState({});
    const [gstTypeListInput, setGstTypeListInput] = useState([]);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            navigate(-1)
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {
        getProductDetails()
    },[])

    const getProductDetails = async () => {
        var response = await getAxios({
            url: productEndPoint + localStorage.getItem('product_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                product_name: response.data?.product_name,
                product_code: response.data?.product_code,
                nt_weight: response.data?.nt_weight,
                hsn: response.data?.hsn,
                purchase_price: response.data?.purchase_price,
                mrp: response.data?.mrp,
                unit: response.data?.unit_name,
                measurement: response.data?.measurement_name,
                division: response.data?.division_name,
                category: response.data?.category_name,
                supplier_name: response.data?.supplier_name,
                supplier_id: response.data?.supplier_id,
                sub_category: response.data?.sub_category_name,
                brand: response.data?.brand_name,
                image: response.data?.image,
                gst_value: response.data?.gst_value
            }

            setCurrentProduct(tempDetails)

            getGstDropdownData(response.data);

        }
    }

    const getGstDropdownData = async (product) => {
        var response = await getAxios({
            url: gstTypeListEndPoint
        })

        if (response !== null) {
            setGstTypeListInput(response.data.list)
        }
    }
    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">Product Details</p>

                <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Product Image</p>
                    <div className="w-[400px] h-[400px] mx-auto">
                        <img src={currentProduct?.image} alt="no-image" />
                    </div>
                </div>

                <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[500]">Supplier Name</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.supplier_name}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[500]">Supplier Id</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.supplier_id}</p>
                        </div>
                    </div>
                </div>

                <div className="border p-[15px] rounded-xl shadow-lg">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Product Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[500]">Product Name</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.product_name}</p>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[500]">Division</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.division}</p>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[500]">Category</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.category}</p>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[500]">Sub Category</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.category}</p>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[500]">Brand</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.brand}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[500]">Product Code</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.product_code}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[500]">HSN</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.hsn}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[500]">Net Weight</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.nt_weight}</p>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[500]">Unit</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.measurement} ({currentProduct.unit})</p>
                        </div>
                    </div>
                </div>

                <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Price Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[500]">Purchase price</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.purchase_price}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[500]">Price</p>
                            <p className=" font-[400] text-[15px]">{currentProduct.mrp}</p>
                        </div>
                        {
                            currentProduct?.gst_value !== undefined && currentProduct?.gst_value !== null && Object.entries(currentProduct?.gst_value)?.map((item, index) => (
                                <div key={"input" + index} className="my-[5px]">
                                    <p className="mb-[5px] text-[15px] font-[500]">{item[0]}</p>
                                    <p className="font-[400] text-[15px]">{item[1]} %</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                    <button
                        id="backnav"
                        className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                        onClick={(e) => { e.preventDefault(); navigate(-1) }}
                    >close<span className="lowercase">(esc)</span></button>
                </div>
            </div>
        </>
    );
}