import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { productWiseStockListEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { useEffect, useState } from "react";

export default function ProductWiseStockDetails() {

    const navigate = useNavigate();

    const [stockProductDetails, setProductStockDetails] = useState([]);
    const [stockDetails, setStockDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {
        getStockDetails()
    }, [])

    const getStockDetails = async () => {
        var response = await getAxios({
            url: productWiseStockListEndPoint + localStorage.getItem('stock_id') + '/'
        })

        if (response != null) {
            setProductStockDetails(response.data)
            setStockDetails(response.data.stock_details)
            setIsLoading(false)
        }
    }
    return (
        <>
            <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                <div className="border-b flex justify-between items-center">
                    <p className="font-[600] text-[17px] text-primary mb-4 pb-2">Product Details</p>
                    <p className="font-[600] text-[17px] mb-4 pb-2">Available: <span className="text-primary">{stockProductDetails.available}</span></p>
                </div>
                <div className="flex flex-wrap gap-[25px] justify-between">
                    <div className="w-[200px] h-[200px] flex items-center">
                        <img src={stockProductDetails.image} alt="no-image" />
                    </div>
                    <div className="min-w-[300px] text-left">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Product Name</p>
                            <div>{stockProductDetails?.product_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Product Code</p>
                            <div>{stockProductDetails?.product_code}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Division</p>
                            <div>{stockProductDetails?.division_name}</div>
                        </div>

                    </div>
                    <div className="min-w-[300px] text-left">
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Category</p>
                            <div>{stockProductDetails?.category_name}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Sub Category</p>
                            <div>{stockProductDetails?.sub_category_name}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Net.Weight</p>
                            <div>{stockProductDetails?.nt_weight} {stockProductDetails?.unit_name}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">

                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr>
                                <td className="p-[10px]">Batch No</td>
                                <td className="p-[10px]">Mfg Date</td>
                                <td className="p-[10px]">Expiry Date</td>
                                <td className="p-[10px]">Purchase Id</td>
                                <td className="p-[10px]">Quantity</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={8}>Loading...</td>
                                </tr> :
                                    stockDetails.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                        stockDetails.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px] capitalize flex gap-5 items-center">
                                                    {item.batch_no}
                                                </td>
                                                <td className="p-[10px]">{item.mfg_date}</td>
                                                <td className="p-[10px]">{item.exp_date}</td>
                                                <td className="p-[10px]">
                                                    {item.purchase_order_id}
                                                </td>
                                                <td className={`p-[10px] capitalize ${item.quantity > 0 ? "" : "text-red-600" }`}>{item.quantity > 0 ? item.quantity : "Out of stock"}</td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
        </>
    );
}