import LogoImage from "../../assets/images/logo.svg";
import { datetimetodate } from "../../utilities/datetime";
import { converttojson } from "../../utilities/jsonvalidation";

export default function SalesRequiremnetCopy({ reqDetails }) {


    return (
        <div className="px-[25px] min-h-[1050px] relative">
            <div className="flex items-center justify-between py-[25px]">
                <div className="flex items-center gap-10">
                    <div>
                        <div className="w-[120px]">
                            <img src={LogoImage} alt="no-logo" />
                        </div>
                        <p className="uppercase text-[23px] mt-[20px]">Sales Requirement</p>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <div className="tracking-wider border border-primary bg-secondary text-primary rounded-lg px-[10px] py-[5px] w-fit h-fit">#{reqDetails.requirement_id}</div>
                    <p className="tracking-widest">{reqDetails.created_at && datetimetodate(reqDetails.created_at, 3)}</p>
                </div>
            </div>

            <div className="py-[25px] flex gap-[25px]">
                <div>
                    <p className="uppercase tracking-widest text-[15px] text-primary font-[500]">To Customer</p>

                    <address className="text-gray-500 w-[250px]">
                        <p className="text-[12px] my-[15px]">{reqDetails.customer_name}</p>
                        <p className="text-[12px]">{reqDetails.customer_address}</p>
                        <p className="text-[12px]">{converttojson(reqDetails.customer_state)?.name}</p>
                        <p className="text-[12px]">{reqDetails.customer_city} - {reqDetails.customer_pincode}</p>
                    </address>
                </div>

                <div>
                    <p className="uppercase tracking-widest text-[15px] text-primary font-[500]">Delivery Address</p>

                    <address className="text-gray-500 w-[250px]">
                        <p className="text-[12px] my-[15px]">{reqDetails.customer_name}</p>
                        <p className="text-[12px]">{reqDetails.customer_address}</p>
                        <p className="text-[12px]">{converttojson(reqDetails.customer_state)?.name}</p>
                        <p className="text-[12px]">{reqDetails.customer_city} - {reqDetails.customer_pincode}</p>
                    </address>
                </div>
            </div>

            <div className="mb-[25px]">
                <p className="font-[600] text-[17px] text-primary mb-4">Particular Details</p>
                <div className="border-b flex items-enter w-full justify-around">
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[200px]">Product</div>
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[100px]">Product Id</div>
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[100px]">Quantity</div>
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[70px]">Price</div>
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[100px]">Net. Weight</div>
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[70px]">Dis %</div>
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[100px]">Dis Total</div>
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[70px]">GST %</div>
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[100px]">GST Total</div>
                    <div className="p-[10px] text-gray-600 font-[500] text-[10px] w-[100px]">Total</div>
                </div>
                <div className="">
                    {
                        reqDetails?.particulars?.map((item, index) => (
                            <div className="flex items-enter w-full justify-around border-b">
                                <div className="p-[10px] text-[10px] w-[200px]">{item.product_name}</div>
                                <div className="p-[10px] text-[10px] w-[100px]">{item.product_code}</div>
                                <div className="p-[10px] text-[10px] w-[100px]">{item.quantity}</div>
                                <div className="p-[10px] text-[10px] w-[70px]">₹ {item.mrp}</div>
                                <div className="p-[10px] text-[10px] w-[100px]">{item.nt_weight} {item.unit}</div>
                                <div className="p-[10px] text-[10px] w-[70px]">{item.discount_percent} %</div>
                                <div className="p-[10px] text-[10px] w-[100px]">₹ {item.discount_amount}</div>
                                <div className="p-[10px] text-[10px] w-[70px]">{item.gst_percent} %</div>
                                <div className="p-[10px] text-[10px] w-[100px]">₹ {item.gst_total}</div>
                                <div className="p-[10px] text-[10px] w-[100px]">₹ {item.grand_total}</div>

                            </div>
                        ))
                    }

                </div>
                <div className="flex items-enter w-full justify-around bg-primary bg-opacity-[0.1]">
                    <div className="p-[10px] text-[10px] w-[200px]">Total Items: {reqDetails?.particulars?.length}</div>
                    <div className="p-[10px] text-[10px] w-[100px]"></div>
                    <div className="p-[10px] text-[10px] w-[100px]"></div>
                    <div className="p-[10px] text-[10px] w-[70px]">₹ {reqDetails.total_amount}</div>
                    <div className="p-[10px] text-[10px] w-[100px]"></div>
                    <div className="p-[10px] text-[10px] w-[70px]"></div>
                    <div className="p-[10px] text-[10px] w-[100px]"></div>
                    <div className="p-[10px] text-[10px] w-[70px]"></div>
                    <div className="p-[10px] text-[10px] w-[100px]">₹ {reqDetails.gst_amount}</div>
                    <div className="p-[10px] text-[10px] w-[100px]">₹ {reqDetails.grand_total}</div>

                </div>
                {
                    reqDetails?.particulars?.length > 0 && <div className="border-t">
                        <div className="flex flex-col items-end">

                            <div className="flex w-[200px] mt-[15px] justify-between items-center text-[12px] p-[5px] bg-primary bg-opacity-[0.1]">
                                <div>Grand Total</div>
                                <div>₹ {reqDetails.grand_total}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>



            <div className="absolute bottom-5 px-[15px] left-0 w-full">
                <div className="mb-[25px]">
                    <p>Delivery Date</p>
                    <p className="text-[#89969F]">{reqDetails?.order_req_date?.length > 0 ? reqDetails?.order_req_date : '-'}</p>
                </div>
                {/* <p>Shipping Terms</p>
                <ul className="border border-gray-600 rounded-lg px-[20px] py-[10px] bg-gray-200 w-full">
                    <li className="text-[13px] my-[5px]">1. Delivery within 15 days from the dates of PO releasing</li>
                    <li className="text-[13px] my-[5px]">2. Delivery charge extra</li>
                    <li className="text-[13px] my-[5px]">3. GST number to be mentioned all your invoice</li>
                    <li className="text-[13px] my-[5px]">4. Payment will be paid within 25 days after the goods received</li>
                </ul> */}
                <div className="mt-[50px] flex justify-between mx-[50px]">
                    <p>Prepared by</p>
                    <p>IA</p>
                    <p>EO</p>
                </div>
                {/* <div className="mt-[15px] flex justify-between mx-[50px]">
                    <div className="flex flex-col gap-[50px]">
                        <p>Prepared by</p>
                        <p>Signature</p>
                    </div>
                    <div className="flex flex-col gap-[50px]">
                        <p>For <span className="font-[500]">KKV(I) ASSOCIATES</span></p>
                        <p>Authorized Signature</p>
                    </div>
                </div> */}
            </div>
        </div>
    );
}