import { useEffect, useState } from "react";
import { datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { instockEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { useNavigate } from "react-router-dom";

export default function ViewInstockDetails() {
    const navigate = useNavigate();

    const [instockDetails, setInstockDetails] = useState([]);
    const [particulars, setParticulars] = useState([]);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {
        getInstockDetails()
    }, [])

    const getInstockDetails = async () => {
        var response = await getAxios({
            url: instockEndPoint + localStorage.getItem('instock_id') + '/'
        })

        if (response != null) {
            setInstockDetails(response.data)
            setParticulars(response.data.particulars)
        }
    }

    return (
        <div className="mb-[50px]">
            <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Instock Details</p>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px] font-[600]">Entry No</p>
                        <div>{instockDetails?.entry_no}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Entry Type</p>
                        <div>{instockDetails?.entry_type} no</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Instock Created By</p>
                        <div>{instockDetails?.created_by_name}</div>
                    </div>
                    <div className="my-[5px]" >
                        <p className="mb-[5px] text-[15px] font-[600]">Instock Created Date</p>
                        <div>{instockDetails?.created_at && datetimetodate(instockDetails.created_at)}</div>
                    </div>
                </div>
            </div>
            <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Purchase Details</p>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px] font-[600]">Purchase Id</p>
                        <div>{instockDetails?.purchase_order_id}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Order Date</p>
                        <div>{instockDetails.purchase_created_at && datetimetodate(instockDetails.purchase_created_at)}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Purchase Created By</p>
                        <div>{instockDetails?.purchase_created_by_name}</div>
                    </div>
                    <div className="my-[5px]" >
                        <p className="mb-[5px] text-[15px] font-[600]">Order Status</p>
                        <div style={{color: instockDetails?.order_status_color}} className="capitalize w-fit">{instockDetails?.order_status_name}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Required Date</p>
                        <div>{instockDetails?.order_req_date && datetimetodate(instockDetails?.order_req_date)}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                        <div>{instockDetails?.with_gst ? 'With' : 'Without'}</div>
                    </div>
                </div>
            </div>

            <div className="mb-[25px]">
                {
                    particulars.map((item, index) => (
                        <div className="p-[15px] shadow-lg border rounded-lg">
                            <div className="flex gap-[15px] items-start ">
                                <div className="min-w-[90px] max-w-[90px] min-h-[90px] max-h-[90px] bg-gray-300 rounded-lg">
                                    <img src={item.image} />
                                </div>
                                <div className="w-full">
                                    <div className="min-[650px]:flex justify-between items-start">
                                        <div>
                                            <p className="text-[12px] sm:text-[15px] font-[600]">{item.product_name}</p>
                                            <p className="text-[12px] sm:text-[15px] font-[400]">Product Code: <span className="text-primary font-[500]">{item.product_code}</span></p>
                                            <p className="text-[12px] sm:text-[15px] font-[400]">Order Quantity: <span className="text-primary font-[500]">{item.order_quantity}</span></p>
                                            <p className="text-[12px] sm:text-[15px] font-[400]">Total Recieved Quantity: <span className="text-primary font-[500]">{item.recieved_items}</span></p>
                                        </div>
                                        {
                                            item.is_completed ? <div className="sm:flex gap-[15px]">
                                            <div className="flex items-center gap-[5px]">
                                                <label className="text-[12px] sm:text-[15px] text-green-600">Completed</label>
                                            </div>
                                            <div className="border h-[full]"></div>
                                            <div className="min-[340px]:flex items-center gap-[5px]">
                                                <p className="text-[12px] sm:text-[15px]">Shortage Quantity :  </p>
                                                <p className="text-primary font-[500] ">{item.shortage_quantity}</p>
                                            </div>
                                        </div> : ""
                                        }
                                    </div>
            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[15px] mt-[10px]">
                                <div className="">
                                    <p className="mb-[5px] text-[15px] font-[500]">Recieved Items</p>
                                    <div>{item.recieved_quantity}</div>
                                </div>
                                <div className="">
                                    <p className="mb-[5px] text-[15px] font-[500]">Batch No</p>
                                    <div>{item.batch_no}</div>
                                </div>
                                <div className="">
                                    <p className="mb-[5px] text-[15px] font-[500]">Manufactured Date</p>
                                    <div>{item.mfg_date && datetimetodate(item.mfg_date)}</div>
                                </div>
                                <div className="">
                                    <p className="mb-[5px] text-[15px] font-[500]">Expiry Date</p>
                                    <div>{item.exp_date && datetimetodate(item.exp_date)}</div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                    <button
                        id="backnav"
                        className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                        onClick={(e) => { e.preventDefault(); navigate(-1) }}
                    >close<span className="lowercase">(esc)</span></button>
                </div>
        </div>
    );
}