import Datepicker from "react-tailwindcss-datepicker";

const DatePicker = ({ inputClassName, handleValueChange, value, asSingle=true, useRange=false, showShortcuts=false, startDate = new Date(), popoverDirection="down" }) => {
    return (
        <Datepicker
            primaryColor="primary"
            asSingle={asSingle}
            useRange={useRange}
            value={value}
            onChange={handleValueChange}
            displayFormat="DD-MM-YYYY"
            showFooter={false}
            showShortcuts={showShortcuts}
            startFrom={startDate}
            popoverDirection={popoverDirection} 
            inputClassName={`focus:ring-0 p-[10px] outline-none w-full ${inputClassName}`}
            containerClassName={'relative border border-gray-300 rounded-lg pl-[10px]'}
            toggleClassName="absolute bg-primary rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed" 
        />

    );
};
export default DatePicker;