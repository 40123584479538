export default function SalesExecutiveDetails({ executiveDetails }) {
    return (
        <div>

            <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Customers List</p>
                <table className="w-full">
                    <thead className="border bg-primary text-white">
                        <tr className="border">
                            <td className="p-[10px] w-[50px]">S.No</td>
                            <td className="p-[10px] w-[200px]">Customer ID</td>
                            <td className="p-[10px] w-[100px]">Customer Name</td>
                            <td className="p-[10px] w-[100px]">Shop Name</td>
                            <td className="p-[10px] w-[100px]">Location</td>
                            <td className="p-[10px] w-[100px]">Assigned by</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            executiveDetails?.customers?.map((item, index) => (
                                <tr className="border">
                                    <td className="p-[10px] w-[50px]">{index + 1}</td>
                                    <td className="p-[10px] w-[200px]">{item.customer_id}</td>
                                    <td className="p-[10px] w-[100px]">{item.customer_name}</td>
                                    <td className="p-[10px] w-[100px]">{item.shop_name}</td>
                                    <td className="p-[10px] w-[100px]">{item.city}</td>
                                    <td className="p-[10px] w-[100px]">{item.assigned_by}</td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>
        </div>
    );
}