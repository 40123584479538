import { useEffect } from "react";
import DatePicker from "../../../components/DatePicker";

export default function ParticularsListForm({ particulars = [], setParticulars }) {

    const onValueUpdate = (text, index) => {
        const { name, value } = text
        const data = [...particulars];
        if (name === 'recieved_quantity') {
            if (parseInt(value) >= 0) {
                data[index][name] = parseInt(value)
                data[index]['pending_items'] = parseInt(data[index]['quantity']) - parseInt(value)
            } else {
                data[index][name] = 0
            }
        } else if (name === 'mfg_date' || name === 'exp_date') {
            data[index][name] = value
        } else {
            data[index][name] = parseInt(value)
        }

        setParticulars(data)
    }

    const onCompleted = (text, index) => {
        const { name, checked } = text
        const data = [...particulars];
        data[index][name] = checked
        setParticulars(data)
    }

    return (
        particulars.map((item, index) => (
            <div className="p-[15px] shadow-lg border rounded-lg">
                <div className="flex gap-[15px] items-start ">
                    <div className="min-w-[90px] max-w-[90px] min-h-[90px] max-h-[90px] bg-gray-300 rounded-lg">
                        <img src={item.image} />
                    </div>
                    <div className="w-full">
                        <div className="min-[650px]:flex justify-between items-start">
                            <div>
                                <p className="text-[12px] sm:text-[15px] font-[600]">{item.product_name}</p>
                                <p className="text-[12px] sm:text-[15px] font-[400]">Product Code: <span className="text-primary font-[500]">{item.product_code}</span></p>
                                <p className="text-[12px] sm:text-[15px] font-[400]">Order Quantity: <span className="text-primary font-[500]">{item.quantity}</span></p>
                                <p className="text-[12px] sm:text-[15px] font-[400]">Recieved Quantity: <span className="text-primary font-[500]">{item.recieved_items}</span></p>
                            </div>
                            <div className="sm:flex gap-[15px]">
                                { item.is_completed ? <p className="text-[12px] sm:text-[15px] text-green-600">Completed</p> : <div className="flex items-center gap-[5px]">
                                    <input
                                        type="checkbox"
                                        className=""
                                        name="is_completed"
                                        id={"is_completed" + index}
                                        checked={item.is_completed}
                                        onChange={(e) => onCompleted(e.target, index)}
                                    />
                                    <label htmlFor={"is_completed" + index} className="text-[12px] sm:text-[15px]">Completed</label>
                                </div> }
                                <div className="border h-[full]"></div>
                                <div className="min-[340px]:flex items-center gap-[5px]">
                                    <p className="text-[12px] sm:text-[15px]">Shortage Quantity :  </p>
                                    <p className="text-primary font-[500] ">{item.shortage_quantity}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                { !item.is_completed && <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[15px] mt-[10px]">
                    <div className="">
                        <p className="mb-[5px] text-[12px]">Recieved Items</p>
                        <input
                            type="number"
                            placeholder="recieved items"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none"
                            name="recieved_quantity"
                            value={item.recieved_quantity}
                            onChange={(e) => onValueUpdate(e.target, index)}
                        />
                        {
                            (parseInt(item.recieved_quantity) + parseInt(item.recieved_items)) > item.quantity && <p className="text-red-600 text-[12px]">Quantity is greater than order qunatity</p>
                        }
                    </div>
                    <div className="">
                        <p className="mb-[5px] text-[12px]">Pending Items</p>
                        <input
                            type="number"
                            placeholder="pending items"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none"
                            name="pending_items"
                            disabled
                            value={(item.pending_items - item.recieved_items) <= 0 ? 0 : (item.pending_items - item.recieved_items)}
                            onChange={(e) => onValueUpdate(e.target, index)}
                        />
                    </div>
                    <div className="">
                        <p className="mb-[5px] text-[12px]">Batch No</p>
                        <input
                            type="text"
                            placeholder="batch no"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none "
                            name="batch_no"
                            value={item.batch_no}
                            onChange={(e) => onValueUpdate(e.target, index)}
                        />
                    </div>
                    <div className="">
                        <p className="mb-[5px] text-[12px]">Mfg Date</p>
                        <input
                            type="date"
                            placeholder="Mfg Date"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none "
                            name="mfg_date"
                            value={item.mfg_date}
                            onChange={(e) => onValueUpdate(e.target, index)}
                        />
                    </div>
                    <div className="">
                        <p className="mb-[5px] text-[12px]">Expiry Date</p>
                        <input
                            type="date"
                            placeholder="Expiry Date"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none "
                            name="exp_date"
                            value={item.exp_date}
                            onChange={(e) => onValueUpdate(e.target, index)}
                        />
                    </div>
                </div> }
            </div>
        ))

    );
}