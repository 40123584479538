import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

export const downloadImageAsPng = (id, name) => {
    htmlToImage.toPng(document.getElementById(id))
        .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = name + '.png';
            link.href = dataUrl;
            link.click();
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
}

export const downloadImageAsJpeg = (id, name) => {
    htmlToImage.toJpeg(document.getElementById(id))
        .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = name + '.jpeg';
            link.href = dataUrl;
            link.click();
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });

}