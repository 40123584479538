import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAxios } from "../../../service/apiservice";
import { userEndPoint } from "../../../service/api";
import { converttojson } from "../../../utilities/jsonvalidation";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function ViewUserDetails() {

    const navigate = useNavigate();

    const [currentUserDetails, setCurrentUserDetails] = useState({});
    const [encryptPasswordValue, setEncryptPasswordValue] = useState(true);

    useEffect(() => {
        getUserDetails()
    },[])

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            navigate(-1)
        }
    };


    useKeyPress(['Escape'], onKeyPress);

    const getUserDetails = async () => {
        var response = await getAxios({
            url: userEndPoint + localStorage.getItem('user_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                first_name: response.data?.staff_details?.first_name,
                last_name: response.data?.staff_details?.last_name,
                email: response.data?.user_details?.email,
                phone: response.data?.user_details?.phone,
                role: response.data?.user_details?.user_role_name,
                city: response.data?.staff_details?.city,
                state: response.data?.staff_details?.state,
                country: response.data?.staff_details?.country,
                address: response.data?.staff_details?.address,
                pincode: response.data?.staff_details?.pincode,
                aadhar_card: response.data?.staff_details?.aadhar_card,
                pan_card: response.data?.staff_details?.pan_card,
                location: response.data?.staff_details?.location_name,
                branch: response.data?.staff_details?.branch_name,
                department: response.data?.staff_details?.department_name,
                designation: response.data?.staff_details?.designation_name,
            }

            setCurrentUserDetails(tempDetails)
        }
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">User Details</p>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Staff Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">First Name</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.first_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Last Name</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.last_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Email</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.email}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Phone no</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.phone}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">User Role</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.role}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Location</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.location}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Branch</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.branch}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Department</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.department}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Designation</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.designation}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Address Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Address</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.address}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">Country</p>
                                <p className=" font-[400] text-[15px]">{converttojson(currentUserDetails.country)?.name}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">State</p>
                                <p className=" font-[400] text-[15px]">{converttojson(currentUserDetails.state)?.name}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px] font-[500]">city</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.city}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Pincode</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.pincode}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Other Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Pan no</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.pan_card}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px] font-[500]">Aadhar no</p>
                                <p className=" font-[400] text-[15px]">{currentUserDetails.aadhar_card}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); navigate(-1) }}
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                        >close<span className="lowercase">(esc)</span></button>
                    </div>
            </div>
        </>
    );
}