import { postAxiosWithToken } from "../../../service/apiservice";
import { stockByBatchCheckEndPoint } from "../../../service/api";

export default function ParticularsListForm({ particulars = [], setParticulars }) {

    const onValueUpdate = (text, index, subIndex) => {
        const { name, value } = text
        const data = [...particulars];
        if (name === 'recieved_quantity') {
            if (parseInt(value) >= 0) {
                data[index]['batch_list'][subIndex][name] = parseInt(value)
                data[index]['batch_list'][subIndex]['pending_items'] = parseInt(data[index]['quantity']) - parseInt(value)
            } else {
                data[index]['batch_list'][subIndex][name] = 0
            }
        } else if (name === 'mfg_date' || name === 'exp_date') {
            data[index]['batch_list'][subIndex][name] = value
        } else {
            data[index]['batch_list'][subIndex][name] = parseInt(value)
        }

        setParticulars(data)
    }

    const addBatch = (index) => {
        let dictData = {
            batch_no: '',
            mfg_date: '',
            exp_date: '',
            available: '',
            quantity: ''
        }

        let data = [...particulars]
        data[index]['batch_list'].push(dictData)
        setParticulars(data)
    }

    const deleteBatch = (index, subIndex) => {
        let data = [...particulars]
        if (data[index]['batch_list'].length > 0) {
            data[index]['batch_list'].splice(subIndex, 1);
        } else {
            data[index]['batch_list'] = []
        }
        setParticulars(data)
    }

    const checkStock = async (index, subIndex) => {
        let data = [...particulars]
        var item = data[index]['batch_list'][subIndex]
        var response = await postAxiosWithToken({
            url: stockByBatchCheckEndPoint,
            body: {
                batch_no: item.batch_no,
                mfg_date: item.mfg_date,
                exp_date: item.exp_date,
                product: data[index].id
            }
        })

        if (response !== null) {
            data[index]['batch_list'][subIndex]['available_quantity'] = response.data.available
            data[index]['batch_list'][subIndex]['purchase_order'] =  response.data.purchase_order
            data[index]['batch_list'][subIndex]['stock_id'] =  response.data.id
            setParticulars(data)
        }
    }
 
    return (
        <div>
            {
                particulars.map((item, index) => (
                    <div className="p-[15px] shadow-lg border rounded-lg">
                        <div className="flex gap-[15px] items-start ">
                            <div className="min-w-[90px] max-w-[90px] min-h-[90px] max-h-[90px] bg-gray-300 rounded-lg flex items-center">
                                <img src={item.image} />
                            </div>
                            <div className="w-full">
                                <div className="min-[650px]:flex justify-between items-start">
                                    <div>
                                        <p className="text-[12px] sm:text-[15px] font-[600]">{item.product_name}</p>
                                        <p className="text-[12px] sm:text-[15px] font-[400]">Product Code: <span className="text-primary font-[500]">{item.product_code}</span></p>
                                        <p className="text-[12px] sm:text-[15px] font-[400]">Order Quantity: <span className="text-primary font-[500]">{item.quantity}</span></p>
                                        <p className="text-[12px] sm:text-[15px] font-[400]">Sent Quantity: <span className="text-primary font-[500]">{item.recieved_items}</span></p>
                                    </div>
                                    <div>
                                        <button onClick={(e) => { e.preventDefault(); addBatch(index); }}>Add Batch</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {
                            item.batch_list && item.batch_list.map((item, subIndex) => (
                                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[15px] mt-[10px]">
                                    <div className="">
                                        <p className="mb-[5px] text-[12px]">Batch No</p>
                                        <input
                                            type="text"
                                            placeholder="batch no"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none "
                                            name="batch_no"
                                            value={item.batch_no}
                                            onChange={(e) => onValueUpdate(e.target, index, subIndex)}
                                        />
                                    </div>
                                    <div className="">
                                        <p className="mb-[5px] text-[12px]">Mfg Date</p>
                                        <input
                                            type="date"
                                            placeholder="Mfg Date"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none "
                                            name="mfg_date"
                                            value={item.mfg_date}
                                            onChange={(e) => onValueUpdate(e.target, index, subIndex)}
                                        />
                                    </div>
                                    <div className="">
                                        <p className="mb-[5px] text-[12px]">Expiry Date</p>
                                        <input
                                            type="date"
                                            placeholder="Expiry Date"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none "
                                            name="exp_date"
                                            value={item.exp_date}
                                            onChange={(e) => onValueUpdate(e.target, index, subIndex)}
                                        />
                                    </div>
                                    <div className="">
                                        <p className="mb-[5px] text-[12px]">Available Quantity</p>
                                        <input
                                            type="number"
                                            placeholder="available quantity"
                                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none"
                                            name="available_quantity"
                                            readOnly
                                            value={item.available_quantity}
                                        />
                                    </div>
                                    <div className="flex gap-4 items-center">
                                        <div className="">
                                            <p className="mb-[5px] text-[12px]">Quantity</p>
                                            <input
                                                type="number"
                                                placeholder="quantity"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full px-[10px] py-[5px] text-[17px] outline-none"
                                                name="sent_quantity"
                                                value={item.sent_quantity}
                                                onChange={(e) => onValueUpdate(e.target, index, subIndex)}
                                            />
                                            {parseInt(item.sent_quantity) > parseInt(item.available_quantity) && <p className="text-[12px] text-red-600">Given quantity is greater than available quantity</p>}
                                        </div>
                                        <div onClick={() => checkStock(index, subIndex)}>
                                            <p>Check</p>
                                        </div>
                                        <div onClick={() => { deleteBatch(index, subIndex) }} className="cursor-pointer hover:scale-125">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    );
}
