import { useEffect, useState } from "react";
import { deleteAxiosWithToken, postAxiosWithToken } from "../../../service/apiservice";
import { purchaseRequirementEndPoint, purchaseRequirementApprovalListEndPoint } from "../../../service/api";
import { datetimetodate } from "../../../utilities/datetime";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import Pagination from "../../../components/Pagination";
import { getStatusTypeDropdownData } from "../../../service/commonservice";
import { pendingStatus, purchaseReqModule } from "../../../constant";

export default function PurchaseRequirementsApproval() {


    const navigate = useNavigate();

    const [staffList, setRequiementsList] = useState([]);
    const [currentRequirement, setCurrentRequirement] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [search, setSearch] = useState("");
    const [searchSupplier, setSearchSupplier] = useState("");
    const [searchCreatedBy, setSearchCreatedBy] = useState("");
    const [orderStatus, setOrderStatus] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    const [menuHovered, setMenuHovered] = useState(null)
    const [positionMenu, setPositionMenu] = useState({
        x: 0,
        y: 0
    })

    const [statusDropDown, setStatusDropDown] = useState([]);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        let defaultBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: currentPage,
            items_per_page: perPage
        }
        getRequirementsListData(defaultBody)
        getStatusTypeDropdownData({
            module: purchaseReqModule,
            based_on: ""
        }, setStatusDropDown);
    }, [])

    const getRequirementsListData = async (body) => {
        var response = await postAxiosWithToken({
            url: purchaseRequirementApprovalListEndPoint,
            body: body
        })

        if (response !== null) {
            setRequiementsList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
    }

    const deleteRequirement = async () => {
        var response = await deleteAxiosWithToken({
            url: purchaseRequirementEndPoint + currentRequirement.id + '/'
        })

        if (response !== null) {
            getRequirementsListData()
            closeDeleteModal()
            toast.success(response.message)
        }
        
    }

    // Filter Functionalities

    const orderStatusHandler = async (e) => {
        let statusBody = {
            order_status: e.target.value === "" ? null : JSON.parse(e.target.value),
            search: search,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setOrderStatus(e.target.value === "" ? null : JSON.parse(e.target.value))
        setCurrentPage(1)
        getRequirementsListData(statusBody)
    }

    const searchHandler = async (e) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: e.target.value,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setSearch(e.target.value)
        setCurrentPage(1)
        getRequirementsListData(statusBody)
    }

    const searchSupplierHandler = async (e) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: e.target.value,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setSearchSupplier(e.target.value)
        setCurrentPage(1)
        getRequirementsListData(statusBody)
    }

    const searchCreatedByHandler = async (e) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: searchSupplier,
            created_by: e.target.value,
            page: 1,
            items_per_page: perPage
        }
        setSearchCreatedBy(e.target.value)
        setCurrentPage(1)
        getRequirementsListData(statusBody)
    }



    // Pagination

    const pageHandler = async (page) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: page,
            items_per_page: perPage
        }
        setCurrentPage(page)
        getRequirementsListData(statusBody)
    }

    const entriesHandler = async (count) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: count
        }
        setPerPage(count)
        setCurrentPage(1)
        getRequirementsListData(statusBody)
    }


    return (
        <>
            <div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr>
                                <td className="p-[10px] w-[75px]">Actions</td>
                                <td className="p-[10px] w-[175px]">Requirement Id</td>
                                <td className="p-[10px] w-[200px]">Supplier</td>
                                <td className="p-[10px] w-[150px]">Total Amount</td>
                                <td className="p-[10px] w-[100px]">Total Gst</td>
                                <td className="p-[10px] w-[150px]">Grand Total</td>
                                <td className="p-[10px] w-[150px]">Order Status</td>
                                <td className="p-[10px] w-[100px]">Created By</td>
                                <td className="p-[10px] w-[125px]">Created At</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border">
                                <td className="p-[10px]"></td>
                                <td className="p-[10px] min-w-[175px]">
                                    <input
                                        type="text"
                                        value={search}
                                        onChange={searchHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[200px]">
                                    <input
                                        type="text"
                                        value={searchSupplier}
                                        onChange={searchSupplierHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[100px]">
                                </td>
                                <td className="p-[10px] min-w-[100px]"></td>
                                <td className="p-[10px] min-w-[100px]">

                                </td>
                                <td className="p-[10px] min-w-[100px]">
                                    <select className="w-full border bg-white outline-none rounded-md py-[7px] px-[10px]" value={orderStatus} onChange={orderStatusHandler}>
                                        <option value={""}>All</option>
                                        {
                                            statusDropDown.map((item, index) => 
                                                <option value={item.id} key={item.status + index}>{item.status}</option>
                                            )
                                        }
                                    </select>
                                </td>

                                <td className="p-[10px] min-w-[150px]">
                                    <input
                                        type="text"
                                        value={searchCreatedBy}
                                        onChange={searchCreatedByHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[125px]"></td>
                            </tr>
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={9}>Loading...</td>
                                </tr> :
                                    staffList.length === 0 ? <tr><td className="text-center border" colSpan={9}>No Data</td></tr> :
                                        staffList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">
                                                <div className="flex gap-3">
                                                        {/* <div onClick={() => { setCurrentRequirement(item); localStorage.setItem('staff_form_mode', 'update'); localStorage.setItem('staff_form_id', item.id.toString()); navigate('staff-form') }} className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div> */}
                                                        <div onClick={() => { setCurrentRequirement(item); setIsDeleteOpen(true) }} className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="p-[10px] capitalize flex gap-5 items-center">
                                                    <p onClick={() => { localStorage.setItem('purchase_req_id', item.id); navigate('view-purchase-requirements-approval')}} className="text-sky-600 cursor-pointer">{item.requirement_id}</p>
                                                </td>
                                                <td className="p-[10px]">{item.supplier_name}</td>
                                                <td className="p-[10px] capitalize">{item.total_amount}</td>
                                                <td className="p-[10px] capitalize">{item.gst_amount}</td>
                                                <td className="p-[10px]">
                                                    {item.grand_total}
                                                </td>
                                                <td className={`${item.status !== pendingStatus ? "text-white": ""} px-[10px] capitalize`}>
                                                    <div style={{backgroundColor: item.status_color}} className="px-[15px] rounded-lg text-center py-[5px]">
                                                    {item.status_name}
                                                    </div>
                                                </td>
                                                <td className="p-[10px]">
                                                    {item.created_by_name}
                                                </td>
                                                <td className="p-[10px]">{datetimetodate(item.created_at, 3)}</td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className="mb-[50px]">
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={pageHandler}
                        entries={perPage}
                        setEntries={entriesHandler}
                    />
                </div>
            </div>
            <div>
                <Popup title={'Delete User Role'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                    <p className="font-400 text-[15px]">Do you want to delete the {currentRequirement?.requirement_id} purchase requirement?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteRequirement() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>


        </>
    );
}