import { useEffect, useState } from "react";
import { soPaymentEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { datetimetodate } from "../../../utilities/datetime";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { deniedStatus, paidStatus } from "../../../constant";
import SalesOrderDetails from "./salesorderdetails";
import AddPayment from "./addpayment";
import PaymentHistoryDetails from "./paymenthistorydetails";

export default function SoPaymentForm() {

    const navigate = useNavigate();

    const [paymentDetails, setPaymentDetails] = useState({});
    const [tab, setTab] = useState(1);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        } else if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('addpaymentsubmit').click()
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getPoPaymenttDetails()
    }, [])

    const getPoPaymenttDetails = async () => {
        var response = await getAxios({
            url: soPaymentEndPoint + localStorage.getItem('so_payment_id') + '/'
        })

        if (response != null) {
            setPaymentDetails(response.data)
        }
    }
    return (
        <>
            <div className="mb-[50px]">
                <div className="flex gap-[10px] border p-[5px] sm:p-[10px] mb-[25px] rounded-lg">
                    <div onClick={() => setTab(1)} className={`${tab === 1 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Sales Details</div>
                    {
                        (paymentDetails.payment_status != paidStatus) && <div onClick={() => setTab(2)} className={`${tab === 2 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Add Payment</div>
                    }
                    <div onClick={() => setTab(3)} className={`${tab === 3 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Payment History</div>
                </div>

                {
                    tab === 1 && <SalesOrderDetails salesDetails={paymentDetails} />
                }

                {
                    (tab === 2 && paymentDetails.payment_status != paidStatus) && <AddPayment salesDetails={paymentDetails} />
                }

                {
                    tab === 3 && <PaymentHistoryDetails paymentHistory={paymentDetails.payment_history} />
                }

                {
                    tab !== 2 &&
                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            onClick={(e) => { e.preventDefault(); navigate(-1) }}
                        >close<span className="lowercase">(esc)</span></button>
                    </div>
                }
            </div>
        </>
    );
}