import { canceledStatus, paidStatus } from "../../../constant";
import { datetimetodate } from "../../../utilities/datetime";
import { converttojson } from "../../../utilities/jsonvalidation";

export default function PurchaseOrderDetails({ purchaseDetails }) {
    return <div>
        <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
            <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Purchase Details</p>
            <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                <div className="sm:my-[5px] ">
                    <p className="mb-[5px] text-[15px] font-[600]">Purchase Id</p>
                    <div>{purchaseDetails?.purchase_order_id}</div>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px] font-[600]">Order Date</p>
                    <div>{purchaseDetails?.created_at && datetimetodate(purchaseDetails?.created_at)}</div>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px] font-[600]">Purchase Created By</p>
                    <div>{purchaseDetails?.created_by}</div>
                </div>
                <div className="my-[5px]" >
                    <p className="mb-[5px] text-[15px] font-[600]">Order Status</p>
                    <div style={{ color: purchaseDetails?.order_status_color }} className="capitalize w-fit">{purchaseDetails?.order_status_name}</div>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px] font-[600]">Payment Status</p>
                    <div style={{ color: purchaseDetails?.payment_status_color }} className="capitalize w-fit">{purchaseDetails?.payment_status_name}</div>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                    <div>{purchaseDetails?.with_gst ? 'With' : 'Without'}</div>
                </div>
            </div>
            <div className={`${purchaseDetails?.order_status === canceledStatus ? 'block' : 'hidden'} border-t mt-[10px] pt-[10px]`}>{purchaseDetails?.cancel_reason}</div>
        </div>
        <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
            <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
            <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                <div className="sm:my-[5px] ">
                    <p className="mb-[5px] text-[15px] font-[600]">Supplier</p>
                    <div>{purchaseDetails?.supplier_name}</div>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px] font-[600]">Supplier Id</p>
                    <div>{purchaseDetails?.supplier_id}</div>
                </div>
            </div>
        </div>
        <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
            <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Delivery Address Details</p>
            <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                <div className="sm:my-[5px] ">
                    <p className="mb-[5px] text-[15px] font-[600]">Address</p>
                    <div>{purchaseDetails?.address}</div>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px] font-[600]">Country</p>
                    <div>{converttojson(purchaseDetails?.country)?.name}</div>
                </div>
                <div className="sm:my-[5px] ">
                    <p className="mb-[5px] text-[15px] font-[600]">State</p>
                    <div>{converttojson(purchaseDetails?.state)?.name}</div>
                </div>
                <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px] font-[600]">City</p>
                    <div>{purchaseDetails?.city}</div>
                </div>
                <div className="sm:my-[5px] ">
                    <p className="mb-[5px] text-[15px] font-[600]">Pincode</p>
                    <div>{purchaseDetails?.pincode}</div>
                </div>
            </div>
        </div>
        <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
            <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Particular Details</p>
            <div className="w-full overflow-x-scroll">
                <table className="w-full">
                    <thead className="border bg-primary text-white">
                        <tr className="border">
                            <td className="p-[10px] w-[50px]">S.No</td>
                            <td className="p-[10px] w-[200px]">Product</td>
                            <td className="p-[10px] w-[100px]">Rate</td>
                            <td className="p-[10px] w-[100px]">Order Qty</td>
                            <td className="p-[10px] w-[100px]">Net. Weight</td>
                            <td className="p-[10px] w-[100px]">GST %</td>
                            <td className="p-[10px] w-[100px]">GST Total</td>
                            <td className="p-[10px] w-[100px]">Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            purchaseDetails?.particulars?.map((item, index) => (
                                <tr className="border">
                                    <td className="p-[10px] w-[50px]">{index + 1}</td>
                                    <td className="p-[10px] w-[200px]">{item.product_name}</td>
                                    <td className="p-[10px] w-[100px]">{item.purchase_price?.toFixed(2)}</td>
                                    <td className="p-[10px] w-[100px]">{item.quantity}</td>
                                    <td className="p-[10px] w-[100px]">{item.nt_weight} {item.measurement}</td>
                                    <td className="p-[10px] w-[100px]">{item.gst_percent?.toFixed(2)} %</td>
                                    <td className="p-[10px] w-[100px]">{item.gst_total?.toFixed(2)}</td>
                                    <td className="p-[10px] w-[100px]">{item.grand_total?.toFixed(2)}</td>

                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>
            {
                purchaseDetails?.particulars?.length > 0 && <div>
                    <div className="flex flex-col items-end mt-2">
                        <div className="flex w-full min-[339px]:w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                            <div className="text-[13px] sm:text-[18px]">Amount</div>
                            <div className="text-[13px] sm:text-[18px]">{purchaseDetails.total_amount?.toFixed(2)}</div>
                        </div>
                        <div className="flex w-full min-[339px]:w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                            <div className="text-[13px] sm:text-[18px]">Gst Amount</div>
                            <div className="text-[13px] sm:text-[18px]">{purchaseDetails.gst_amount?.toFixed(2)}</div>
                        </div>
                        <div className="flex w-full min-[339px]:w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                            <div className="text-[13px] sm:text-[18px]">PO Amount</div>
                            <div className="text-[13px] sm:text-[18px]">{purchaseDetails.grand_total?.toFixed(2)}</div>
                        </div>
                        <div className="flex w-full min-[339px]:w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                            <div className="text-[13px] sm:text-[18px]">Paid</div>
                            <div className="text-[13px] sm:text-[18px]">{purchaseDetails.paid_amount?.toFixed(2)}</div>
                        </div>
                        <div className="flex w-full min-[339px]:w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                            <div className="text-[13px] sm:text-[18px]">Shortage Quantity</div>
                            <div className="text-[13px] sm:text-[18px]">{purchaseDetails.shortage_quantity_amount?.toFixed(2)}</div>
                        </div>
                        {purchaseDetails.payment_status != paidStatus && <div className="flex w-full min-[339px]:w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                            <div className="text-[13px] sm:text-[18px]">Supplier Due</div>
                            <div className="text-[13px] sm:text-[18px]">{purchaseDetails.supplier_due?.toFixed(2)}</div>
                        </div>}
                        <div className="flex w-full min-[339px]:w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                            <div className="text-[13px] sm:text-[18px]">Payable</div>
                            <div className="text-[13px] sm:text-[18px]">{purchaseDetails.payable_amount?.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            }
        </div>


    </div>
}