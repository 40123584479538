import { useState } from "react";
import { paymentHistoryList } from "../../../constant";
import { datetimetodate } from "../../../utilities/datetime";

export default function PaymentHistoryDetails({ paymentHistory = [] }) {

    const [openedHistory, setOpenedHistory] = useState(null);

    return (
        <div>
            {
                paymentHistory.map((item, index) => (
                    <div onClick={() => {
                        if (index === openedHistory) {
                            setOpenedHistory(null)
                        } else {
                            setOpenedHistory(index)
                        }
                    }} className="border shadow-lg my-[15px] p-[15px] rounded-lg" key={'history' + index}>
                        <div className="flex w-full items-center justify-between ">
                            <div>
                                <p>Payment Method</p>
                                <p>{paymentHistoryList[item.payment_method - 1]}</p>
                            </div>
                            <div>
                                <p>Paid Amount</p>
                                <p>{item.paid_amount}</p>
                            </div>
                            <div>
                                <p>Status</p>
                                <p>{item.status}</p>
                            </div>
                            <div>
                                <p>Created At</p>
                                <p>{item.created_at && datetimetodate(item.created_at, 3)}</p>
                            </div>
                            <div>
                                <p>Created By</p>
                                <p>{item.created_by}</p>
                            </div>
                            <div>
                                view
                            </div>
                        </div>

                        <div className={`${openedHistory === index ? 'block' : 'hidden'} mt-[15px] pt-[15px] border-t`}>
                            {item.image && <div className="w-[300px] h-[300px]">
                                <img src={item.image} alt="no-image" />
                            </div>
                            }

                            {
                                Object.entries(item).map((item, subIndex) => (
                                    <div className="flex" key={"history"+index+subIndex}>
                                        <div>{item[0]}</div>
                                        <div>{item[1]}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    );
}