import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';


export default function Pagination({ totalPages = 1, currentPage = 1, setCurrentPage, entries, setEntries }) {
    return (
        <div className='min-[500px]:flex justify-between gap-5'>
            <div className='max-[499px]:mb-[15px] flex gap-3 items-center'>
                <p>Show Entries</p>
                <input type='number' value={entries} onChange={(e) => setEntries(e.target.value)} className='border rounded-md w-[100px] font-400 text-[15px] p-[5px] outline-primary bg-white' />
            </div>
            <ResponsivePagination
                total={totalPages}
                current={currentPage}
                onPageChange={page => setCurrentPage(page)}
                className='flex gap-[5px]'
                activeItemClassName='border border-primary text-primary'
                pageLinkClassName='w-full text-center'
                pageItemClassName='cursor-pointer bg-secondary text-black w-[35px] h-[35px] rounded-full flex items-center justify-center '
                disabledItemClassName='border-none text-white'
                nextClassName='text-primary'
                previousClassName='text-primary'
            />
        </div>
    );
}