import { createSlice } from '@reduxjs/toolkit'
 
const initialState = {
    isDark: false,
    menuOption: 'default'
}
 
export const themeConfigSlice = createSlice({
  name: 'themeConfig',
  initialState:initialState,
  reducers: {
    setIsDark: (state, action) => {
      localStorage.setItem('dark', action.payload)
        state.isDark = action.payload
    },
    setMenuOption: (state, action) => {
      localStorage.setItem('layout', action.payload)
      state.menuOption = action.payload
  }
  },
})
 
// Action creators are generated for each case reducer function
export const { setIsDark, setMenuOption } = themeConfigSlice.actions
 
export default themeConfigSlice.reducer