import { Fragment, useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { purchaseOrderEndPoint, purchaseOrderListEndPoint, purchaseOrderStatusEndPoint } from "../../../service/api";
import { datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import Pagination from "../../../components/Pagination";
import { getStatusTypeDropdownData } from "../../../service/commonservice";
import { canceledStatus, pendingStatus, purchaseOrderModule, sentToSupplierStatus } from "../../../constant";

export default function PurchaseOrders() {

    const navigate = useNavigate();

    const [purchaseList, setPurchaseList] = useState([]);
    const [currentPurchase, setCurrentPurchase] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [search, setSearch] = useState("");
    const [searchSupplier, setSearchSupplier] = useState("");
    const [searchCreatedBy, setSearchCreatedBy] = useState("");
    const [orderStatus, setOrderStatus] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    const [statusDropDown, setStatusDropDown] = useState([]);

    const [canceledPopup, setCanceledPopup] = useState(false);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    function closeCanceledModal() {
        setCanceledPopup(false)
    }

    useEffect(() => {
        let defaultBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: currentPage,
            items_per_page: perPage
        }
        getPurchaseListData(defaultBody)
        getStatusTypeDropdownData({
            module: purchaseOrderModule,
            based_on: ""
        }, setStatusDropDown);
    }, [])


    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            navigate('purchase-order-form');
        }
    };

    useKeyPress(['m'], onKeyPress);

    const getPurchaseListData = async (body) => {
        var response = await postAxiosWithToken({
            url: purchaseOrderListEndPoint,
            body: body
        })

        if (response !== null) {
            setPurchaseList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    const deletePurchase = async () => {
        var response = await deleteAxiosWithToken({
            url: purchaseOrderEndPoint + currentPurchase.id + '/'
        })

        if (response !== null) {
            getPurchaseListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    const updateOrderStatus = async (body, id) => {
        var response = await putAxiosWithToken({
            url: purchaseOrderStatusEndPoint + id + '/',
            body: body
        })

        if (response !== null) {
            getPurchaseListData()
            toast.success(response.message)
        }
    }

    const purchaseStatusHandler = (value, id) => {
        if (Number(value) === sentToSupplierStatus) {
            let statusBody = {
                status: sentToSupplierStatus
            }
            updateOrderStatus(statusBody, id)
        } else if (Number(value) === canceledStatus) {

            setCanceledPopup(!canceledPopup)
        }
    }

    // Filter Functionalities

    const orderStatusHandler = async (e) => {
        let statusBody = {
            order_status: e.target.value === "" ? null : JSON.parse(e.target.value),
            search: search,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setOrderStatus(e.target.value === "" ? null : JSON.parse(e.target.value))
        setCurrentPage(1)
        getPurchaseListData(statusBody)
    }

    const searchHandler = async (e) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: e.target.value,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setSearch(e.target.value)
        setCurrentPage(1)
        getPurchaseListData(statusBody)
    }

    const searchSupplierHandler = async (e) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: e.target.value,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setSearchSupplier(e.target.value)
        setCurrentPage(1)
        getPurchaseListData(statusBody)
    }

    const searchCreatedByHandler = async (e) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: searchSupplier,
            created_by: e.target.value,
            page: 1,
            items_per_page: perPage
        }
        setSearchCreatedBy(e.target.value)
        setCurrentPage(1)
        getPurchaseListData(statusBody)
    }

    // Pagination

    const pageHandler = async (page) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: page,
            items_per_page: perPage
        }
        setCurrentPage(page)
        getPurchaseListData(statusBody)
    }

    const entriesHandler = async (count) => {
        let statusBody = {
            order_status: orderStatus === "" ? null : JSON.parse(orderStatus),
            search: search,
            supplier: searchSupplier,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: count
        }
        setPerPage(count)
        setCurrentPage(1)
        getPurchaseListData(statusBody)
    }


    return (
        <>
            <div className="relative">
                <div className="flex justify-end">
                    <button
                        onClick={() => { localStorage.setItem('purchase_form_mode', 'create'); navigate('purchase-order-form') }}
                        className="w-[300px] mt-[20px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Create Purchase <span className="lowercase">(ctrl+m)</span></button>
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">

                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr>
                                <td className="p-[10px] min-w-[75px]">Actions</td>
                                <td className="p-[10px] min-w-[175px]">Purchase Id</td>
                                <td className="p-[10px] min-w-[200px]">Supplier</td>
                                <td className="p-[10px] min-w-[150px]">Total Amount</td>
                                <td className="p-[10px] min-w-[100px]">Total Gst</td>
                                <td className="p-[10px] min-w-[150px]">Grand Total</td>
                                <td className="p-[10px] min-w-[200px]">Order Status</td>
                                <td className="p-[10px] min-w-[100px]">Created By</td>
                                <td className="p-[10px] min-w-[125px]">Created At</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border">
                                <td className="p-[10px]"></td>
                                <td className="p-[10px] min-w-[175px]">
                                    <input
                                        type="text"
                                        value={search}
                                        onChange={searchHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[200px]">
                                    <input
                                        type="text"
                                        value={searchSupplier}
                                        onChange={searchSupplierHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[100px]">
                                </td>
                                <td className="p-[10px] min-w-[100px]"></td>
                                <td className="p-[10px] min-w-[100px]">

                                </td>
                                <td className="p-[10px] min-w-[150px]">
                                    <select className="w-full border bg-white outline-none rounded-md py-[7px] px-[10px]" value={orderStatus} onChange={orderStatusHandler}>
                                        <option value={""}>All</option>
                                        {
                                            statusDropDown.map((item, index) =>
                                                <option value={item.id} key={item.status + index}>{item.status}</option>
                                            )
                                        }
                                    </select>
                                </td>

                                <td className="p-[10px] min-w-[150px]">
                                    <input
                                        type="text"
                                        value={searchCreatedBy}
                                        onChange={searchCreatedByHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                {/* <td className="p-[10px] min-w-[125px]"></td> */}
                            </tr>
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={8}>Loading...</td>
                                </tr> :
                                    purchaseList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                        purchaseList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">
                                                    <div className="flex gap-3">
                                                        {/* <div onClick={() => { setCurrentPurchase(item); localStorage.setItem('staff_form_mode', 'update'); localStorage.setItem('staff_form_id', item.id.toString()); navigate('staff-form') }} className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div> */}
                                                        <div onClick={() => { setCurrentPurchase(item); setIsDeleteOpen(true) }} className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="p-[10px] capitalize flex gap-5 items-center">
                                                    <p onClick={() => { localStorage.setItem('purchase_id', item.id); navigate('view-purchase-order') }} className="text-sky-600 cursor-pointer">{item.purchase_order_id}</p>
                                                </td>
                                                <td className="p-[10px]">{item.supplier_name}</td>
                                                <td className="p-[10px] capitalize">{item.total_amount}</td>
                                                <td className="p-[10px] capitalize">{item.gst_amount}</td>
                                                <td className="p-[10px]">
                                                    {item.grand_total}
                                                </td>
                                                <td className={` px-[10px] capitalize`}>
                                                    {
                                                        item.order_status === pendingStatus ?
                                                            <StatusDropDwon item={item} setCurrentPurchase={setCurrentPurchase} purchaseStatusHandler={purchaseStatusHandler} />
                                                            : <div style={{ backgroundColor: item.status_color }} className={`w-full px-[15px] rounded-lg text-center py-[10px] text-[white]`}>{item.status_name}</div>
                                                    }
                                                </td>
                                                <td className="p-[10px]">
                                                    {item.created_by_name}
                                                </td>
                                                <td className="p-[10px]">{datetimetodate(item.created_at, 3)}</td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className="mb-[50px]">
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={pageHandler}
                        entries={perPage}
                        setEntries={entriesHandler}
                    />
                </div>
            </div>
            <div>
                <Popup title={'Delete User Role'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentPurchase?.purchase_order_id} purchase order?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deletePurchase() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>

            <Popup title={'Purchase Order Cancel'} isOpen={canceledPopup} closeModal={() => setCanceledPopup(!canceledPopup)}>
                <textarea rows={5} id="reason" className="w-full border outline-none rounded-lg my-[25px]">

                </textarea>
                <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                    <button
                        id="backnav"
                        className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                        onClick={(e) => { e.preventDefault(); setCanceledPopup(false) }}
                    >Close</button>
                    <button
                        onClick={(e) => { e.preventDefault(); updateOrderStatus({ status: canceledStatus, cancel_reason: document.getElementById('reason')?.value }, currentPurchase.id) }}
                        className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                    >Continue</button>
                </div>
            </Popup>

        </>
    );
}

export const StatusDropDwon = ({ item, setCurrentPurchase, purchaseStatusHandler }) => {
    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(item.order_status)

    useEffect(() => {
        getStatusTypeDropdownData({
            module: purchaseOrderModule,
            based_on: item.order_status
        }, setData)
    }, [])
    return (
        <select onChange={(e) => { setSelectedValue(item.id); setCurrentPurchase(item); purchaseStatusHandler(e.target.value, item.id) }} style={{ backgroundColor: item.status_color }} className="w-full px-[15px] rounded-lg text-center py-[10px] outline-none" value={selectedValue}>
            {data.map((item) => <option value={item.id} key={item.status + item.order_status} className="text-[15px] p-[10px]">
                {item.status}
            </option>)}
        </select>
    );
}