import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { approvalTypeListEndPoint, approvalRuleEndPoint, userListByRoleEndPoint, userRoleListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function ApprovalRuleForm({ getData, formMode, currentApprovalRule, setFormMode, setCurrentApprovalRule }) {

    const [approvalTypeDropdown, setApprovalTypeDropdown] = useState([]);
    const [userRoleDropdown, setUserRoleDropdown] = useState([]);
    const [approvalByDropdown, setApprovalByDropdown] = useState([]);

    const [selectedUser, setSelectedUser] = useState('');

    const schema = yup
        .object({
            approve_type: yup.string().required(),
            user_role: yup.string().required(),
            approve_by: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            approve_type: currentApprovalRule.approve_type,
            user_role: currentApprovalRule.user_role,
            approve_by: currentApprovalRule.approve_by
        } : {
            approve_type: "",
            user_role: "",
            approve_by: ""
        }
    })

    useEffect(() => {
        getApprovalTypeDropdownData()
        getUserRoleDropdownData()
    },[])

    useEffect(() => {
        if (formMode === 'update') {
            getUserDropdownData(currentApprovalRule.user_role)
            setSelectedUser(currentApprovalRule.approve_by)
        }
    },[currentApprovalRule])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const getApprovalTypeDropdownData = async () => {
        var response = await getAxios({
            url: approvalTypeListEndPoint
        })

        if (response !== null) {
            setApprovalTypeDropdown(response.data.list) 
        }
    }

    const getUserRoleDropdownData = async () => {
        var response = await getAxios({
            url: userRoleListEndPoint
        })

        if (response !== null) {
            setUserRoleDropdown(response.data.list) 
        }
    }

    const getUserDropdownData = async (value) => {
        var response = await getAxios({
            url: userListByRoleEndPoint + value + '/'
        })

        if (response !== null) {
            setApprovalByDropdown(response.data.list) 
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createBranch(data)
            } else {
                updateBranch(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createBranch = async (body) => {
        var response = await postAxiosWithToken({
            url: approvalRuleEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateBranch = async (body) => {
        var response = await putAxiosWithToken({
            url: approvalRuleEndPoint + currentApprovalRule?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentApprovalRule({
            approve_type: "",
            user_role: "",
            approve_by: ""
        })
        setSelectedUser("")
    }

    return (
        <>
            <div>
                <form className="grid sm:grid-cols-2 gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">Approval Type</p>
                        <select
                            placeholder="approval type"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="approve_type"
                            {...register('approve_type')}
                        >
                            <option value={""}>--select approve type--</option>
                            {
                                approvalTypeDropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.approve_type}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.approve_type?.message}</p>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">User Role</p>
                        <select
                            placeholder="user role"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="user_role"
                            {...register('user_role')}
                            onChange={(e) => getUserDropdownData(e.target.value)}
                        >
                            <option value={""}>--select user role--</option>
                            {
                                userRoleDropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.role_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.user_role?.message}</p>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">Approval by</p>
                        <select
                            placeholder="approve by"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="approve_by"
                            {...register('approve_by')}
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                        >
                            <option value={""}>--select approval by--</option>
                            {
                                approvalByDropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.email}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.approve_by?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >Save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}