import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "../layout";
import Login from "../auth/login";
import { activeUserPermissionEndPoint } from "../service/api";
import { getAxios } from "../service/apiservice";
import { AddedAuthenticatedRoutes, AuthenticatedComponents } from "./authenticatedroutes";
 
export default function Routers() {
 
    const [AuthenticatedRoutes, setAuthenticatedRoutes] = useState([]);
 
    useEffect(() => {

        if (JSON.parse(localStorage.getItem('authenticated'))) {
            getMenuList()
        }
        
    },[])

    const getMenuList = async () => {
        var response = await getAxios({
            url: activeUserPermissionEndPoint
        })

        if (response !== null) {
            var tempList = []
            for (var item of response.data) {
                for (var subItem of item.menu_list) {
                    for (var menuItem of AuthenticatedComponents) {
                        var lastPath = subItem.menu_path.split('/')[subItem.menu_path.split('/').length - 1]
                        if (lastPath.toString().toLowerCase() === menuItem.path.toString().toLowerCase()) {
                            var dictData = {
                                path: subItem.menu_path,
                                component: menuItem.component
                            }
                            tempList.push(dictData)
                            
                        }
                    }
                }
            }
            var mergedTempList = tempList.concat(AddedAuthenticatedRoutes)
            setAuthenticatedRoutes(mergedTempList)
        }
    }
 
    return (
 
        <>
            <Routes>
                <Route path="/" element={JSON.parse(localStorage.getItem('authenticated')) ? <Layout setAuthenticatedRoutes={setAuthenticatedRoutes}/> : <Login />}>
                    {
                        AuthenticatedRoutes.map((item) => (
                            <Route key={item.path} path={item.path} element={item.component} />
                        ))
                    }
                </Route>
 
                <Route path="*" element={JSON.parse(localStorage.getItem('authenticated')) ? <p>404</p> : <Navigate to={"/"} />} />
 
                <Route path="/" element={JSON.parse(localStorage.getItem('authenticated')) ? <Layout setAuthenticatedRoutes={setAuthenticatedRoutes} /> : <Login />} />
            </Routes>
        </>
 
    );
}