import { useCallback, useEffect, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { divisionEndPoint, divisionListEndPoint, divisionStatusEndPoint } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { datetimetodate } from "../../../utilities/datetime";
import DivisionForm from "./divisionform";
import { toast } from "react-hot-toast";
import Popup from "../../../components/Popup";
import Pagination from "../../../components/Pagination";
import Search from "../../../components/Search";

export default function Division() {


    const [divisionList, setDivisionList] = useState([]);
    const [currentDivision, setCurrentDivision] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);


    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }
    
    const getDivisionListData = useCallback( async () => {
        let defaultBody;

        if (formMode === 'create') {
            defaultBody = {
                active_status: status === "" ? null : JSON.parse(status),
                search: search,
                page: 1,
                items_per_page: perPage
            }  
            
        } else {
            defaultBody = {
                active_status: status === "" ? null : JSON.parse(status),
                search: search,
                page: currentPage,
                items_per_page: perPage
            }
        }
        var response = await postAxiosWithToken({
            url: divisionListEndPoint,
            body: defaultBody
        })
        
        if (response !== null) {
            setDivisionList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
        
    },[status, search, perPage, currentPage, formMode])
    
    useEffect(() => {
        getDivisionListData()
    }, [status, search, perPage])

    useEffect(() => {
        getDivisionListData()
    },[currentPage])

    const onStatusChange = async (id) => {
        var response = await getAxios({
            url: divisionStatusEndPoint + id + '/'
        })

        if (response !== null) {
            setFormMode('update')
            getDivisionListData()
            toast.success(response.message)
        }
    }

    const deleteDivision = async () => {
        var response = await deleteAxiosWithToken({
            url: divisionEndPoint + currentDivision.id + '/'
        })

        if (response !== null) {
            setFormMode('delete')
            getDivisionListData(2)
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    // Filter Functionalities

    const statusHandler = async (e) => {
        setFormMode('create')
        setCurrentPage(1)
        setStatus(e.target.value)
    }

    const searchHandler = async (e) => {
        setFormMode('create')
        setCurrentPage(1)
        setSearch(e.target.value)
    }

    // Pagination

    const pageHandler = async (page) => {
        setFormMode('page')
        setCurrentPage(page)
    }

    const entriesHandler = async (count) => {
        setFormMode('create')
        setCurrentPage(1)
        setPerPage(count)
    }

    return (
        <>
            <div>

                
                <div>
                    <DivisionForm getData={getDivisionListData} formMode={formMode} setFormMode={setFormMode} currentDivision={currentDivision} setCurrentDivision={setCurrentDivision} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px]">Division</td>
                                <td className="p-[10px]">Status</td>
                                <td className="p-[10px]">Created At</td>
                                <td className="p-[10px]">Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border">
                                <td className="p-[10px]"></td>
                                <td className="p-[10px]">
                                    <Search text={search} onChange={searchHandler} />
                                </td>
                                <td className="p-[10px]">
                                    <select className="w-full border bg-white outline-none rounded-md py-[7px] px-[10px]" value={status} onChange={statusHandler}>
                                        <option value={""}>All</option>
                                        <option value={true}>Active</option>
                                        <option value={false}>Inactive</option>
                                    </select>
                                </td>
                                <td className="p-[10px]"></td>
                                <td className="p-[10px]"></td>
                            </tr>
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={7}>Loading...</td>
                                </tr> :
                                    divisionList.length === 0 ? <tr><td className="text-center border" colSpan={7}>No Data</td></tr> :
                                        divisionList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px] capitalize">{item.division_name}</td>
                                                <td className="p-[10px]">
                                                    <div onClick={() => onStatusChange(item.id)}>
                                                        <SwitchButton enabled={item.is_active} />
                                                    </div>
                                                </td>
                                                <td className="p-[10px]">{datetimetodate(item.created_at)}</td>
                                                <td className="p-[10px]">
                                                    <div className="flex gap-3">
                                                        <div onClick={() => { setCurrentDivision(item); setFormMode('update'); document.getElementById('forminit').focus() }} className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div>
                                                        <div onClick={() => { setCurrentDivision(item); setIsDeleteOpen(true) }} className="cursor-pointer hover:scale-125">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className="mb-[50px]">
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={pageHandler}
                        entries={perPage}
                        setEntries={entriesHandler}
                    />
                </div>
            </div>
            <div>
                <Popup title={'Delete User Role'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentDivision?.division_name} division?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteDivision() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}