export const isValidForm = (formFields) => {
    for (var field of Object.values(formFields)) {
        if (field.length === 0) {
            return false
        }
    }
    return true
}

export const isOptionalValidForm = (formFields, notRequiredList = []) => {
    for (var field of Object.entries(formFields)) {
        if (field[1].length === 0 && !notRequiredList.includes(field[0])) {
            return false
        }
    }
    return true
}