import { customerEndPoint, gstVerifyEndPoint, ifscVerifyEndPoint, userListByRoleEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from 'country-state-city';
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import SwitchButton from "../../../components/Switch";
import { executiveRoleId, promotersRoleId } from "../../../constant";
import { isOptionalValidForm } from "../../../utilities/formvalidation";

const gstRequiredList = ["pan_no", "gst_no", "registered_name", "gst_status", "tax_payer_type", "bussiness_type"]
const accountRequiredList = ["acc_holder_name", "account_no", "ifsc_code", "bank_name", "branch_name", "micr_code"]

export default function CustomerForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');

    const [countryDropdown, setCountryDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [cityDropdown, setCityDropdown] = useState([]);

    const [stateShopDropdown, setStateShopDropdown] = useState([]);
    const [cityShopDropdown, setCityShopDropdown] = useState([]);

    const [executiveDropDown, setExecutiveDropDown] = useState([]);
    const [promoterDropDown, setPromoterDropDown] = useState([]);

    const [gstEnabled, setGstEnabled] = useState(false);
    const [bankEnabled, setBankEnabled] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [selectedShopCountry, setSelectedShopCountry] = useState('');
    const [selectedShopState, setSelectedShopState] = useState('');
    const [selectedShopCity, setSelectedShopCity] = useState('');

    const [currentCustomerErrors, setCurrentCustomerErrors] = useState({});

    const [currentCustomer, setCurrentCustomer] = useState({
        customer_name: "",
        email: "",
        phone: "",
        due_amount: "",
        outstanding_amount: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        shop_customer_name: "",
        shop_address: "",
        shop_country: "",
        shop_state: "",
        shop_city: "",
        shop_pincode: "",
        pan_no: "",
        gst_no: "",
        registered_name: "",
        gst_status: "",
        tax_payer_type: "",
        bussiness_type: "",
        acc_holder_name: "",
        account_no: "",
        ifsc_code: "",
        bank_name: "",
        branch_name: "",
        micr_code: "",
        executive: "",
        has_promoter: false,
        promoter: ""
    });

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };


    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {

        var countryTempList = []
        Country.getAllCountries().map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            countryTempList.push(dictData)
        })

        setCountryDropdown(countryTempList)

        setFormMode(localStorage.getItem('customer_form_mode'))

        if (localStorage.getItem('customer_form_mode') === 'update') {
            getCustomerDetails()
        }
    }, [])

    const getCustomerDetails = async () => {
        var response = await getAxios({
            url: customerEndPoint + localStorage.getItem('customer_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                customer_name: response.data?.customer_details?.customer_name,
                email: response.data?.customer_details?.email,
                mobile: response.data?.customer_details?.mobile,
                due_amount: response.data?.customer_details?.due_amount,
                outstanding_amount: response.data?.customer_details?.outstanding_amount,
                address: response.data?.customer_details?.address,
                country: response.data?.customer_details?.country,
                state: response.data?.customer_details?.state,
                city: response.data?.customer_details?.city,
                pincode: response.data?.customer_details?.pincode,
                shop_name: response.data?.customer_details?.shop_name,
                shop_address: response.data?.customer_details?.shop_address,
                shop_country: response.data?.customer_details?.shop_country,
                shop_state: response.data?.customer_details?.shop_state,
                shop_city: response.data?.customer_details?.shop_city,
                shop_pincode: response.data?.customer_details?.shop_pincode,
                pan_no: response.data?.customer_gst_details?.pan_no,
                gst_no: response.data?.customer_gst_details?.gst_no,
                registered_name: response.data?.customer_gst_details?.registered_name,
                gst_status: response.data?.customer_gst_details?.gst_status,
                tax_payer_type: response.data?.customer_gst_details?.tax_payer_type,
                bussiness_type: response.data?.customer_gst_details?.bussiness_type,
                acc_holder_name: response.data?.customer_bank_details?.acc_holder_name,
                account_no: response.data?.customer_bank_details?.account_no,
                ifsc_code: response.data?.customer_bank_details?.ifsc_code,
                bank_name: response.data?.customer_bank_details?.bank_name,
                branch_name: response.data?.customer_bank_details?.branch_name,
                micr_code: response.data?.customer_bank_details?.micr_code,
                executive: response.data?.customer_promoter_details?.executive,
                has_promoter: response.data?.customer_promoter_details?.has_promoter,
                promoter: response.data?.customer_promoter_details?.promoter,
            }

            setGstEnabled(true)
            setBankEnabled(true)

            if (response.data?.customer_details?.country !== undefined && response.data?.customer_details?.country !== null && response.data?.customer_details?.country.length !== 0) {
                setSelectedCountry(JSON.parse(response.data?.customer_details?.country))
                getStateDropdown(response.data?.customer_details?.country)
            }

            if (response.data?.customer_details?.state !== undefined && response.data?.customer_details?.state !== null && response.data?.customer_details?.state.length !== 0) {
                setSelectedState(JSON.parse(response.data?.customer_details?.state))
                setSelectedCity(response.data?.customer_details?.city)
            }

            if (response.data?.customer_details?.shop_country !== undefined && response.data?.customer_details?.shop_country !== null && response.data?.customer_details?.shop_country.length !== 0) {
                setSelectedShopCountry(JSON.parse(response.data?.customer_details?.shop_country))
                getStateShopDropdown(response.data?.customer_details?.shop_country)
            }

            if (response.data?.customer_details?.shop_state !== undefined && response.data?.customer_details?.shop_state !== null && response.data?.customer_details?.shop_state.length !== 0) {
                setSelectedShopState(JSON.parse(response.data?.customer_details?.shop_state))
                setSelectedShopCity(response.data?.customer_details?.shop_city)
            }

            setCurrentCustomer(tempDetails)
        }
    }

    useEffect(() => {
        getCityDropdown(JSON.stringify(selectedState))
        getExecutiveDropDown()
        getPromotersDropDown()
    }, [selectedState])

    useEffect(() => {
        if (selectedShopState !== {} && selectedShopState.length !== 0) {
            getCityShopDropdown(JSON.stringify(selectedShopState))
        }
    },[selectedShopState])

    const getStateDropdown = (value) => {
        var stateTempList = []
        State.getStatesOfCountry(JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            stateTempList.push(dictData)
        })
        setStateDropdown(stateTempList)
    }

    const getCityDropdown = useCallback((value) => {
        var cityTempList = []
        City.getCitiesOfState(selectedCountry.isoCode, JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name
            }
            cityTempList.push(dictData)
        })
        setCityDropdown(cityTempList)
    }, [selectedState]);

    const getStateShopDropdown = (value) => {
        var stateTempList = []
        State.getStatesOfCountry(JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            stateTempList.push(dictData)
        })
        setStateShopDropdown(stateTempList)
    }

    const getCityShopDropdown = useCallback((value) => {
        var cityTempList = []
        City.getCitiesOfState(selectedShopCountry.isoCode, JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name
            }
            cityTempList.push(dictData)
        })
        setCityShopDropdown(cityTempList)
    },[selectedShopState])

    const getExecutiveDropDown = async () => {
        var response = await getAxios({
            url: userListByRoleEndPoint + executiveRoleId + "/"
        })

        if (response !== null) {
            setExecutiveDropDown(response.data.list)
        }
    }

    const getPromotersDropDown = async () => {
        var response = await getAxios({
            url: userListByRoleEndPoint + promotersRoleId + "/"
        })

        if (response !== null) {
            setPromoterDropDown(response.data.list)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        let notRequiredList = []

        if (!currentCustomer.has_promoter) {
            notRequiredList.push('promoter')
        }

        if (!gstEnabled) {
            notRequiredList = notRequiredList + gstRequiredList
        }

        if (!bankEnabled) {
            notRequiredList = notRequiredList + accountRequiredList
        }

        

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0 && !notRequiredList.includes(field[0])) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setCurrentCustomerErrors(err)

        if (isOptionalValidForm(formDataObject, notRequiredList)) {
        if (localStorage.getItem('customer_form_mode') === 'create') {
            
            if (formDataObject['country'] !== undefined && formDataObject['country'] !== null && formDataObject['country'].length !== 0) {
                formDataObject['country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.country).name,
                    "isoCode": JSON.parse(formDataObject.country).isoCode
                })
            }
            if (formDataObject['state'] !== undefined && formDataObject['state'] !== null && formDataObject['state'].length !== 0) {
                formDataObject['state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.state).name,
                    "isoCode": JSON.parse(formDataObject.state).isoCode
                })
            }

            if (formDataObject['shop_country'] !== undefined && formDataObject['shop_country'] !== null && formDataObject['shop_country'].length !== 0) {
                formDataObject['shop_country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.shop_country).name,
                    "isoCode": JSON.parse(formDataObject.shop_country).isoCode
                })
            }
            if (formDataObject['shop_state'] !== undefined && formDataObject['shop_state'] !== null && formDataObject['shop_state'].length !== 0) {
                formDataObject['shop_state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.shop_state).name,
                    "isoCode": JSON.parse(formDataObject.shop_state).isoCode
                })
            }

            let createBody = {}
            createBody['gst_enabled'] = gstEnabled
            createBody['bank_enabled'] = bankEnabled
            createBody['customer_details'] = {
                customer_name: formDataObject.customer_name,
                email: formDataObject.email,
                mobile: formDataObject.mobile,
                address: formDataObject.address,
                country: formDataObject.country,
                state: formDataObject.state,
                city: formDataObject.city,
                pincode: formDataObject.pincode,
                shop_name: formDataObject.shop_name,
                shop_address: formDataObject.shop_address,
                shop_country: formDataObject.shop_country,
                shop_state: formDataObject.shop_state,
                shop_city: formDataObject.shop_city,
                shop_pincode: formDataObject.shop_pincode
            }
            createBody['customer_gst_details'] = gstEnabled ? {
                pan_no: formDataObject.pan_no,
                gst_no: formDataObject.gst_no,
                registered_name: formDataObject.registered_name,
                gst_status: formDataObject.gst_status,
                tax_payer_type: formDataObject.tax_payer_type,
                bussiness_type: formDataObject.bussiness_type
            } : {}

            createBody['customer_bank_details'] = bankEnabled ? {
                acc_holder_name: formDataObject.acc_holder_name,
                account_no: formDataObject.account_no,
                ifsc_code: formDataObject.ifsc_code,
                bank_name: formDataObject.bank_name,
                branch_name: formDataObject.branch_name,
                micr_code: formDataObject.micr_code
            } : {}

            createBody['customer_promoter_details'] = {
                executive: formDataObject.executive,
                has_promoter: currentCustomer.has_promoter,
                promoter: currentCustomer.has_promoter ? formDataObject.promoter : ""
            }

            createCustomer(createBody)

        } else {
            if (formDataObject['country'] !== undefined && formDataObject['country'] !== null && formDataObject['country'].length !== 0) {
                formDataObject['country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.country).name,
                    "isoCode": JSON.parse(formDataObject.country).isoCode
                })
            }
            if (formDataObject['state'] !== undefined && formDataObject['state'] !== null && formDataObject['state'].length !== 0) {
                formDataObject['state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.state).name,
                    "isoCode": JSON.parse(formDataObject.state).isoCode
                })
            }

            if (formDataObject['shop_country'] !== undefined && formDataObject['shop_country'] !== null && formDataObject['shop_country'].length !== 0) {
                formDataObject['shop_country'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.shop_country).name,
                    "isoCode": JSON.parse(formDataObject.shop_country).isoCode
                })
            }
            if (formDataObject['shop_state'] !== undefined && formDataObject['shop_state'] !== null && formDataObject['shop_state'].length !== 0) {
                formDataObject['shop_state'] = JSON.stringify({
                    "name": JSON.parse(formDataObject.shop_state).name,
                    "isoCode": JSON.parse(formDataObject.shop_state).isoCode
                })
            }

            let updateBody = {}
            updateBody['gst_enabled'] = gstEnabled
            updateBody['bank_enabled'] = bankEnabled
            updateBody['customer_details'] = {
                customer_name: formDataObject.customer_name,
                email: formDataObject.email,
                mobile: formDataObject.mobile,
                address: formDataObject.address,
                country: formDataObject.country,
                state: formDataObject.state,
                city: formDataObject.city,
                pincode: formDataObject.pincode,
                shop_name: formDataObject.shop_name,
                shop_address: formDataObject.shop_address,
                shop_country: formDataObject.shop_country,
                shop_state: formDataObject.shop_state,
                shop_city: formDataObject.shop_city,
                shop_pincode: formDataObject.shop_pincode
            }
            updateBody['customer_gst_details'] = gstEnabled ? {
                pan_no: formDataObject.pan_no,
                gst_no: formDataObject.gst_no,
                registered_name: formDataObject.registered_name,
                gst_status: formDataObject.gst_status,
                tax_payer_type: formDataObject.tax_payer_type,
                bussiness_type: formDataObject.bussiness_type
            } : {}

            updateBody['customer_bank_details'] = bankEnabled ? {
                acc_holder_name: formDataObject.acc_holder_name,
                account_no: formDataObject.account_no,
                ifsc_code: formDataObject.ifsc_code,
                bank_name: formDataObject.bank_name,
                branch_name: formDataObject.branch_name,
                micr_code: formDataObject.micr_code
            } : {}

            updateBody['customer_promoter_details'] = {
                executive: formDataObject.executive,
                has_promoter: currentCustomer.has_promoter,
                promoter: currentCustomer.has_promoter ? formDataObject.promoter : ""
            }

            updateCustomer(updateBody)
        }
        }
    }

    const createCustomer = async (body) => {
        var response = await postAxiosWithToken({
            url: customerEndPoint,
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/customers/customers')
        }
    }

    const updateCustomer = async (body) => {
        var response = await putAxiosWithToken({
            url: customerEndPoint + localStorage.getItem('customer_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            localStorage.removeItem('customer_form_id')
            localStorage.setItem('customer_form_mode', 'create')
            toast.success(response.message)
            navigate('/customers/customers')
        }
    }

    const clearData = () => {
        setCurrentCustomer({
            customer_name: "",
            email: "",
            phone: "",
            due_amount: "",
            outstanding_amount: "",
            address: "",
            country: "",
            state: "",
            city: "",
            pincode: "",
            shop_customer_name: "",
            shop_address: "",
            shop_country: "",
            shop_state: "",
            shop_city: "",
            shop_pincode: "",
            pan_no: "",
            gst_no: "",
            registered_name: "",
            gst_status: "",
            tax_payer_type: "",
            bussiness_type: "",
            acc_holder_name: "",
            account_no: "",
            ifsc_code: "",
            bank_name: "",
            branch_name: "",
            micr_code: "",
            executive: "",
            has_promoter: false,
            promoter: ""
        })
    }

    const getGstDetails = async (gstId) => {
        var response = await getAxios({
            url: gstVerifyEndPoint + gstId + "/",
        })

        if (response != null) {
            setCurrentCustomer((state) => ({
                ...state,
                ['registered_name']: response.data.registered_name,
                ['gst_status']: response.data.gst_status,
                ['tax_payer_type']: response.data.tax_payer_type,
                ['bussiness_type']: response.data.bussiness_type
            }))
            setCurrentCustomerErrors((state) => ({
                ...state,
                ['registered_name']: "",
                ['gst_status']: "",
                ['tax_payer_type']: "",
                ['bussiness_type']: ""
            }));
        }
    }

    const getBankDetails = async (ifsc) => {
        var response = await getAxios({
            url: ifscVerifyEndPoint + ifsc + "/",
        })

        if (response != null) {
            setCurrentCustomer({
                ...currentCustomer,
                ...{
                    bank_name: response.data.bank_name,
                    branch_name: response.data.branch_name,
                    micr_code: response.data.micr_code,
                }
            })
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target

        if (name === 'country') {
            getStateDropdown(value)
            setSelectedCountry(JSON.parse(value))
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'state') {
            getCityDropdown(value)
            setSelectedState(JSON.parse(value))
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'city') {
            setSelectedCity(value)
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'shop_country') {
            getStateShopDropdown(value)
            setSelectedShopCountry(JSON.parse(value))
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'shop_state') {
            getCityShopDropdown(value)
            setSelectedShopState(JSON.parse(value))
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'shop_city') {
            setSelectedShopCity(value)
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

        setCurrentCustomerErrors((prevState) => ({
            ...prevState,
            [name]: value.length === 0 ? `${name} field is required` : ''
        }));
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Supplier</p>
                <form onSubmit={onSubmit}>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Customer Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Customer Name</p>
                                <input
                                    type="text"
                                    placeholder="customer name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="customer_name"
                                    value={currentCustomer.customer_name}
                                    onChange={onValueUpdate}
                                    id="forminit"
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.customer_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Email</p>
                                <input
                                    type="text"
                                    placeholder="email"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="email"
                                    value={currentCustomer.email}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.email}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Phone no</p>
                                <input
                                    type="text"
                                    placeholder="Phone no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="mobile"
                                    value={currentCustomer.mobile}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.mobile}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Customer Due</p>
                                <input
                                    type="text"
                                    placeholder="Supplier Due"
                                    className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="due_amount"
                                    value={currentCustomer.due_amount}
                                    disabled
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.due_amount}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Customer Outstanding</p>
                                <input
                                    type="text"
                                    placeholder="supplier outstanding"
                                    className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="outstanding_amount"
                                    value={currentCustomer.outstanding_amount}
                                    disabled
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.outstanding_amount}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Address Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Address</p>
                                <input
                                    type="text"
                                    placeholder="address"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="address"
                                    value={currentCustomer.address}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.address}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Country</p>
                                <select
                                    placeholder="country"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="country"
                                    value={currentCustomer.country}
                                    onChange={onValueUpdate}

                                >
                                    <option value={""}>--select country--</option>
                                    {
                                        countryDropdown.map((option) => (
                                            <option className="capitalize" key={"country" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.country}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">State</p>
                                <select
                                    placeholder="state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="state"
                                    value={currentCustomer.state}
                                    onChange={onValueUpdate}
                                // onChange={(e) => { setSelectedState(JSON.parse(e.target.value)); getCityDropdown(e.target.value) }}
                                >
                                    <option value={""}>--select state--</option>
                                    {
                                        stateDropdown.map((option) => (
                                            <option className="capitalize" key={"state" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.state}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">City</p>
                                <select
                                    placeholder="city"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="city"
                                    value={selectedCity}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select city--</option>
                                    {
                                        cityDropdown.map((option) => (
                                            <option className="capitalize" key={"city" + option.name} value={option.name}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.city}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pincode</p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                    value={currentCustomer.pincode}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.pincode}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Shop Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Shop Name</p>
                                <input
                                    type="text"
                                    placeholder="shop name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="shop_name"
                                    value={currentCustomer.shop_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.shop_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Shop Address</p>
                                <input
                                    type="text"
                                    placeholder="shop address"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="shop_address"
                                    value={currentCustomer.shop_address}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.shop_address}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Shop Country</p>
                                <select
                                    placeholder="shop country"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="shop_country"
                                    value={currentCustomer.shop_country}
                                    onChange={onValueUpdate}

                                >
                                    <option value={""}>--select country--</option>
                                    {
                                        countryDropdown.map((option) => (
                                            <option className="capitalize" key={"shop_country" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.shop_country}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Shop State</p>
                                <select
                                    placeholder="shop state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="shop_state"
                                    value={currentCustomer.shop_state}
                                    onChange={onValueUpdate}
                                // onChange={(e) => { setSelectedState(JSON.parse(e.target.value)); getCityDropdown(e.target.value) }}
                                >
                                    <option value={""}>--select state--</option>
                                    {
                                        stateShopDropdown.map((option) => (
                                            <option className="capitalize" key={"shop_state" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.shop_state}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Shop City</p>
                                <select
                                    placeholder="shop city"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="shop_city"
                                    value={selectedShopCity}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select city--</option>
                                    {
                                        cityShopDropdown.map((option) => (
                                            <option className="capitalize" key={"shop_city" + option.name} value={option.name}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.shop_city}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Shop Pincode</p>
                                <input
                                    type="text"
                                    placeholder="Shpo pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="shop_pincode"
                                    value={currentCustomer.shop_pincode}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.shop_pincode}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2 flex justify-between">
                            <div>GST Details</div>
                            <div>
                                <SwitchButton enabled={gstEnabled} setEnabled={setGstEnabled} />
                            </div>
                        </p>
                        <div className={`${gstEnabled ? 'block' : 'hidden'} grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5`}>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pan Number</p>
                                <input
                                    type="text"
                                    placeholder="pan no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pan_no"
                                    value={currentCustomer.pan_no}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.pan_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST NO</p>
                                <div className="bg-white sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] flex justify-between items-center">
                                    <input
                                        type="text"
                                        placeholder="gst no"
                                        className=" outline-none w-full"
                                        name="gst_no"
                                        id="gst_no"
                                        value={currentCustomer.gst_no}
                                        onChange={onValueUpdate}
                                    />
                                    <button type="button" className="cursor-pointer text-primary" onClick={() => getGstDetails(document.getElementById('gst_no')?.value)}>verify</button>
                                </div>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.gst_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST Status</p>
                                <input
                                    type="text"
                                    placeholder="gst status"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="gst_status"
                                    value={currentCustomer.gst_status}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.gst_status}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">GST Type</p>
                                <input
                                    type="text"
                                    placeholder="gst type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="tax_payer_type"
                                    id="tax_payer_type"
                                    value={currentCustomer.tax_payer_type}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.tax_payer_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Bussiness Type</p>
                                <input
                                    type="text"
                                    placeholder="bussiness type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="bussiness_type"
                                    id="bussiness_type"
                                    value={currentCustomer.bussiness_type}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.bussiness_type}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Registered Name</p>
                                <input
                                    type="text"
                                    placeholder="registered name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="registered_name"
                                    id="registered_name"
                                    value={currentCustomer.registered_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.registered_name}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2 flex justify-between">
                            <div>Bank Details</div>
                            <div>
                                <SwitchButton enabled={bankEnabled} setEnabled={setBankEnabled} />
                            </div>
                        </p>
                        <div className={`${bankEnabled ? 'block' : 'hidden'} grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5`}>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Account Holder Name</p>
                                <input
                                    type="text"
                                    placeholder="account holder name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="acc_holder_name"
                                    value={currentCustomer.acc_holder_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.acc_holder_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Account No</p>
                                <input
                                    type="text"
                                    placeholder="account no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="account_no"
                                    value={currentCustomer.account_no}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.account_no}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">IFSC Code</p>
                                <div className={`bg-white sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] flex justify-between items-center`}>
                                    <input
                                        type="text"
                                        placeholder="ifsc code"
                                        className=" outline-none w-full"
                                        name="ifsc_code"
                                        id={"ifsccode"}
                                        value={currentCustomer.ifsc_code}
                                        onChange={onValueUpdate}
                                    />
                                    <button type="button" className="cursor-pointer text-primary" onClick={() => getBankDetails(document.getElementById("ifsccode")?.value)}>verify</button>
                                </div>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.ifsc_code}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Bank Name</p>
                                <input
                                    type="text"
                                    placeholder="bank name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="bank_name"
                                    value={currentCustomer.bank_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.bank_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Branch Name</p>
                                <input
                                    type="text"
                                    placeholder="branch name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="branch_name"
                                    value={currentCustomer.branch_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.branch_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">MICR No</p>
                                <input
                                    type="text"
                                    placeholder="micr no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="micr_code"
                                    value={currentCustomer.micr_code}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.micr_code}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Assigned Head Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Sales Executive</p>
                                <select
                                    placeholder="executive"
                                    className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="executive"
                                    value={currentCustomer.executive}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select executive--</option>
                                    {
                                        executiveDropDown.map((option) => (
                                            <option key={"executive" + option.email} value={option.id}>{option.email}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.executive}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Are Promoter?</p>
                                <div className="flex gap-5 items-center mt-4">
                                    <div onClick={() => setCurrentCustomer((prevState) => ({
                                        ...prevState,
                                        has_promoter: true
                                    }))} className="flex gap-3 items-center cursor-pointer">
                                        <div className="w-[20px] h-[20px] rounded-full border border-primary flex items-center justify-center">
                                            <div className={`${currentCustomer.has_promoter ? 'block' : 'hidden'} w-[12px] h-[12px] rounded-full bg-primary`}></div>
                                        </div>
                                        <div>Yes</div>
                                    </div>
                                    <div onClick={() => setCurrentCustomer((prevState) => ({
                                        ...prevState,
                                        has_promoter: false
                                    }))} className="flex gap-3 items-center cursor-pointer">
                                        <div className="w-[20px] h-[20px] rounded-full border border-primary flex items-center justify-center">
                                            <div className={`${!currentCustomer.has_promoter ? 'block' : 'hidden'} w-[12px] h-[12px] rounded-full bg-primary`}></div>
                                        </div>
                                        <div>No</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${currentCustomer.has_promoter ? 'block' : 'hidden'} "sm:my-[5px] "`}>
                                <p className="mb-[5px] text-[15px]">Promoter Name</p>
                                <select
                                    placeholder="promoter"
                                    className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="promoter"
                                    value={currentCustomer.promoter}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select promoter--</option>
                                    {
                                        promoterDropDown.map((option) => (
                                            <option key={"promoter" + option.email} value={option.id}>{option.email}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentCustomerErrors.promoter}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}