import { Outlet } from "react-router-dom";
import Footer from "./footer";
import { Toaster } from 'react-hot-toast';
import SideBar from "./sidebar";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "./breadcrumbs";
import Header from "./header";


export default function Layout() {
    const menuOption = useSelector((state) => state.themeConfig.menuOption)
    const isDark = useSelector((state) => state.themeConfig.isDark)

    const dispatch = useDispatch();

    return (
        <>
            <div className="flex w-full">
                <div className="fixed top-0 left-0 z-[5] hidden lg:block" id="menubar">
                    <SideBar isDark={isDark} classess={'h-[100vh] md:border-b border-l w-[275px] fixed top-0 left-0 z-[5] shadow-lg'} position={'left'} />
                </div>
                <div className="w-full lg:w-[calc(100vw-275px)] lg:ml-[275px]">
                    <div>
                        <Header />
                        <BreadCrumbs />
                    </div>
                    <div className="mx-[15px] my-[25px]">
                        <Outlet />
                    </div>
                    <div className={`${isDark ? "bg-dark-bg" : "bg-white"} fixed bottom-0 h-[35px] flex items-center shadow-inner border-t w-full lg:w-[calc(100vw-275px)]`}>
                        <Footer />
                    </div>
                </div>
            </div>


            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </>
    )
}