import { Fragment, useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { instockEndPoint, outStockStatusEndPoint, outstockListEndPoint, purchaseOrderStatusEndPoint } from "../../../service/api";
import { datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import Pagination from "../../../components/Pagination";
import { getStatusTypeDropdownData } from "../../../service/commonservice";
import { canceledStatus, deliveryEntryType, dispatchedStatus, invoiceEntryType, outstockModule, pendingStatus, purchaseOrderModule, sentToSupplierStatus, shippedStatus } from "../../../constant";
import { isOptionalValidForm, isValidForm } from "../../../utilities/formvalidation";

export default function OutStock() {

    const navigate = useNavigate();

    const [outStockList, setOutStockList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [search, setSearch] = useState("");
    const [searchCreatedBy, setSearchCreatedBy] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    const [currentOutStock, setCurrentOutStock] = useState({});

    const [transportPopup, setTransportPopup] = useState(false);

    const [selectedSentType, setSelectedSentType] = useState(1);
    
    const [errors, setErrors] = useState({});


    function closeCanceledModal() {
        setTransportPopup(false)
    }

    useEffect(() => {
        let defaultBody = {
            search: search,
            created_by: searchCreatedBy,
            page: currentPage,
            items_per_page: perPage
        }
        getOutstockListData(defaultBody)
    }, [])


    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            navigate('out-stock-form');
        }
    };

    useKeyPress(['m'], onKeyPress);

    const getOutstockListData = async (body) => {
        var response = await postAxiosWithToken({
            url: outstockListEndPoint,
            body: body
        })

        if (response !== null) {
            setOutStockList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    // Filter Functionalities

    const searchHandler = async (e) => {
        let statusBody = {
            search: e.target.value,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setSearch(e.target.value)
        setCurrentPage(1)
        getOutstockListData(statusBody)
    }

    const searchCreatedByHandler = async (e) => {
        let statusBody = {
            search: search,
            created_by: e.target.value,
            page: 1,
            items_per_page: perPage
        }
        setSearchCreatedBy(e.target.value)
        setCurrentPage(1)
        getOutstockListData(statusBody)
    }

    // Pagination

    const pageHandler = async (page) => {
        let statusBody = {
            search: search,
            created_by: searchCreatedBy,
            page: page,
            items_per_page: perPage
        }
        setCurrentPage(page)
        getOutstockListData(statusBody)
    }

    const entriesHandler = async (count) => {
        let statusBody = {
            search: search,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: count
        }
        setPerPage(count)
        setCurrentPage(1)
        getOutstockListData(statusBody)
    }

    const updateOrderStatus = async (body) => {
        var response = await putAxiosWithToken({
            url: outStockStatusEndPoint + currentOutStock.id + '/',
            body: body
        })

        if (response !== null) {
            getOutstockListData()
            toast.success(response.message)
        }
    }

    const purchaseStatusHandler = (value, id) => {
        if (Number(value) === shippedStatus) {
            setTransportPopup(!transportPopup)
        }
    }

    const handleStatusSubmit = (e) => {
        e.preventDefault();

        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors(err)

        let optionalFields = selectedSentType == 1 ? ['vehicle_no', 'driver_name'] : ['tracking_no']

        if (isOptionalValidForm(formDataObject, optionalFields)) {
            formDataObject['shipped_in'] = selectedSentType
            updateOrderStatus(formDataObject)
        }
    }


    return (
        <>
            <div className="relative">
                <div className="flex justify-end">
                    <button
                        onClick={() => { localStorage.setItem('instock_form_mode', 'create'); navigate('out-stock-form') }}
                        className="w-[300px] mt-[20px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Add Stock <span className="lowercase">(ctrl+m)</span></button>
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">

                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr>
                                <td className="p-[10px] min-w-[175px]">Sales Id</td>
                                <td className="p-[10px] min-w-[200px]">Customer ID</td>
                                <td className="p-[10px] min-w-[150px]">Customer Name</td>
                                <td className="p-[10px] min-w-[100px]">Ordered Date</td>
                                <td className="p-[10px] min-w-[100px]">Dispatch Date</td>
                                <td className="p-[10px] min-w-[125px]">Created by</td>
                                <td className="p-[10px] min-w-[125px]">Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border">
                                <td className="p-[10px] min-w-[175px]">
                                    <input
                                        type="text"
                                        value={search}
                                        onChange={searchHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[200px]"></td>
                                <td className="p-[10px] min-w-[100px]"></td>
                                <td className="p-[10px] min-w-[100px]"></td>
                                <td className="p-[10px] min-w-[125px]"></td>
                                <td className="p-[10px] min-w-[150px]">
                                    <input
                                        type="text"
                                        value={searchCreatedBy}
                                        onChange={searchCreatedByHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                            </tr>
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={7}>Loading...</td>
                                </tr> :
                                    outStockList.length === 0 ? <tr><td className="text-center border" colSpan={7}>No Data</td></tr> :
                                        outStockList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px] capitalize flex gap-5 items-center">
                                                    <p onClick={() => { localStorage.setItem('instock_id', item.id); navigate('view-out-stock-details') }} className="text-sky-600 cursor-pointer">{item.sales_order_id}</p>
                                                </td>
                                                <td className="p-[10px]">{item.customer_id}</td>
                                                <td className="p-[10px] capitalize">{item.customer_name}</td>
                                                <td className="p-[10px]">
                                                    {datetimetodate(item.ordered_date, 3)}
                                                </td>
                                                <td className="p-[10px]">{datetimetodate(item.created_at, 3)}</td>
                                                <td className="p-[10px]">
                                                    {item.created_by_name}
                                                </td>
                                                <td className={` px-[10px] capitalize`}>
                                                    {
                                                        item.order_status === dispatchedStatus ?
                                                            <StatusDropDwon item={item} setCurrentOutStock={setCurrentOutStock} purchaseStatusHandler={purchaseStatusHandler} />
                                                            : <div style={{ backgroundColor: item.status_color }} className={`w-full px-[15px] rounded-lg text-center py-[10px] text-[white]`}>{item.status_name}</div>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className="mb-[50px]">
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={pageHandler}
                        entries={perPage}
                        setEntries={entriesHandler}
                    />
                </div>
            </div>


            <Popup title={'Transport Items'} isOpen={transportPopup} closeModal={() => setTransportPopup(!transportPopup)}>
                <div className="sm:my-[5px] ">
                    <p className="mb-[5px] text-[15px]">Dispatch Mode</p>
                    <select
                        placeholder="dispatch mode"
                        className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                        name="shipped_in"
                        value={selectedSentType}
                        onChange={(e) => { setSelectedSentType(e.target.value) }}
                    >
                        <option value={1}>Courier</option>
                        <option value={2}>Transport</option>
                    </select>
                </div>
                <form onSubmit={handleStatusSubmit}>
                    { selectedSentType == 1 ? <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Courier Name</p>
                        <input
                            type="text"
                            placeholder="courier name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="courier_name"
                        />
                        <p className="text-red-600 text-[14px]">{errors.courier_name}</p>
                    </div> : <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Transport Name</p>
                        <input
                            type="text"
                            placeholder="transport name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="transport_name"
                        />
                        <p className="text-red-600 text-[14px]">{errors.transport_name}</p>
                    </div> }
                    <div className="grid grid-cols-2 gap-x-[15px]">
                    { selectedSentType == 1 ? <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Tracking No</p>
                            <input
                                type="text"
                                placeholder="tracking no"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="tracking_no"
                            />
                            <p className="text-red-600 text-[14px]">{errors.tracking_no}</p>
                        </div> : <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Vehicle No</p>
                            <input
                                type="text"
                                placeholder="vehicle no"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="vehicle_no"
                            />
                            <p className="text-red-600 text-[14px]">{errors.vehicle_no}</p>
                        </div> }
                        {
                            selectedSentType == 2 && <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Driver Name</p>
                            <input
                                type="text"
                                placeholder="driver name"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="driver_name"
                            />
                            <p className="text-red-600 text-[14px]">{errors.driver_name}</p>
                        </div>
                        }
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Mobile No</p>
                            <input
                                type="text"
                                placeholder="mobile no"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="mobile_no"
                            />
                            <p className="text-red-600 text-[14px]">{errors.mobile_no}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Delivery Date</p>
                            <input
                                type="date"
                                placeholder="delivery date"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="delivery_date"
                            />
                            <p className="text-red-600 text-[14px]">{errors.delivery_date}</p>
                        </div>
                    </div>
                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            onClick={(e) => { e.preventDefault(); setTransportPopup(false) }}
                        >Close</button>
                        <button
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >Continue</button>
                    </div>
                </form>
            </Popup>

        </>
    );
}

export const StatusDropDwon = ({ item, setCurrentOutStock, purchaseStatusHandler }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getStatusTypeDropdownData({
            module: outstockModule,
            based_on: item.order_status
        }, setData)
    }, [])

    return (
        <select
            onChange={(e) => {
                setCurrentOutStock(item);
                purchaseStatusHandler(e.target.value, item.id);
            }}
            style={{ backgroundColor: item.status_color }}
            className="w-full px-[15px] rounded-lg text-center py-[10px] outline-none"
            value={item.order_status}>
            {data.map((item) => <option value={item.id} key={item.status + item.order_status} className="text-[15px] p-[10px]">
                {item.status}
            </option>)}
        </select>
    );
}