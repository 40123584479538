import { useEffect, useState } from "react";
import { outstockEndPoint, salesOrderEndPoint, salesOrderListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { datetimetodate } from "../../../utilities/datetime";
import ParticularsListForm from "./particularlistform";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { converttojson } from "../../../utilities/jsonvalidation";

export default function OutStockForm() {

    const navigate = useNavigate();

    const [salesDropDown, setSalesDropDown] = useState([]);
    const [salesDetails, setSalesDetails] = useState();
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        getSalesDropdownData();
    }, [])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('outstockformsubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    const getSalesDropdownData = async () => {
        var response = await getAxios({
            url: salesOrderListEndPoint
        })

        if (response !== null) {
            setSalesDropDown(response.data.list)
        }
    }

    const getSalesOrderDetails = async (value) => {
        var response = await getAxios({
            url: salesOrderEndPoint + value.id + '/'
        })

        if (response != null) {
            setSalesDetails(response.data)

            let tempList = []
            for (var i of response.data.particulars) {
                let dictData = i
                dictData['batch_list'] = [{
                    batch_no: '',
                    mfg_date: '',
                    exp_date: '',
                    available: '',
                    quantity: ''
                }]

                tempList.push(dictData)
            }
            setProductList(tempList)
        }
    }

    const addStock = async () => {
        let createBody = {
            sales_order: salesDetails.id,
            particulars: []
        }

        for (var item of productList) {

            for (var batch of item.batch_list) {
                var dictData = {
                    sales_order: salesDetails.id,
                    batch_no: batch.batch_no,
                    mfg_date: batch.mfg_date,
                    exp_date: batch.exp_date,
                    product: item.id,
                    sent_quantity: batch.sent_quantity,
                    purchase_order: batch.purchase_order,
                    stock_id: batch.stock_id
                }
                createBody.particulars.push(dictData)
            }
            
        }

        var response = await postAxiosWithToken({
            url: outstockEndPoint,
            body: createBody
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    return (
        <>

            <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                <div className="w-full">
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">Sales Order Id</p>
                        <select
                            placeholder="sales id"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="sales"
                            onChange={(e) => getSalesOrderDetails(JSON.parse(e.target.value))}
                        >
                            <option value={""}>--select sales order--</option>
                            {
                                salesDropDown.map((option) => (
                                    <option className="capitalize" key={"sales" + option.sales_id} value={JSON.stringify(option)}>{option.sales_order_id}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>

            {
                salesDetails && <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Sales Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Sales Id</p>
                            <div>{salesDetails?.sales_order_id}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Order Date</p>
                            <div>{salesDetails?.created_at && datetimetodate(salesDetails?.created_at)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Sales Created By</p>
                            <div>{salesDetails?.created_by}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Order Status</p>
                            <div style={{ color: salesDetails?.order_status_color }} className="capitalize w-fit">{salesDetails?.order_status_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Required Date</p>
                            <div>{salesDetails?.order_req_date && datetimetodate(salesDetails?.order_req_date)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                            <div>{salesDetails?.with_gst ? 'With' : 'Without'}</div>
                        </div>
                    </div>
                </div>
            }
            {
                salesDetails && <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Customer Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Customer</p>
                            <div>{salesDetails?.customer_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Customer Id</p>
                            <div>{salesDetails?.customer_id}</div>
                        </div>
                    </div>
                </div>
            }
            {
                salesDetails && <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Delivery Address Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Address</p>
                            <div>{salesDetails?.address}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Country</p>
                            <div>{converttojson(salesDetails?.country)?.name}</div>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">State</p>
                            <div>{converttojson(salesDetails?.state)?.name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">City</p>
                            <div>{salesDetails?.city}</div>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Pincode</p>
                            <div>{salesDetails?.pincode}</div>
                        </div>
                    </div>
                </div>
            }

            <div className="mb-[50px]">
                <ParticularsListForm particulars={productList} setParticulars={setProductList} />
            </div>

            {
                salesDetails && <div className="min-[423px]:flex gap-4 mb-[50px]">

                    <button
                        id="backnav"
                        onClick={(e) => { e.preventDefault(); navigate(-1); }}
                        className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                    >Back<span className="lowercase">(ctrl+e)</span></button>

                    <button
                    onClick={addStock}
                        type="submit"
                        id="outstockformsubmit"
                        className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Submit<span className="lowercase">(ctrl+s)</span></button>
                </div>
            }


        </>
    );
}