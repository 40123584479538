import { Fragment, useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { instockEndPoint, instockListEndPoint } from "../../../service/api";
import { datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import Pagination from "../../../components/Pagination";
import { getStatusTypeDropdownData } from "../../../service/commonservice";
import { canceledStatus, deliveryEntryType, invoiceEntryType, pendingStatus, purchaseOrderModule, sentToSupplierStatus } from "../../../constant";

export default function InStock() {

    const navigate = useNavigate();

    const [inStockList, setInStockList] = useState([]);
    const [currentInStock, setCurrentInStock] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [search, setSearch] = useState("");
    const [entryNo, setEntryNo] = useState("");
    const [searchCreatedBy, setSearchCreatedBy] = useState("");
    const [entryType, setEntryType] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        let defaultBody = {
            entry_type: entryType === "" ? null : entryType,
            search: search,
            entry_no: entryNo,
            created_by: searchCreatedBy,
            page: currentPage,
            items_per_page: perPage
        }
        getInstockListData(defaultBody)
    }, [])


    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            navigate('in-stock-form');
        }
    };

    useKeyPress(['m'], onKeyPress);

    const getInstockListData = async (body) => {
        var response = await postAxiosWithToken({
            url: instockListEndPoint,
            body: body
        })

        if (response !== null) {
            setInStockList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    // Filter Functionalities

    const entryTypeHandler = async (e) => {
        let statusBody = {
            entry_type: e.target.value === "" ? null : e.target.value,
            search: search,
            supplier: entryNo,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setEntryType(e.target.value === "" ? null : e.target.value)
        setCurrentPage(1)
        getInstockListData(statusBody)
    }

    const searchHandler = async (e) => {
        let statusBody = {
            entry_type: entryType === "" ? null : entryType,
            search: e.target.value,
            supplier: entryNo,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setSearch(e.target.value)
        setCurrentPage(1)
        getInstockListData(statusBody)
    }

    const entryNoHandler = async (e) => {
        let statusBody = {
            entry_type: entryType === "" ? null : entryType,
            search: search,
            supplier: e.target.value,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setEntryNo(e.target.value)
        setCurrentPage(1)
        getInstockListData(statusBody)
    }

    const searchCreatedByHandler = async (e) => {
        let statusBody = {
            entry_type: entryType === "" ? null : entryType,
            search: search,
            supplier: entryNo,
            created_by: e.target.value,
            page: 1,
            items_per_page: perPage
        }
        setSearchCreatedBy(e.target.value)
        setCurrentPage(1)
        getInstockListData(statusBody)
    }

    // Pagination

    const pageHandler = async (page) => {
        let statusBody = {
            entry_type: entryType === "" ? null : entryType,
            search: search,
            supplier: entryNo,
            created_by: searchCreatedBy,
            page: page,
            items_per_page: perPage
        }
        setCurrentPage(page)
        getInstockListData(statusBody)
    }

    const entriesHandler = async (count) => {
        let statusBody = {
            entry_type: entryType === "" ? null : entryType,
            search: search,
            supplier: entryNo,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: count
        }
        setPerPage(count)
        setCurrentPage(1)
        getInstockListData(statusBody)
    }


    return (
        <>
            <div className="relative">
                <div className="flex justify-end">
                    <button
                        onClick={() => { localStorage.setItem('instock_form_mode', 'create'); navigate('in-stock-form') }}
                        className="w-[300px] mt-[20px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Add Stock <span className="lowercase">(ctrl+m)</span></button>
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">

                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr>
                                <td className="p-[10px] min-w-[175px]">Purchase Id</td>
                                <td className="p-[10px] min-w-[200px]">Entry No</td>
                                <td className="p-[10px] min-w-[150px]">Entry Type</td>
                                <td className="p-[10px] min-w-[100px]">Image</td>
                                <td className="p-[10px] min-w-[100px]">Created By</td>
                                <td className="p-[10px] min-w-[125px]">Created At</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border">
                                <td className="p-[10px] min-w-[175px]">
                                    <input
                                        type="text"
                                        value={search}
                                        onChange={searchHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[200px]">
                                    <input
                                        type="text"
                                        value={entryNo}
                                        onChange={entryNoHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[100px]">
                                <select className="w-full border bg-white outline-none rounded-md py-[7px] px-[10px]" value={entryType} onChange={entryTypeHandler}>
                                        <option value={""}>All</option>
                                        <option value={deliveryEntryType}>{deliveryEntryType}</option>
                                        <option value={invoiceEntryType}>{invoiceEntryType}</option>
                                    </select>
                                </td>
                                <td className="p-[10px] min-w-[100px]"></td>
                                <td className="p-[10px] min-w-[150px]">
                                    <input
                                        type="text"
                                        value={searchCreatedBy}
                                        onChange={searchCreatedByHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                {/* <td className="p-[10px] min-w-[125px]"></td> */}
                            </tr>
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={8}>Loading...</td>
                                </tr> :
                                    inStockList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                        inStockList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px] capitalize flex gap-5 items-center">
                                                    <p onClick={() => { localStorage.setItem('instock_id', item.id); navigate('view-in-stock-details') }} className="text-sky-600 cursor-pointer">{item.purchase_order_id}</p>
                                                </td>
                                                <td className="p-[10px]">{item.entry_no}</td>
                                                <td className="p-[10px] capitalize">{item.entry_type} no</td>
                                                <td className="p-[10px]">
                                                {item.image ? <a className="text-blue-600 underline" target="blank" href={item.image}>Open Invoice</a> : "-"}
                                                </td>
                                                <td className="p-[10px]">
                                                    {item.created_by_name}
                                                </td>
                                                <td className="p-[10px]">{datetimetodate(item.created_at, 3)}</td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className="mb-[50px]">
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={pageHandler}
                        entries={perPage}
                        setEntries={entriesHandler}
                    />
                </div>
            </div>

        </>
    );
}