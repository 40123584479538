import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { productListBySupplierEndPoint, purchaseRequirementEndPoint, supplierListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { City, Country, State } from "country-state-city";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { roundedCalulation } from "../../../utilities/amountcalculation";
import { isValidForm } from "../../../utilities/formvalidation";
import DatePicker from "../../../components/DatePicker";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";


export default function PurchaseRequirementsForm() {
    const navigate = useNavigate();

    const gstDropDowRef = useRef(null);

    const [formMode, setFormMode] = useState('create');
    const [currentRequirement, setCurrentRequirement] = useState({
        supplier: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        order_req_date: ""
    });

    const [currentRequirementErrors, setCurrentRequirementErrors] = useState({});

    const [supplierDropDown, setSupplierDropDown] = useState([]);
    const [productDropDown, setProductDropDown] = useState([]);
    const [countryDropdown, setCountryDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [cityDropdown, setCityDropdown] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [gstList, setGstList] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState({
        product: "",
        product_name: "",
        division_name: "",
        category_name: "",
        sub_category_name: "",
        brand_name: "",
        measurement: "",
        purchase_price: "",
        quantity: "",
        nt_weight: "",
        gst_value: [],
        gst_percent: 0,
        total_price: ""
    });
    const [selectedGstValue, setSelectedGstValue] = useState([]);
    const [selectedParticulars, setSelectedParticulars] = useState([]);

    const [requirementsCost, setRequirementCost] = useState({
        grandTotal: 0,
        gstTotal: 0,
        total: 0
    });

    const [orderReqDate, setOrderReqDate] = useState({
        startDate: null,
        endDate: null
    })
    const [datePopupOpen, setDatePopupOpen] = useState(false)
    const [addGst, setAddGst] = useState(true)

    const [apiBody, setApiBody] = useState({});

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('requirementformsubmit').click()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            clearData();
        } else if (event.key === 'Enter') {
            addProduct(event)
        } else if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['s', 'e', 'Enter', 'Escape'], onKeyPress);

    useEffect(() => {
        getSupplierDropdownData();

        var countryTempList = []
        Country.getAllCountries().map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            countryTempList.push(dictData)
        })

        setCountryDropdown(countryTempList)

        setFormMode(localStorage.getItem('purchase_req_form_mode'))

        // if (localStorage.getItem('staff_form_mode') === 'update') {
        //     getStaffDetails()
        // }
    }, [])

    useEffect(() => {
        if (selectedParticulars.length > 0) {
            for (var i of selectedParticulars) {
                setRequirementCost({
                    ...requirementsCost,
                    grandTotal: Number(requirementsCost.grandTotal) + Number(i.grand_total),
                    gstTotal: Number(requirementsCost.gstTotal) + Number(i.gst_total),
                    total: Number(requirementsCost.total) + Number(i.total_price),
                })
            }
        } else {
            setRequirementCost({
                grandTotal: 0.00,
                gstTotal: 0.00,
                total: 0.00
            })
        }
    }, [selectedParticulars])

    const getSupplierDropdownData = async () => {
        var response = await getAxios({
            url: supplierListEndPoint
        })

        if (response !== null) {
            setSupplierDropDown(response.data.list)
        }
    }

    const getStateDropdown = (value) => {
        var stateTempList = []
        State.getStatesOfCountry(JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            stateTempList.push(dictData)
        })
        setStateDropdown(stateTempList)
    }

    const getCityDropdown = useCallback((value) => {
        var cityTempList = []
        City.getCitiesOfState(selectedCountry.isoCode, JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name
            }
            cityTempList.push(dictData)
        })

        setCityDropdown(cityTempList)
    }, [selectedState, selectedCountry]);

    const getProductListDropDown = async (value) => {
        var response = await getAxios({
            url: productListBySupplierEndPoint + value?.id + '/'
        })

        if (response !== null) {
            setProductDropDown(response.data.list)
            setCurrentRequirement({ ...currentRequirement, supplier: value })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (localStorage.getItem('purchase_req_form_mode') === 'create') {

            let formFields = new FormData(e.target);
            let formDataObject = Object.fromEntries(formFields.entries());

            var err = {}

            for (var field of Object.entries(formDataObject)) {
                if (field[1].length === 0) {
                    err[field[0]] = `${field[0]} field is required`
                } else {
                    err[field[0]] = ""
                }
            }

            setCurrentRequirementErrors(err)

            if (isValidForm(formDataObject) && selectedParticulars.length > 0) {

                var items = []
                for (var i of selectedParticulars) {
                    var dictData = {
                        product: i.id,
                        quantity: i.quantity,
                        gst_values: i.gst_value
                    }
                    items.push(dictData)
                }
                let createBody = {
                    supplier: JSON.parse(formDataObject.supplier).id,
                    address: formDataObject.address,
                    country: formDataObject.country,
                    state: formDataObject.state,
                    city: formDataObject.city,
                    pincode: formDataObject.pincode,
                    particulars: items,
                    order_req_date: ""
                }
                setApiBody(createBody)
                setDatePopupOpen(!datePopupOpen)
            }
        } else {

        }
    }

    const addSeletedGstValue = (value) => {

        if (selectedGstValue.length < 2 && !selectedGstValue.includes(value)) {
            setSelectedGstValue([...selectedGstValue, value])
        } else {
            const data = [...selectedGstValue];
            data.splice(0, 1);
            data.push(value)
            setSelectedGstValue(data);
        }
    }

    const removeGstValue = (index) => {
        const data = [...selectedGstValue];
        data.splice(index, 1);
        setSelectedGstValue(data);
    }

    useEffect(() => {
        window.addEventListener('click', function handleClickOutsideBox(event) {
            const box = document.getElementById('gstinput');

            if (!box?.contains(event.target)) {
                if (gstDropDowRef.current) {

                    gstDropDowRef.current.className = 'hidden'
                }
            }
        });
    })

    const addProduct = (e) => {
        e.preventDefault();

        if (selectedProduct.product_name !== "" && selectedProduct.gst_value != []) {
            var data = selectedProduct
            data['gst_value'] = selectedGstValue

            setSelectedParticulars([...selectedParticulars, data]);
            clearData()
        }

    }

    const removeParticular = (index) => {
        const data = [...selectedParticulars];
        data.splice(index, 1);
        setSelectedParticulars(data);
    }

    const addQuantity = (value) => {
        var totalPercent = 0
        for (var i of Object.values(selectedGstValue)) {
            totalPercent = (Number(i.value) + Number(totalPercent)).toFixed(2)
        }
        var gstPrice = selectedProduct.purchase_price * totalPercent / 100

        var grandTotal = (selectedProduct?.purchase_price * value) + (value * gstPrice)
        setSelectedProduct({
            ...selectedProduct,
            quantity: value,
            gst_percent: totalPercent,
            gst_total: (value * gstPrice).toFixed(2),
            total_price: (selectedProduct?.purchase_price * value).toFixed(2),
            grand_total: grandTotal.toFixed(2)
        })
    }

    useEffect(() => {
        addGstValue()
    }, [selectedGstValue])

    const addGstValue = () => {
        var totalPercent = 0
        for (var i of Object.values(selectedGstValue)) {
            totalPercent = (Number(i.value) + Number(totalPercent)).toFixed(2)
        }

        var gstPrice = selectedProduct.purchase_price * totalPercent / 100
        var grandTotal = (selectedProduct?.purchase_price * selectedProduct?.quantity) + (selectedProduct?.quantity * gstPrice)
        setSelectedProduct({
            ...selectedProduct,
            gst_percent: totalPercent,
            gst_total: (selectedProduct?.quantity * gstPrice).toFixed(2),
            grand_total: grandTotal.toFixed(2)
        })
    }

    const clearData = () => {
        setSelectedProduct({
            product: "",
            product_name: "",
            division_name: "",
            category_name: "",
            sub_category_name: "",
            brand_name: "",
            measurement: "",
            purchase_price: "",
            quantity: "",
            nt_weight: "",
            gst_value: [],
            gst_percent: 0,
            total_price: ""
        })
        setSelectedGstValue([])
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target

        if (name === 'country') {
            getStateDropdown(value);
            setSelectedCountry(JSON.parse(e.target.value));
            setCurrentRequirement((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (name === 'state') {
            setSelectedState(JSON.parse(e.target.value));
            setCurrentRequirement((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            getCityDropdown(value)
        } else if (name === 'city') {
            setSelectedCity(value)
            setCurrentRequirement((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setCurrentRequirement((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

        setCurrentRequirementErrors((prevState) => ({
            ...prevState,
            [name]: value.length === 0 ? `${name} field is required` : ''
        }));
    }

    const changeGstValue = (value) => {
        setAddGst(value)
    }

    const createRequirement = async () => {
        let body = apiBody
        body['order_req_date'] = orderReqDate?.startDate
        body['with_gst'] = addGst
        console.log(body)

        var response = await postAxiosWithToken({
            url: purchaseRequirementEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message);
            navigate('/purchase/purchase-requirements')
        }
    }

    return (
        <>
            <div className="mb-[50px]  pb-[70px] relative">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Purchase Requirement</p>
                <form onSubmit={onSubmit}>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Supplier</p>
                                <select
                                    placeholder="supplier"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="supplier"
                                    onChange={(e) => getProductListDropDown(JSON.parse(e.target.value))}
                                    value={JSON.stringify(currentRequirement.supplier)}
                                >
                                    <option value={""}>--select supplier--</option>
                                    {
                                        supplierDropDown.map((option) => (
                                            <option className="capitalize" key={"supplier" + option.supplier_name} value={JSON.stringify(option)}>{option.supplier_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.supplier}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Supplier Id</p>
                                <input
                                    type="text"
                                    placeholder="supplier id"
                                    className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="supplier_id"
                                    value={currentRequirement?.supplier?.supplier_id}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Delivery Address Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Address</p>
                                <input
                                    type="text"
                                    placeholder="address"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="address"
                                    value={currentRequirement.address}
                                    onChange={(e) => onValueUpdate(e)}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.address}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Country</p>
                                <select
                                    placeholder="country"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="country"
                                    onChange={(e) => onValueUpdate(e)}
                                // onChange={(e) => { setSelectedCountry(JSON.parse(e.target.value)); getStateDropdown(e.target.value) }}
                                >
                                    <option value={""}>--select country--</option>
                                    {
                                        countryDropdown.map((option) => (
                                            <option className="capitalize" key={"country" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.country}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">State</p>
                                <select
                                    placeholder="state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="state"
                                    onChange={(e) => onValueUpdate(e)}
                                // onChange={(e) => { setSelectedState(JSON.parse(e.target.value)); getCityDropdown(e.target.value) }}
                                >
                                    <option value={""}>--select state--</option>
                                    {
                                        stateDropdown.map((option) => (
                                            <option className="capitalize" key={"state" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.state}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">City</p>
                                <select
                                    placeholder="city"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="city"
                                    value={selectedCity}
                                    onChange={(e) => onValueUpdate(e)}
                                >
                                    <option value={""}>--select city--</option>
                                    {
                                        cityDropdown.map((option) => (
                                            <option className="capitalize" key={"city" + option.name} value={option.name}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.city}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pincode</p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{currentRequirementErrors.pincode}</p>
                            </div>
                        </div>
                    </div>

                    <div className="absolute bottom-0 right-0 flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            onClick={(e) => { e.preventDefault(); clearData(); navigate(-1) }}
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="requirementformsubmit"
                            className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>

                <form onSubmit={addProduct}>
                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Add Particulars</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Product</p>
                                <select
                                    placeholder="product"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="product"
                                    onChange={(e) => {
                                        var tempData = JSON.parse(e.target.value)
                                        setSelectedProduct({ ...tempData, quantity: 1, gst_percent: 0, total_price: tempData.purchase_price });

                                        let tempGstList = []
                                        for (var i of Object.entries(tempData.gst_value)) {
                                            var tempDict = {}
                                            tempDict['key'] = i[0]
                                            tempDict['value'] = i[1]
                                            tempGstList.push(tempDict)
                                        }
                                        setGstList(tempGstList);
                                    }}
                                    value={selectedProduct.product}
                                >
                                    <option value={""}>--select product--</option>
                                    {
                                        productDropDown.map((option) => (
                                            <option className="capitalize" key={"product" + option.product_name} value={JSON.stringify(option)}>{option.product_name}</option>
                                        ))
                                    }
                                </select>
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.product?.message}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Division</p>
                                <input
                                    type="text"
                                    placeholder="division"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="division"
                                    value={selectedProduct?.division_name}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.division?.message}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Category</p>
                                <input
                                    type="text"
                                    placeholder="category"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="category"
                                    value={selectedProduct?.category_name}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.category?.message}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Sub Category</p>
                                <input
                                    type="text"
                                    placeholder="sub category"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="sub_category"
                                    value={selectedProduct?.sub_category_name}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.sub_category?.message}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Brand</p>
                                <input
                                    type="text"
                                    placeholder="brand"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="brand"
                                    value={selectedProduct?.brand_name}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.brand?.message}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Unit</p>
                                <input
                                    type="text"
                                    placeholder="unit"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="measurement"
                                    value={selectedProduct?.measurement}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.unit?.message}</p> */}
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Price</p>
                                <input
                                    type="text"
                                    placeholder="price"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="price"
                                    value={selectedProduct?.purchase_price}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.price?.message}</p> */}
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Quantity</p>
                                <input
                                    type="number"
                                    placeholder="quantity"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="quantity"
                                    value={selectedProduct?.quantity}
                                    onChange={(e) => { addQuantity(e.target.value); }}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.quantity?.message}</p> */}
                            </div>

                            <div className="my-[5px] relative">
                                <p className="mb-[5px] text-[15px]">Gst</p>
                                <div id="gstinput">
                                    <div onClick={() => {
                                        if (document.getElementById('gstdropdown').className === 'hidden') {
                                            document.getElementById('gstdropdown').className = 'block absolute top-[85px] bg-white shadow-xl border border-gray-300 w-full rounded-lg overflow-hidden'
                                        } else {
                                            document.getElementById('gstdropdown').className = 'hidden'
                                        }
                                    }} className="sm:mb-[5px] border flex items-center border-gray-300 rounded-lg w-full h-[48px] px-[5px] text-[17px] outline-none">
                                        {
                                            selectedGstValue.length > 0 ? selectedGstValue.map((item, index) => (
                                                <div onClick={() => removeGstValue(index)} className="cursor-pointer p-[5px] bg-sky-200 mx-1 my-2 p-1 px-3 rounded-md hover:bg-red-600 hover:text-white">{item.key} {item.value} %</div>
                                            )) : <div className="flex items-center text-gray-400 ml-2">gst</div>
                                        }
                                    </div>
                                    <div id="gstdropdown" ref={gstDropDowRef} className="hidden ">
                                        {
                                            gstList.map((item) => (
                                                <div onClick={() => { addSeletedGstValue(item) }} className={`${selectedGstValue.includes(item) ? 'bg-sky-200' : ''} cursor-pointer p-[10px] hover:bg-sky-200`}>{item.key} {item.value} %</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.quantity?.message}</p> */}
                            </div>



                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Total Price</p>
                                <input
                                    type="text"
                                    placeholder="total price"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="total_price"
                                    value={selectedProduct?.total_price}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.total_price?.message}</p> */}
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Total Gst Price</p>
                                <input
                                    type="text"
                                    placeholder="total gst price"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="gst_total"
                                    value={selectedProduct?.gst_total}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.gst_total?.message}</p> */}
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Grand Total</p>
                                <input
                                    type="text"
                                    placeholder="grand total"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="grand_total"
                                    value={selectedProduct?.grand_total}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.grand_total?.message}</p> */}
                            </div>

                        </div>
                        {selectedGstValue.length > 0 && <div className="min-[423px]:flex gap-4 mt-0 sm:mt-[35px]">

                            <button
                                onClick={(e) => { e.preventDefault(); clearData(); }}
                                className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                            >Reset<span className="lowercase">(ctrl+e)</span></button>

                            <button
                                type="submit"
                                className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                            >Add<span className="lowercase">(enter)</span></button>
                        </div>}
                    </div>
                </form>

                <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Particulars</p>
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr className="border">
                                <td className="p-[10px] w-[50px] text-center">Actions</td>
                                <td className="p-[10px] w-[50px]">S.No</td>
                                <td className="p-[10px] w-[200px]">Product</td>
                                <td className="p-[10px] w-[100px]">Rate</td>
                                <td className="p-[10px] w-[100px]">Order Qty</td>
                                <td className="p-[10px] w-[100px]">Net. Weight</td>
                                <td className="p-[10px] w-[100px]">GST %</td>
                                <td className="p-[10px] w-[100px]">GST Total</td>
                                <td className="p-[10px] w-[100px]">Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedParticulars.map((item, index) => (
                                    <tr className="border">
                                        <td className="p-[10px] w-[50px] ">
                                            <div onClick={() => removeParticular(index)} className="cursor-pointer hover:scale-125 w-fit mx-auto">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                            </div>
                                        </td>
                                        <td className="p-[10px] w-[50px]">{index + 1}</td>
                                        <td className="p-[10px] w-[200px]">{item.product_name}</td>
                                        <td className="p-[10px] w-[100px]">{item.purchase_price}</td>
                                        <td className="p-[10px] w-[100px]">{item.quantity}</td>
                                        <td className="p-[10px] w-[100px]">{item.nt_weight} {item.measurement}</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_percent} %</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_total}</td>
                                        <td className="p-[10px] w-[100px]">{item.grand_total}</td>

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {
                        selectedParticulars.length > 0 && <div>
                            <div className="flex flex-col items-end ">
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Amount</div>
                                    <div>{requirementsCost.total}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Gst Amount</div>
                                    <div>{requirementsCost.gstTotal}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Grand Total</div>
                                    <div>{requirementsCost.grandTotal}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>


                <Popup isOpen={datePopupOpen} closeModal={() => setDatePopupOpen(!datePopupOpen)} title={'Purchase Requirement'}>
                    <div className="my-[15px]">
                        <DatePicker value={orderReqDate} handleValueChange={(value) => setOrderReqDate(value)} />
                    </div>
                    <div className="mb-2">GST</div>
                    <div className="flex gap-5 mb-5">
                        <div className="flex gap-1 items-center">
                            <div onClick={() => changeGstValue(true)} className="w-[18px] h-[18px] border rounded-full border-gray-300 flex justify-center items-center">
                                <div className={`${addGst ? 'block' : 'hidden'} w-[11px] h-[11px] rounded-full bg-primary`}></div>
                            </div>
                            <div>With GST</div>
                        </div>
                        <div className="flex gap-1 items-center">
                            <div onClick={() => changeGstValue(false)} className="w-[18px] h-[18px] border rounded-full border-gray-300 flex justify-center items-center">
                                <div className={`${!addGst ? 'block' : 'hidden'} w-[11px] h-[11px] rounded-full bg-primary`}></div>
                            </div>
                            <div>Without GST</div>
                        </div>
                    </div>

                    {
                        selectedParticulars.length > 0 && <div className="my-[25px] border p-[7px]">
                            <div className="flex flex-col items-end ">
                                <div className="flex w-full justify-between items-center p-[5px]">
                                    <div>Amount</div>
                                    <div>{requirementsCost.total}</div>
                                </div>
                                <div className="flex w-full justify-between items-center p-[5px]">
                                    <div>Gst Amount</div>
                                    <div>{addGst ? requirementsCost.gstTotal : '-'}</div>
                                </div>
                                <div className="flex w-full justify-between items-center p-[5px]">
                                    <div>Grand Total</div>
                                    <div>{addGst ? requirementsCost.grandTotal : requirementsCost.total}</div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="flex gap-[15px] items-center">
                        <button
                            onClick={(e) => { e.preventDefault(); setDatePopupOpen(!datePopupOpen); }}
                            className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Close</button>

                        <button
                        onClick={(e) => {e.preventDefault(); createRequirement()}}
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >Send</button>
                    </div>
                </Popup>

            </div>
        </>
    );
}