import { useEffect, useState } from "react";
import { getAxios, postAxios, putAxiosWithToken } from "../../../service/apiservice";
import { s3ImageUploadEndPoint, soPaymentEndPoint, utrTypeListEndPoint } from "../../../service/api";
import { useNavigate } from "react-router-dom";
import { accountTransferMethod, cashMethod, chequeMethod, upiMethod, utrMethod } from "../../../constant";
import DatePicker from "../../../components/DatePicker";
import { toast } from "react-hot-toast";

export default function AddPayment({ salesDetails }) {

    const navigate = useNavigate();

    const [paymentMethod, setPaymentMethod] = useState(utrMethod);
    const [imageData, setimageData] = useState();
    const [errors, setErrors] = useState({})

    const [paymentAmountDetails, setPaymentAmountDetails] = useState(false);

    const [transferTypeDropdown, setTransferTypeDropDown] = useState([]);

    const [cashValue, setCashValue] = useState({
        fivehundred: 0,
        twohundred: 0,
        hundred: 0,
        fifty: 0,
        twenty: 0,
        ten: 0,
        five: 0,
        two: 0,
        one: 0
    });

    const [utrValue, setUtrValue] = useState({
        transfer_type: "",
        account_no: "",
        ifsc_no: "",
        transaction_date: {
            startDate: new Date(),
            endDate: null
        },
        transaction_desc: "",
        transaction_id: "",
        image: null,
        paid_amount: ""
    })

    const [upiValue, setUpiValue] = useState({
        upi_id: "",
        transaction_id: "",
        paid_amount: "",
        image: null
    });

    const [accValue, setAccValue] = useState({
        account_no: "",
        ifsc_no: "",
        account_name: "",
        bank_name: "",
        branch_name: "",
        transaction_id: "",
        paid_amount: "",
        image: null
    });

    const [chequeValue, setChequeValue] = useState({
        cheque_no: "",
        cheque_date: "",
        paid_amount: "",
        image: null
    });

    const [totalAmount, setTotalAmount] = useState(0);


    useEffect(() => {
        let totalCashAmount = 0;
        totalCashAmount = totalCashAmount + (cashValue.fivehundred * 500)
        totalCashAmount = totalCashAmount + (cashValue.twohundred * 200)
        totalCashAmount = totalCashAmount + (cashValue.hundred * 100)
        totalCashAmount = totalCashAmount + (cashValue.fifty * 50)
        totalCashAmount = totalCashAmount + (cashValue.twenty * 20)
        totalCashAmount = totalCashAmount + (cashValue.ten * 10)
        totalCashAmount = totalCashAmount + (cashValue.five * 5)
        totalCashAmount = totalCashAmount + (cashValue.two * 2)
        totalCashAmount = totalCashAmount + cashValue.one

        setTotalAmount(totalCashAmount)
    }, [cashValue])

    useEffect(() => {
        getTransferTypeDropdownData();
    }, [])

    const getTransferTypeDropdownData = async () => {
        var response = await getAxios({
            url: utrTypeListEndPoint
        })

        if (response !== null) {
            setTransferTypeDropDown(response.data.list)
        }
    }

    const uploadImage = (value) => {
        var file = value.target.files[0];
        var reader = new FileReader();

        try {
            reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setimageData(e.target.result)
            };
        } catch (Err) {

        }
    }

    const updatePayment = async () => {
        if (paymentMethod === utrMethod) {
            let utrBody = utrValue
            utrBody['payment_method'] = utrMethod
            utrBody['transaction_date'] = utrValue.transaction_date.startDate

            if (imageData !== undefined && imageData !== null) {
                var response = await postAxios({
                    url: s3ImageUploadEndPoint,
                    body: new FormData(document.getElementById('image-form'))
                })
        
                if (response != null) {
                    utrBody['image'] =  response.url
                }
            }

            var response = await putAxiosWithToken({
                url: soPaymentEndPoint + salesDetails.id + '/',
                body: utrBody
            })
    
            if (response != null) {
                toast.success(response.message)
            }
        } else if (paymentMethod === upiMethod) {
            let upiBody = upiValue
            upiBody['payment_method'] = upiMethod
            upiBody['transaction_date'] = utrValue.transaction_date.startDate

            if (imageData !== undefined && imageData !== null) {
                var response = await postAxios({
                    url: s3ImageUploadEndPoint,
                    body: new FormData(document.getElementById('image-form'))
                })
        
                if (response != null) {
                    upiValue['image'] =  response.url
                }
            }

            var response = await putAxiosWithToken({
                url: soPaymentEndPoint + salesDetails.id + '/',
                body: upiValue
            })
    
            if (response != null) {
                toast.success(response.message)
            }
        } else if (paymentMethod === accountTransferMethod) {
            let accBody = accValue
            accBody['payment_method'] = accountTransferMethod

            if (imageData !== undefined && imageData !== null) {
                var response = await postAxios({
                    url: s3ImageUploadEndPoint,
                    body: new FormData(document.getElementById('image-form'))
                })
        
                if (response != null) {
                    accBody['image'] =  response.url
                }
            }

            var response = await putAxiosWithToken({
                url: soPaymentEndPoint + salesDetails.id + '/',
                body: accBody
            })
    
            if (response != null) {
                toast.success(response.message)
            }
        } else if (paymentMethod === chequeMethod) {
            let chequeBody = chequeValue
            chequeBody['payment_method'] = chequeMethod

            if (imageData !== undefined && imageData !== null) {
                var response = await postAxios({
                    url: s3ImageUploadEndPoint,
                    body: new FormData(document.getElementById('image-form'))
                })
        
                if (response != null) {
                    chequeBody['image'] =  response.url
                }
            }

            var response = await putAxiosWithToken({
                url: soPaymentEndPoint + salesDetails.id + '/',
                body: chequeBody
            })
    
            if (response != null) {
                toast.success(response.message)
            }
        } else if (paymentMethod === cashMethod) {
            let cashBody = cashValue
            cashBody['payment_method'] = cashMethod
            cashBody['paid_amount'] = totalAmount

            var response = await putAxiosWithToken({
                url: soPaymentEndPoint + salesDetails.id + '/',
                body: cashBody
            })
    
            if (response != null) {
                toast.success(response.message)
            }
        }
    }

    return (
        <div>
            <div className={`${paymentAmountDetails ? 'translate-x-0' : 'translate-x-[275px]'} z-[3] cursor-pointer fixed right-0 flex items-center transform transition duration-200 ease-in-out`}>
                <div onClick={() => setPaymentAmountDetails(!paymentAmountDetails)} className="w-[15px] rounded-l uppercase h-fit border border-primary break-all bg-secondary p-[5px] text-[9px]">Payment Details</div>
                {
                    salesDetails?.particulars?.length > 0 && <div>
                        <div className="flex flex-col items-end mt-2">
                            <div className="flex w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                                <div className="text-[13px] sm:text-[18px]">Amount</div>
                                <div className="text-[13px] sm:text-[18px]">{salesDetails.total_amount?.toFixed(2)}</div>
                            </div>
                            <div className="flex w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                                <div className="text-[13px] sm:text-[18px]">Gst Amount</div>
                                <div className="text-[13px] sm:text-[18px]">{salesDetails.gst_amount?.toFixed(2)}</div>
                            </div>
                            <div className="flex w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                                <div className="text-[13px] sm:text-[18px]">PO Amount</div>
                                <div className="text-[13px] sm:text-[18px]">{salesDetails.grand_total?.toFixed(2)}</div>
                            </div>
                            <div className="flex w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                                <div className="text-[13px] sm:text-[18px]">Paid</div>
                                <div className="text-[13px] sm:text-[18px]">{salesDetails.paid_amount?.toFixed(2)}</div>
                            </div>
                            <div className="flex w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                                <div className="text-[13px] sm:text-[18px]">Shortage Quantity</div>
                                <div className="text-[13px] sm:text-[18px]">{salesDetails.shortage_quantity_amount?.toFixed(2)}</div>
                            </div>
                            <div className="flex w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                                <div className="text-[13px] sm:text-[18px]">Supplier Due</div>
                                <div className="text-[13px] sm:text-[18px]">{salesDetails.supplier_due?.toFixed(2)}</div>
                            </div>
                            <div className="flex w-[275px] justify-between items-center border border-gray-400 bg-gray-200 p-[5px]">
                                <div className="text-[13px] sm:text-[18px]">Payable</div>
                                <div className="text-[13px] sm:text-[18px]">{salesDetails.payable_amount?.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="hidden sm:flex items-center gap-5">
                <div onClick={() => setPaymentMethod(1)} className="flex gap-3 items-center cursor-pointer">
                    <div className="w-[20px] h-[20px] rounded-full border border-primary flex items-center justify-center">
                        <div className={`${paymentMethod === 1 ? 'block' : 'hidden'} w-[12px] h-[12px] rounded-full bg-primary`}></div>
                    </div>
                    <div>UTR</div>
                </div>
                <div onClick={() => setPaymentMethod(2)} className="flex gap-3 items-center cursor-pointer">
                    <div className="w-[20px] h-[20px] rounded-full border border-primary flex items-center justify-center">
                        <div className={`${paymentMethod === 2 ? 'block' : 'hidden'} w-[12px] h-[12px] rounded-full bg-primary`}></div>
                    </div>
                    <div>Cash</div>
                </div>
                <div onClick={() => setPaymentMethod(3)} className="flex gap-3 items-center cursor-pointer">
                    <div className="w-[20px] h-[20px] rounded-full border border-primary flex items-center justify-center">
                        <div className={`${paymentMethod === 3 ? 'block' : 'hidden'} w-[12px] h-[12px] rounded-full bg-primary`}></div>
                    </div>
                    <div>UPI</div>
                </div>
                <div onClick={() => setPaymentMethod(4)} className="flex gap-3 items-center cursor-pointer">
                    <div className="w-[20px] h-[20px] rounded-full border border-primary flex items-center justify-center">
                        <div className={`${paymentMethod === 4 ? 'block' : 'hidden'} w-[12px] h-[12px] rounded-full bg-primary`}></div>
                    </div>
                    <div>Account Transfer</div>
                </div>
                <div onClick={() => setPaymentMethod(5)} className="flex gap-3 items-center cursor-pointer">
                    <div className="w-[20px] h-[20px] rounded-full border border-primary flex items-center justify-center">
                        <div className={`${paymentMethod === 5 ? 'block' : 'hidden'} w-[12px] h-[12px] rounded-full bg-primary`}></div>
                    </div>
                    <div>Cheque</div>
                </div>
            </div>

            <div className="block sm:hidden">
                <select className="w-full bg-white px-[10px] py-[5px] rounded-lg border outline-primary" value={paymentMethod} onChange={(e) => setPaymentMethod(Number(e.target.value))}>
                    <option value={1}>UTR</option>
                    <option value={2}>Cash</option>
                    <option value={3}>UPI</option>
                    <option value={4}>Account Transfer</option>
                    <option value={5}>Chaeck</option>
                </select>
            </div>

            <form id="image-form">
                <input type="file" id="imageupload" name="image" onChange={uploadImage} hidden />
            </form>

            {
                paymentMethod === cashMethod &&
                <div className="my-[25px] bg-gray-200 p-[15px]">
                    <p>Cash Denomination</p>


                    <div className="my-[15px] text-[13px] sm:text-[18px]">
                        <div className="flex items-center justify-around h-[55px]">
                            <p className="md:w-[100px]">500</p>
                            <p className="md:w-[100px]">X</p>
                            <input
                                type="number"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[50px] md:w-[100px] py-[5px] px-[10px] text-[17px] outline-none"
                                id="fivehundred"
                                value={cashValue?.fivehundred}
                                onChange={(e) => setCashValue({ ...cashValue, fivehundred: Number(e.target.value) })}
                            />
                            <p className="md:w-[100px]">=</p>
                            <p className="md:w-[100px] text-end">{500 * cashValue.fivehundred}.00</p>
                        </div>
                        <div className="flex items-center justify-around h-[55px]">
                            <p className="md:w-[100px]">200</p>
                            <p className="md:w-[100px]">X</p>
                            <input
                                type="number"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[50px] md:w-[100px] py-[5px] px-[10px] text-[17px] outline-none"
                                id="twohundred"
                                value={cashValue?.twohundred}
                                onChange={(e) => setCashValue({ ...cashValue, twohundred: Number(e.target.value) })}
                            />
                            <p className="md:w-[100px]">=</p>
                            <p className="md:w-[100px]  text-end">{200 * cashValue.twohundred}.00</p>
                        </div>
                        <div className="flex items-center justify-around h-[55px]">
                            <p className="md:w-[100px]">100</p>
                            <p className="md:w-[100px]">X</p>
                            <input
                                type="number"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[50px] md:w-[100px] py-[5px] px-[10px] text-[17px] outline-none"
                                id="hundred"
                                value={cashValue?.hundred}
                                onChange={(e) => setCashValue({ ...cashValue, hundred: Number(e.target.value) })}
                            />
                            <p className="md:w-[100px]">=</p>
                            <p className="md:w-[100px] text-end">{100 * cashValue.hundred}.00</p>
                        </div>
                        <div className="flex items-center justify-around h-[55px]">
                            <p className="md:w-[100px]">50</p>
                            <p className="md:w-[100px]">X</p>
                            <input
                                type="number"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[50px] md:w-[100px] py-[5px] px-[10px] text-[17px] outline-none"
                                id="fifty"
                                value={cashValue?.fifty}
                                onChange={(e) => setCashValue({ ...cashValue, fifty: Number(e.target.value) })}
                            />
                            <p className="md:w-[100px]">=</p>
                            <p className="md:w-[100px] text-end">{50 * cashValue.fifty}.00</p>
                        </div>
                        <div className="flex items-center justify-around h-[55px]">
                            <p className="md:w-[100px]">20</p>
                            <p className="md:w-[100px]">X</p>
                            <input
                                type="number"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[50px] md:w-[100px] py-[5px] px-[10px] text-[17px] outline-none"
                                id="twenty"
                                value={cashValue?.twenty}
                                onChange={(e) => setCashValue({ ...cashValue, twenty: Number(e.target.value) })}
                            />
                            <p className="md:w-[100px]">=</p>
                            <p className="md:w-[100px] text-end">{20 * cashValue.twenty}.00</p>
                        </div>
                        <div className="flex items-center justify-around h-[55px]">
                            <p className="md:w-[100px]">10</p>
                            <p className="md:w-[100px]">X</p>
                            <input
                                type="number"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[50px] md:w-[100px] py-[5px] px-[10px] text-[17px] outline-none"
                                id="ten"
                                value={cashValue?.ten}
                                onChange={(e) => setCashValue({ ...cashValue, ten: Number(e.target.value) })}
                            />
                            <p className="md:w-[100px]">=</p>
                            <p className="md:w-[100px] text-end">{10 * cashValue.ten}.00</p>
                        </div>
                        <div className="flex items-center justify-around h-[55px]">
                            <p className="md:w-[100px]">5</p>
                            <p className="md:w-[100px]">X</p>
                            <input
                                type="number"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[50px] md:w-[100px] py-[5px] px-[10px] text-[17px] outline-none"
                                id="five"
                                value={cashValue?.five}
                                onChange={(e) => setCashValue({ ...cashValue, five: Number(e.target.value) })}
                            />
                            <p className="md:w-[100px]">=</p>
                            <p className="md:w-[100px] text-end">{5 * cashValue.five}.00</p>
                        </div>
                        <div className="flex items-center justify-around h-[55px]">
                            <p className="md:w-[100px]">2</p>
                            <p className="md:w-[100px]">X</p>
                            <input
                                type="number"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[50px] md:w-[100px] py-[5px] px-[10px] text-[17px] outline-none"
                                id="two"
                                value={cashValue?.two}
                                onChange={(e) => setCashValue({ ...cashValue, two: Number(e.target.value) })}
                            />
                            <p className="md:w-[100px]">=</p>
                            <p className="md:w-[100px] text-end">{2 * cashValue.two}.00</p>
                        </div>
                        <div className="flex items-center justify-around h-[55px]">
                            <p className="md:w-[100px]">1</p>
                            <p className="md:w-[100px]">X</p>
                            <input
                                type="number"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[50px] md:w-[100px] py-[5px] px-[10px] text-[17px] outline-none"
                                id="one"
                                value={cashValue?.one}
                                onChange={(e) => setCashValue({ ...cashValue, one: Number(e.target.value) })}
                            />
                            <p className="md:w-[100px]">=</p>
                            <p className="md:w-[100px] text-end">{1 * cashValue.one}.00</p>
                        </div>
                    </div>

                    <div className="flex items-center justify-end w-full gap-5">
                        <p>Total Amount</p>
                        <div>{totalAmount}.00</div>
                    </div>
                </div>
            }

            <div className={`${paymentMethod !== cashMethod ? 'block' : 'hidden'} border p-[15px] rounded-xl shadow-lg my-[25px]`}>
                {
                    paymentMethod === utrMethod &&
                    <div>
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">UTR Transfer</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Transfer Type</p>
                                <select
                                    placeholder="transfer type"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="transfer_type"
                                    onChange={(e) => setUtrValue({...utrValue, transfer_type: e.target.value})}
                                    value={utrValue.transfer_type}
                                >
                                    <option value={""}>--select utr type--</option>
                                    {
                                        transferTypeDropdown.map((option) => (
                                            <option key={option.id} value={option.id} className="capitalize">{option.type_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.transfer_type?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Account Number</p>
                                <input
                                    type="text"
                                    placeholder="account no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="account_no"
                                    onChange={(e) => setUtrValue({...utrValue, account_no: e.target.value})}
                                    value={utrValue.account_no}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.account_no?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">IFSC Code</p>
                                <input
                                    type="text"
                                    placeholder="ifsc code"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="ifsc_no"
                                    onChange={(e) => setUtrValue({...utrValue, ifsc_no: e.target.value})}
                                    value={utrValue.ifsc_no}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.ifsc_no?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Transaction Date</p>

                                <DatePicker value={utrValue.transaction_date} handleValueChange={(value) => setUtrValue({...utrValue, transaction_date: value})} />
                                
                                <p className="text-red-500 font-[400] text-[13px]">{errors.transaction_date?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Transaction Description</p>
                                <input
                                    type="text"
                                    placeholder="transaction description"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="transaction_desc"
                                    onChange={(e) => setUtrValue({...utrValue, transaction_desc: e.target.value})}
                                    value={utrValue.transaction_desc}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.transaction_desc?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Transaction Id</p>
                                <input
                                    type="text"
                                    placeholder="transaction id"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="transaction_id"
                                    onChange={(e) => setUtrValue({...utrValue, transaction_id: e.target.value})}
                                    value={utrValue.transaction_id}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.transaction_id?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Paid Amount</p>
                                <input
                                    type="text"
                                    placeholder="paid amount"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="paid_amount"
                                    onChange={(e) => setUtrValue({...utrValue, paid_amount: e.target.value})}
                                    value={utrValue.paid_amount}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.paid_amount?.message}</p>
                            </div>
                        </div>

                    </div>
                }

                {
                    paymentMethod === upiMethod &&
                    <div>
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">UPI Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">UPI Id</p>
                                <input
                                    type="text"
                                    placeholder="upi id"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="upi_id"
                                    onChange={(e) => setUpiValue({...upiValue, upi_id: e.target.value})}
                                    value={upiValue.upi_id}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.upi_id?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Transaction Id</p>
                                <input
                                    type="text"
                                    placeholder="transaction id"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="transaction_id"
                                    onChange={(e) => setUpiValue({...upiValue, transaction_id: e.target.value})}
                                    value={upiValue.transaction_id}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.transaction_id?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Paid Amount</p>
                                <input
                                    type="text"
                                    placeholder="paid amount"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="paid_amount"
                                    onChange={(e) => setUpiValue({...upiValue, paid_amount: e.target.value})}
                                    value={upiValue.paid_amount}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.paid_amount?.message}</p>
                            </div>
                        </div>

                    </div>
                }

                {
                    paymentMethod === accountTransferMethod &&
                    <div>
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Account Transfer</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Account Number</p>
                                <input
                                    type="text"
                                    placeholder="account no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="account_no"
                                    onChange={(e) => setAccValue({...accValue, account_no: e.target.value})}
                                    value={accValue.account_no}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.account_no?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Account Holder Name </p>
                                <input
                                    type="text"
                                    placeholder="account holder name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="account_name"
                                    onChange={(e) => setAccValue({...accValue, account_name: e.target.value})}
                                    value={accValue.account_name}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.account_name?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">IFSC Code</p>
                                <input
                                    type="text"
                                    placeholder="ifsc code"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="ifsc_no"
                                    onChange={(e) => setAccValue({...accValue, ifsc_no: e.target.value})}
                                    value={accValue.ifsc_no}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.ifsc_no?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Bank Name</p>
                                <input
                                    type="text"
                                    placeholder="bank name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="bank_name"
                                    onChange={(e) => setAccValue({...accValue, bank_name: e.target.value})}
                                    value={accValue.bank_name}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.bank_name?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Branch Name</p>
                                <input
                                    type="text"
                                    placeholder="branch name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="branch_name"
                                    onChange={(e) => setAccValue({...accValue, branch_name: e.target.value})}
                                    value={accValue.branch_name}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.branch_name?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Transaction Id</p>
                                <input
                                    type="text"
                                    placeholder="transaction id"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="transaction_id"
                                    onChange={(e) => setAccValue({...accValue, transaction_id: e.target.value})}
                                    value={accValue.transaction_id}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.transaction_id?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Paid Amount</p>
                                <input
                                    type="text"
                                    placeholder="paid amount"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="paid_amount"
                                    onChange={(e) => setAccValue({...accValue, paid_amount: e.target.value})}
                                    value={accValue.paid_amount}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.paid_amount?.message}</p>
                            </div>
                        </div>

                    </div>
                }

                {
                    paymentMethod === chequeMethod &&
                    <div>
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Cheque Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Cheque No</p>
                                <input
                                    type="text"
                                    placeholder="cheque no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="cheque_no"
                                    onChange={(e) => setChequeValue({...chequeValue, cheque_no: e.target.value})}
                                    value={chequeValue.cheque_no}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.cheque_no?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Cheque Date</p>
                                <input
                                    type="date"
                                    placeholder="check date"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="cheque_date"
                                    onChange={(e) => setChequeValue({...chequeValue, cheque_date: e.target.value})}
                                    value={chequeValue.cheque_date}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.cheque_date?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Paid Amount</p>
                                <input
                                    type="text"
                                    placeholder="paid amount"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="paid_amount"
                                    onChange={(e) => setChequeValue({...chequeValue, paid_amount: e.target.value})}
                                    value={chequeValue.paid_amount}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.paid_amount?.message}</p>
                            </div>
                        </div>

                    </div>
                }

                {
                    paymentMethod !== 2 &&
                    (
                        imageData ?
                            <div className="w-full relative border-2 border-dashed border-blue-600 rounded-lg overflow-hidden bg-blue-50 flex items-center justify-center gap-3">
                                <div className="w-[300px]">
                                    <img src={imageData} alt="no-image" />
                                </div>
                                <label className="text-blue-600 cursor-pointer underline absolute" htmlFor="imageupload">Upload file</label>
                            </div>

                            :
                            <div className="w-full border-2 border-dashed border-blue-600 rounded-lg h-[200px] overflow-hidden bg-blue-50 flex items-center justify-center">
                                <label className="text-blue-600 cursor-pointer underline" htmlFor="imageupload">Upload file</label>
                            </div>
                    )
                }
            </div>

            <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                <button
                    id="backnav"
                    className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                    onClick={(e) => { e.preventDefault(); navigate(-1) }}
                >close<span className="lowercase">(esc)</span></button>
                <button
                onClick={(e) => {e.preventDefault(); updatePayment()}}
                    id="addpaymentsubmit"
                    className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                >save<span className="lowercase">(ctrl+s)</span></button>
            </div>

        </div>
    );
}