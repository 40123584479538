import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import Routers from './routes';
import { clickToNotify } from './service/pushnotification';
import { useEffect, useState } from 'react';
import addNotification from 'react-push-notification';
import Logo from "./assets/images/logo.svg";
// import OneSignal from 'react-onesignal';
// import runOneSignal from './onesignal';

function App() {

  return (
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
  );
}

export default App;


{/* <script src="https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js" defer></script>
<script>
  window.OneSignalDeferred = window.OneSignalDeferred || [];
  OneSignalDeferred.push(function(OneSignal) {
    OneSignal.init({
      appId: "f2a2b4db-f253-47c6-9f8d-2de7093eb1ce",
    });
  });
</script> */}