import { useEffect, useRef, useState } from "react";
import { purchaseOrderEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { datetimetodate } from "../../../utilities/datetime";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { deniedStatus } from "../../../constant";
import PaymentHistoryDetails from "../../finance/popayment/paymenthistorydetails";
import InStockSummary from "./instocksummary";
import ReactToPrint from "react-to-print";
import PurchaseOrderCopy from "../../../components/PrinterComponents/PurchaseOrderCopy";
import { converttojson } from "../../../utilities/jsonvalidation";
import ViewPurchaseReturnSummary from "./purchasereturnsummary";

export default function ViewPurchaseOrders() {

    let componentRef = useRef();


    const navigate = useNavigate();

    const [purchaseDetails, setPurchaseDetails] = useState({});

    const [tab, setTab] = useState(1);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {
        getPurchaseOrderDetails()
    }, [])

    const getPurchaseOrderDetails = async () => {
        var response = await getAxios({
            url: purchaseOrderEndPoint + localStorage.getItem('purchase_id') + '/'
        })

        if (response != null) {
            setPurchaseDetails(response.data)
        }
    }
    return (
        <>
            <div className="flex gap-[10px] border p-[5px] sm:p-[10px] mb-[25px] rounded-lg">
                <div onClick={() => setTab(1)} className={`${tab === 1 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Purchase Details</div>
                <div onClick={() => setTab(2)} className={`${tab === 2 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Payment History</div>
                <div onClick={() => setTab(3)} className={`${tab === 3 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Instock Summary</div>
                <div onClick={() => setTab(4)} className={`${tab === 4 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Purchase Return Summary</div>
                <div onClick={() => setTab(5)} className={`${tab === 5 ? 'bg-primary rounded-md text-white' : ''} text-[12px] sm:text-[18px] px-[10px] py-[5px]  cursor-pointer`}>Purchase Order Copy</div>
            </div>

            <ReactToPrint
                trigger={() => <button className="fixed bottom-[50px] right-[50px] z-[3] rounded-full bg-secondary text-primary border border-primary p-[15px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 256 256"><path fill="currentColor" d="M214.67 72H200V40a8 8 0 0 0-8-8H64a8 8 0 0 0-8 8v32H41.33C27.36 72 16 82.77 16 96v80a8 8 0 0 0 8 8h32v32a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-32h32a8 8 0 0 0 8-8V96c0-13.23-11.36-24-25.33-24ZM72 48h112v24H72Zm112 160H72v-48h112Zm40-40h-24v-16a8 8 0 0 0-8-8H64a8 8 0 0 0-8 8v16H32V96c0-4.41 4.19-8 9.33-8h173.34c5.14 0 9.33 3.59 9.33 8Zm-24-52a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z" /></svg>
                </button>}
                content={() => componentRef}
            />

            {tab === 1 && <div className="mb-[25px]">
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Purchase Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Purchase Id</p>
                            <div>{purchaseDetails?.purchase_order_id}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Order Date</p>
                            <div>{purchaseDetails.created_at && datetimetodate(purchaseDetails.created_at)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Purchase Created By</p>
                            <div>{purchaseDetails?.created_by}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Order Status</p>
                            <div style={{ color: purchaseDetails?.order_status_color }} className="capitalize w-fit">{purchaseDetails?.order_status_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Required Date</p>
                            <div>{purchaseDetails.order_req_date && datetimetodate(purchaseDetails.order_req_date)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                            <div>{purchaseDetails.with_gst ? 'With' : 'Without'}</div>
                        </div>
                    </div>
                    <div className={`${purchaseDetails?.order_status === deniedStatus ? 'block' : 'hidden'} border-t mt-[10px] pt-[10px]`}>{purchaseDetails.denied_reason}</div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier</p>
                            <div>{purchaseDetails?.supplier_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier Id</p>
                            <div>{purchaseDetails?.supplier_id}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Delivery Address Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Address</p>
                            <div>{purchaseDetails?.address}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Country</p>
                            <div>{converttojson(purchaseDetails?.country)?.name}</div>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">State</p>
                            <div>{converttojson(purchaseDetails?.state)?.name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">City</p>
                            <div>{purchaseDetails?.city}</div>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Pincode</p>
                            <div>{purchaseDetails?.pincode}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Particular Details</p>
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr className="border">
                                <td className="p-[10px] w-[50px]">S.No</td>
                                <td className="p-[10px] w-[200px]">Product</td>
                                <td className="p-[10px] w-[100px]">Rate</td>
                                <td className="p-[10px] w-[100px]">Order Qty</td>
                                <td className="p-[10px] w-[100px]">Net. Weight</td>
                                <td className="p-[10px] w-[100px]">GST %</td>
                                <td className="p-[10px] w-[100px]">GST Total</td>
                                <td className="p-[10px] w-[100px]">Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                purchaseDetails?.particulars?.map((item, index) => (
                                    <tr className="border">
                                        <td className="p-[10px] w-[50px]">{index + 1}</td>
                                        <td className="p-[10px] w-[200px]">{item.product_name}</td>
                                        <td className="p-[10px] w-[100px]">{item.purchase_price}</td>
                                        <td className="p-[10px] w-[100px]">{item.quantity}</td>
                                        <td className="p-[10px] w-[100px]">{item.nt_weight} {item.measurement}</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_percent} %</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_total}</td>
                                        <td className="p-[10px] w-[100px]">{item.grand_total}</td>

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {
                        purchaseDetails?.particulars?.length > 0 && <div>
                            <div className="flex flex-col items-end ">
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Amount</div>
                                    <div>{purchaseDetails.total_amount}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Gst Amount</div>
                                    <div>{purchaseDetails.gst_amount}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Grand Total</div>
                                    <div>{purchaseDetails.grand_total}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            }

            {
                tab === 2 && <PaymentHistoryDetails paymentHistory={purchaseDetails.payment_history} />
            }

            {
                tab === 3 && <InStockSummary summary={purchaseDetails.instock_summary} />
            }

            {
                tab === 4 && <ViewPurchaseReturnSummary summary={purchaseDetails.purchase_return_summary} />
            }

            {
                tab === 5 && <PurchaseOrderCopy purchaseDetails={purchaseDetails}  />
            }

            <div className="hidden">
                <div className="border mb-[25px]" ref={(el) => (componentRef = el)}>
                    <PurchaseOrderCopy purchaseDetails={purchaseDetails}  />
                </div>
            </div>
            
            

            <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 mb-[50px]">
                <button
                    id="backnav"
                    className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                    onClick={(e) => { e.preventDefault(); navigate(-1) }}
                >close<span className="lowercase">(esc)</span></button>
            </div>

            
        </>
    );
}