import { useEffect, useState } from "react";
import SwitchButton from "../../../components/Switch";
import { getAxios } from "../../../service/apiservice";
import { supplierEndPoint } from "../../../service/api";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { converttojson } from "../../../utilities/jsonvalidation";

export default function ViewSupplierDetails() {

    const navigate = useNavigate();

    const [currentSupplier, setCurrentSupplier] = useState({});
    const [gstEnabled, setGstEnabled] = useState(false);
    const [bankEnabled, setBankEnabled] = useState(false);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {
        getSupplierDetails();
    }, [])

    const getSupplierDetails = async () => {
        var response = await getAxios({
            url: supplierEndPoint + localStorage.getItem('supplier_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                supplier_name: response.data?.supplier_details?.supplier_name,
                email: response.data?.supplier_details?.email,
                mobile: response.data?.supplier_details?.mobile,
                due_amount: response.data?.supplier_details?.due_amount,
                outstanding_amount: response.data?.supplier_details?.outstanding_amount,
                address: response.data?.supplier_details?.address,
                country: response.data?.supplier_details?.country,
                state: response.data?.supplier_details?.state,
                city: response.data?.supplier_details?.city,
                pincode: response.data?.supplier_details?.pincode,
                pan_no: response.data?.supplier_gst_details?.pan_no,
                gst_no: response.data?.supplier_gst_details?.gst_no,
                registered_name: response.data?.supplier_gst_details?.registered_name,
                gst_status: response.data?.supplier_gst_details?.gst_status,
                tax_payer_type: response.data?.supplier_gst_details?.tax_payer_type,
                bussiness_type: response.data?.supplier_gst_details?.bussiness_type,
                acc_holder_name: response.data?.supplier_bank_details?.acc_holder_name,
                account_no: response.data?.supplier_bank_details?.account_no,
                ifsc_code: response.data?.supplier_bank_details?.ifsc_code,
                bank_name: response.data?.supplier_bank_details?.bank_name,
                branch_name: response.data?.supplier_bank_details?.branch_name,
                micr_code: response.data?.supplier_bank_details?.micr_code,
            }
            setCurrentSupplier(tempDetails)
        }
    }

    return (
        <div>
            <div className="border p-[15px] rounded-xl shadow-lg">
                <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Supplier Name</p>
                        <div>{currentSupplier?.supplier_name}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Email</p>
                        <div>{currentSupplier?.email}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Phone no</p>
                        <div>{currentSupplier?.mobile}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Supplier Due</p>
                        <div>{currentSupplier?.due_amount}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Supplier Outstanding</p>
                        <div>{currentSupplier?.outstanding_amount}</div>
                    </div>
                </div>
            </div>

            <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Address Details</p>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Address</p>
                        <div>{currentSupplier?.address}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Country</p>
                        <div>{converttojson(currentSupplier?.country)?.name}</div>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px] font-[600]">State</p>
                        <div>{converttojson(currentSupplier?.state)?.name}</div>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px] font-[600]">City</p>
                        <div>{currentSupplier?.city}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Pincode</p>
                        <div>{currentSupplier?.pincode}</div>
                    </div>
                </div>
            </div>

            <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2 flex justify-between">
                    <div>GST Details</div>
                    <div onClick={() => setGstEnabled(!gstEnabled)} className={`${gstEnabled ? '' : 'rotate-180'} w-fit h-fit`} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path fill="currentColor" d="M3.2 10.26a.75.75 0 0 0 1.06.04L8 6.773l3.74 3.527a.75.75 0 1 0 1.02-1.1l-4.25-4a.75.75 0 0 0-1.02 0l-4.25 4a.75.75 0 0 0-.04 1.06Z" /></svg>
                    </div>
                </p>
                <div className={`${gstEnabled ? 'block' : 'hidden'} grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5`}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Pan Number</p>
                        <div>{currentSupplier?.pan_no}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">GST NO</p>
                        <div>{currentSupplier?.gst_no}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">GST Status</p>
                        <div>{currentSupplier?.gst_status}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">GST Type</p>
                        <div>{currentSupplier?.tax_payer_type}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Bussiness Type</p>
                        <div>{currentSupplier?.bussiness_type}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Registered Name</p>
                        <div>{currentSupplier?.registered_name}</div>
                    </div>
                </div>
            </div>

            <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2 flex justify-between">
                    <div>Bank Details</div>
                    <div onClick={() => setBankEnabled(!bankEnabled)} className={`${bankEnabled ? '' : 'rotate-180'} w-fit h-fit`} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path fill="currentColor" d="M3.2 10.26a.75.75 0 0 0 1.06.04L8 6.773l3.74 3.527a.75.75 0 1 0 1.02-1.1l-4.25-4a.75.75 0 0 0-1.02 0l-4.25 4a.75.75 0 0 0-.04 1.06Z" /></svg>
                    </div>
                </p>
                <div className={`${bankEnabled ? 'block' : 'hidden'} grid min-[2000px]:grid-cols-5 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 gap-5`}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Account Holder Name</p>
                        <div>{currentSupplier?.acc_holder_name}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Account No</p>
                        <div>{currentSupplier?.account_no}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">IFSC Code</p>
                        <div>{currentSupplier?.ifsc_code}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Bank Name</p>
                        <div>{currentSupplier?.bank_name}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">Branch Name</p>
                        <div>{currentSupplier?.branch_name}</div>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px] font-[600]">MICR No</p>
                        <div>{currentSupplier?.micr_code}</div>
                    </div>
                </div>
            </div>

            <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                <button
                    id="backnav"
                    className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                    onClick={(e) => { e.preventDefault(); navigate(-1) }}
                >close<span className="lowercase">(esc)</span></button>
            </div>
        </div>
    );
}