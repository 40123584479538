import { memo } from "react";

function Search({ onChange, text }) {
    return <input
        type="text"
        value={text}
        onChange={onChange}
        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
}

export default memo(Search)