import { useEffect, useState } from "react";
import { datetimetodate } from "../../../utilities/datetime";
import { getAxios } from "../../../service/apiservice";
import { instockEndPoint } from "../../../service/api";

import { downloadImageAsJpeg, downloadImageAsPng } from "../../../utilities/download";

export default function InStockSummary({ summary = [] }) {

    const [instockDetails, setInstockDetails] = useState({});
    const [instockImageDetails, setInstockImageDetails] = useState({});
    const [particulars, setParticulars] = useState([]);
    const [imageParticulars, setImageParticulars] = useState([]);

    const [activeDetail, setActiveDetail] = useState(null);
    const [downloadIndex, setDownloadIndex] = useState(null);

    const [summaryList, setSummaryList] = useState(true);

    useEffect(() => {
        if (summary.length > 0) {
            getInstockDetails(summary[0].id, 0)
        }
    },[summary])

    const getInstockDetails = async (id, index) => {
        var response = await getAxios({
            url: instockEndPoint + id + '/'
        });

        if (response != null) {
            setInstockDetails(response.data);
            setParticulars(response.data.particulars);
            setInstockImageDetails(response.data);
            setImageParticulars(response.data.particulars);
            setActiveDetail(index);
        }
    }

    

    return (
        <div className="">
            <div className="mb-[50px]">
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Instock Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Entry No</p>
                            <div>{instockDetails?.entry_no}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Entry Type</p>
                            <div>{instockDetails?.entry_type} no</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Instock Created By</p>
                            <div>{instockDetails?.created_by_name}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Instock Created Date</p>
                            <div>{instockDetails?.created_at && datetimetodate(instockDetails.created_at)}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Purchase Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Purchase Id</p>
                            <div>{instockDetails?.purchase_order_id}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Order Date</p>
                            <div>{instockDetails.purchase_created_at && datetimetodate(instockDetails.purchase_created_at)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Purchase Created By</p>
                            <div>{instockDetails?.purchase_created_by_name}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Order Status</p>
                            <div style={{ color: instockDetails?.order_status_color }} className="capitalize w-fit">{instockDetails?.order_status_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Required Date</p>
                            <div>{instockDetails?.order_req_date && datetimetodate(instockDetails?.order_req_date)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                            <div>{instockDetails?.with_gst ? 'With' : 'Without'}</div>
                        </div>
                    </div>
                </div>

                <div className="mb-[25px]">
                    {
                        particulars.map((item, index) => (
                            <div className="p-[15px] shadow-lg border rounded-lg">
                                <div className="flex gap-[15px] items-start ">
                                    <div className="min-w-[90px] max-w-[90px] min-h-[90px] max-h-[90px] bg-gray-300 rounded-lg">
                                        <img src={item.image} />
                                    </div>
                                    <div className="w-full">
                                        <div className="min-[650px]:flex justify-between items-start">
                                            <div>
                                                <p className="text-[12px] sm:text-[15px] font-[600]">{item.product_name}</p>
                                                <p className="text-[12px] sm:text-[15px] font-[400]">Product Code: <span className="text-primary font-[500]">{item.product_code}</span></p>
                                                <p className="text-[12px] sm:text-[15px] font-[400]">Order Quantity: <span className="text-primary font-[500]">{item.order_quantity}</span></p>
                                                <p className="text-[12px] sm:text-[15px] font-[400]">Total Recieved Quantity: <span className="text-primary font-[500]">{item.recieved_items}</span></p>
                                            </div>
                                            {
                                                item.is_completed ? <div className="sm:flex gap-[15px]">
                                                    <div className="flex items-center gap-[5px]">
                                                        <label className="text-[12px] sm:text-[15px] text-green-600">Completed</label>
                                                    </div>
                                                    <div className="border h-[full]"></div>
                                                    <div className="min-[340px]:flex items-center gap-[5px]">
                                                        <p className="text-[12px] sm:text-[15px]">Shortage Quantity :  </p>
                                                        <p className="text-primary font-[500] ">{item.shortage_quantity}</p>
                                                    </div>
                                                </div> : ""
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[15px] mt-[10px]">
                                    <div className="">
                                        <p className="mb-[5px] text-[15px] font-[500]">Recieved Items</p>
                                        <div>{item.recieved_quantity}</div>
                                    </div>
                                    <div className="">
                                        <p className="mb-[5px] text-[15px] font-[500]">Batch No</p>
                                        <div>{item.batch_no}</div>
                                    </div>
                                    <div className="">
                                        <p className="mb-[5px] text-[15px] font-[500]">Manufactured Date</p>
                                        <div>{item.mfg_date && datetimetodate(item.mfg_date)}</div>
                                    </div>
                                    <div className="">
                                        <p className="mb-[5px] text-[15px] font-[500]">Expiry Date</p>
                                        <div>{item.exp_date && datetimetodate(item.exp_date)}</div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div id="downloadasimage" className="bg-white hidden">
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Instock Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Entry No</p>
                            <div>{instockImageDetails?.entry_no}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Entry Type</p>
                            <div>{instockImageDetails?.entry_type} no</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Instock Created By</p>
                            <div>{instockImageDetails?.created_by_name}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Instock Created Date</p>
                            <div>{instockImageDetails?.created_at && datetimetodate(instockImageDetails.created_at)}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Purchase Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Purchase Id</p>
                            <div>{instockImageDetails?.purchase_order_id}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Order Date</p>
                            <div>{instockImageDetails.purchase_created_at && datetimetodate(instockImageDetails.purchase_created_at)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Purchase Created By</p>
                            <div>{instockImageDetails?.purchase_created_by_name}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Order Status</p>
                            <div style={{ color: instockImageDetails?.order_status_color }} className="capitalize w-fit">{instockImageDetails?.order_status_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Required Date</p>
                            <div>{instockImageDetails?.order_req_date && datetimetodate(instockImageDetails?.order_req_date)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                            <div>{instockImageDetails?.with_gst ? 'With' : 'Without'}</div>
                        </div>
                    </div>
                </div>

                <div className="mb-[25px]">
                    {
                        imageParticulars.map((item, index) => (
                            <div className="p-[15px] shadow-lg border rounded-lg">
                                <div className="flex gap-[15px] items-start ">
                                    <div className="w-full">
                                        <div className="min-[650px]:flex justify-between items-start">
                                            <div>
                                                <p className="text-[12px] sm:text-[15px] font-[600]">{item.product_name}</p>
                                                <p className="text-[12px] sm:text-[15px] font-[400]">Product Code: <span className="text-primary font-[500]">{item.product_code}</span></p>
                                                <p className="text-[12px] sm:text-[15px] font-[400]">Order Quantity: <span className="text-primary font-[500]">{item.order_quantity}</span></p>
                                                <p className="text-[12px] sm:text-[15px] font-[400]">Total Recieved Quantity: <span className="text-primary font-[500]">{item.recieved_items}</span></p>
                                            </div>
                                            {
                                                item.is_completed ? <div className="sm:flex gap-[15px]">
                                                    <div className="flex items-center gap-[5px]">
                                                        <label className="text-[12px] sm:text-[15px] text-green-600">Completed</label>
                                                    </div>
                                                    <div className="border h-[full]"></div>
                                                    <div className="min-[340px]:flex items-center gap-[5px]">
                                                        <p className="text-[12px] sm:text-[15px]">Shortage Quantity :  </p>
                                                        <p className="text-primary font-[500] ">{item.shortage_quantity}</p>
                                                    </div>
                                                </div> : ""
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[15px] mt-[10px]">
                                    <div className="">
                                        <p className="mb-[5px] text-[15px] font-[500]">Recieved Items</p>
                                        <div>{item.recieved_quantity}</div>
                                    </div>
                                    <div className="">
                                        <p className="mb-[5px] text-[15px] font-[500]">Batch No</p>
                                        <div>{item.batch_no}</div>
                                    </div>
                                    <div className="">
                                        <p className="mb-[5px] text-[15px] font-[500]">Manufactured Date</p>
                                        <div>{item.mfg_date && datetimetodate(item.mfg_date)}</div>
                                    </div>
                                    <div className="">
                                        <p className="mb-[5px] text-[15px] font-[500]">Expiry Date</p>
                                        <div>{item.exp_date && datetimetodate(item.exp_date)}</div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className={`${summaryList ? 'translate-x-0' : 'translate-x-[300px] sm:translate-x-[500px]'} z-[3] cursor-pointer fixed top-0 right-0 flex items-center transform transition duration-200 ease-in-out`}>
                <div onClick={() => setSummaryList(!summaryList)} className="w-[15px] rounded-l uppercase h-fit border border-primary break-all bg-secondary p-[5px] text-[9px]">summary Details</div>

                <div className="overflow-y-scroll h-[95vh] bg-white w-[300px] sm:w-[500px]">
                    <div className="flex items-center justify-between m-[5px]">
                        <div className="border border-primary bg-secondary text-primary rounded-lg px-[10px] py-[5px] ">Download All</div>
                    </div>
                    <div className="">
                        {
                            summary.map((item, index) => (
                                <div className={`${activeDetail === index ? 'bg-primary text-white' : ''} flex items-center justify-between p-[15px]`} key={'summary' + index}>
                                    <div>
                                        <div className="capitalize">{item.entry_type} no</div>
                                        <div>{item.entry_no}</div>
                                        <div>{item.created_by && datetimetodate(item.created_by, 3)}</div>
                                    </div>

                                    <div className="flex gap-3">
                                        {/* <div className={`${activeDetail === index ? "text-white" : "text-primary"}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M214.67 72H200V40a8 8 0 0 0-8-8H64a8 8 0 0 0-8 8v32H41.33C27.36 72 16 82.77 16 96v80a8 8 0 0 0 8 8h32v32a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-32h32a8 8 0 0 0 8-8V96c0-13.23-11.36-24-25.33-24ZM72 48h112v24H72Zm112 160H72v-48h112Zm40-40h-24v-16a8 8 0 0 0-8-8H64a8 8 0 0 0-8 8v16H32V96c0-4.41 4.19-8 9.33-8h173.34c5.14 0 9.33 3.59 9.33 8Zm-24-52a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z" /></svg>
                                        </div> */}
                                        <div className="relative">
                                            <div onClick={() => {getInstockDetails(item.id, index); setDownloadIndex(index)}} className={`${activeDetail === index ? "text-white" : "text-primary"}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m12 15.577l-3.538-3.539l.707-.719L11.5 13.65V5h1v8.65l2.33-2.33l.708.718L12 15.577ZM6.615 19q-.69 0-1.152-.462Q5 18.075 5 17.385v-2.423h1v2.423q0 .23.192.423q.193.192.423.192h10.77q.23 0 .423-.192q.192-.193.192-.423v-2.423h1v2.423q0 .69-.462 1.152q-.463.463-1.153.463H6.615Z" /></svg>
                                            </div>

                                            <div className={`${downloadIndex === index ? "block" : "hidden"} absolute right-0 border rounded-lg shadow-lg w-[100px] bg-white text-black`}>
                                                <div onClick={() => downloadImageAsPng("downloadasimage", instockImageDetails?.purchase_order_id)} className="p-[5px]">PNG</div>
                                                <div onClick={() => downloadImageAsJpeg("downloadasimage", instockImageDetails?.purchase_order_id)} className="p-[5px]">JPEG</div>
                                                {/* <div onClick={() => downloadImage('pdf')} className="p-[5px]">SVG</div> */}
                                            </div>
                                        </div>
                                        <div onClick={() => getInstockDetails(item.id, index)} className={`${activeDetail === index ? "text-white" : "text-primary"}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M3 13c3.6-8 14.4-8 18 0" /><path fill="currentColor" d="M12 17a3 3 0 1 1 0-6a3 3 0 0 1 0 6Z" /></g></svg>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}