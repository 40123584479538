import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { branchEndPoint, locationListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function BranchForm({ getData, formMode, currentBranch, setFormMode, setCurrentBranch }) {

    const [locationDropdown, setLocationDropdown] = useState([]);

    const schema = yup
        .object({
            branch_name: yup.string().required(),
            location: yup.string().required(),
            address: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            branch_name: currentBranch.branch_name,
            location: currentBranch.location,
            address: currentBranch.address
        } : {
            branch_name: "",
            location: "",
            address: ""
        }
    })

    useEffect(() => {
        getLocationDropdownData()
    },[])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const getLocationDropdownData = async () => {
        var response = await getAxios({
            url: locationListEndPoint
        })

        if (response !== null) {
            setLocationDropdown(response.data.list) 
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createBranch(data)
            } else {
                updateBranch(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createBranch = async (body) => {
        var response = await postAxiosWithToken({
            url: branchEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateBranch = async (body) => {
        var response = await putAxiosWithToken({
            url: branchEndPoint + currentBranch?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentBranch({
            branch_name: "",
            location: "",
            address: ""
        })
    }

    return (
        <>
            <div>
                <form className="grid sm:grid-cols-2 gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">Location</p>
                        <select
                            placeholder="location"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="location"
                            {...register('location')}
                        >
                            <option value={""}>--select location--</option>
                            {
                                locationDropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.location_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.location?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Branch Name</p>
                        <input
                            type="text"
                            placeholder="branch name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="branch_name"
                            id="forminit"
                            {...register('branch_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.branch_name?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Address</p>
                        <input
                            type="text"
                            placeholder="address"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="address"
                            {...register('address')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.address?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >Save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}