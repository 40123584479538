import { useState } from "react";
import Popup from "../../../components/Popup";
import { deleteAxiosWithToken } from "../../../service/apiservice";
import { salesExecutivesEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";

export default function SalesTargetDetails({ isLoading, executiveDetails, setTargetPopup, targetPopup, getData }) {

    const [currentTarget, setCurrentTarget] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    const deleteTargetDetails = async () => {
        var response = await deleteAxiosWithToken({
            url: salesExecutivesEndPoint + currentTarget.id + '/'
        })

        if (response !== null) {
            getData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }


    return (
        <div>


            <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                <div className="border-b mb-4 pb-2 flex items-center justify-between">
                    <p className="font-[600] text-[17px] text-primary ">Target History</p>
                    <div className="flex justify-end">
                        <button
                            onClick={() => { setTargetPopup(!targetPopup) }}
                            className="w-[300px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >Assign Target <span className="lowercase">(ctrl+m)</span></button>
                    </div>
                </div>
                <div className="w-full overflow-scroll">
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr className="border">
                                <td></td>
                                <td className="p-[10px] min-w-[200px]">Target Type</td>
                                <td className="p-[10px] min-w-[150px]">From</td>
                                <td className="p-[10px] min-w-[150px]">To</td>
                                <td className="p-[10px] min-w-[100px] text-center">Target</td>
                                <td className="p-[10px] min-w-[100px] text-center">Achieved</td>
                                <td className="p-[10px] min-w-[100px] text-center">Remaining</td>
                                <td className="p-[10px] min-w-[150px]">Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={9}>Loading...</td>
                            </tr> :
                                executiveDetails?.target_history?.length === 0 ? <tr><td className="text-center border" colSpan={9}>No Data</td></tr> : executiveDetails?.target_history?.map((item, index) => (
                                    <tr className="border">
                                        <td className="p-[10px]">
                                            <div onClick={() => { setCurrentTarget(item); setIsDeleteOpen(true) }} className="cursor-pointer hover:scale-125">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                            </div>
                                        </td>
                                        <td className="p-[10px] min-w-[200px]">{item.target_type_name}</td>
                                        <td className="p-[10px] min-w-[150px]">{item.from_date}</td>
                                        <td className="p-[10px] min-w-[150px]">{item.to_date}</td>
                                        <td className="p-[10px] min-w-[100px] text-center">{item.target}</td>
                                        <td className="p-[10px] min-w-[100px] text-center">{item.achieved}</td>
                                        <td className="p-[10px] min-w-[100px] text-center">{item.remaining}</td>
                                        <td className="p-[10px]  min-w-[150px]">
                                            <div className="text-center p-[10px] rounded-lg text-white" style={{ backgroundColor: item.status_color }}>
                                                {item.status_name}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <Popup title={'Delete Target Details'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentTarget?.target_type_name} target?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteTargetDetails() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>


        </div>
    );
}