import { useEffect, useRef, useState } from "react";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { menuPermissionEndPoint, userRoleListEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

export default function MenuPermission() {
    const isDark = useSelector((state) => state.themeConfig.isDark)

    const [userRolesList, setUserRoleList] = useState([]);
    const [menuPermissionList, setMenuPermissionList] = useState([]);


    const [activeRole, setActiveRole] = useState(0);

    useEffect(() => {
        getUserRoleData()
    }, [])

    const getMenuPermissionData = async (id) => {
        var response = await getAxios({
            url: menuPermissionEndPoint + String(id) + "/"
        })

        if (response !== null) {
            setMenuPermissionList(response.data)
        }
    }

    const getUserRoleData = async (id) => {
        var response = await getAxios({
            url: userRoleListEndPoint
        })

        if (response !== null) {
            setUserRoleList(response.data.list)
            getMenuPermissionData(response.data.list[0].id)
        }
    }

    const updateMenuPermission = async (value, action, menuId) => {
        if (action === 'all') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "view_permit": value,
                "add_permit": value,
                "edit_permit": value,
                "delete_permit": value
            }

            updatePermission(body)
        } else if (action === 'view') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "view_permit": value,
            }

            updatePermission(body)
        } else if (action === 'add') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "add_permit": value,
            }

            updatePermission(body)
        } else if (action === 'edit') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "edit_permit": value
            }

            updatePermission(body)
        } else if (action === 'delete') {
            let body = {
                "menu": menuId,
                "user_role": userRolesList[activeRole].id,
                "delete_permit": value
            }

            updatePermission(body)
        }
    }

    const updatePermission = async (body) => {
        var response = await postAxiosWithToken({
            url: menuPermissionEndPoint,
            body: body
        })

        if (response !== null) {
            getMenuPermissionData(userRolesList[activeRole].id)
            toast.success(response.message)
        }
    }

    return (
        <>
            <div className="md:flex md:gap-8">
                <div className={`${isDark ? 'bg-dark-bg' : 'bg-white'} border rounded-lg w-[250px] my-[10px] py-[5px] h-fit max-[768px]:flex`}>
                    {
                        userRolesList.map((item, index) => (
                            <div
                                onClick={() => { getMenuPermissionData(item.id); setActiveRole(Number(index)) }}
                                className={`${activeRole === index ? 'bg-primary' : ''} p-[10px] cursor-pointer capitalize`}
                            >{item.role_name}</div>
                        ))
                    }
                </div>

                <div className="w-full my-[10px] mb-[50px] max-[440px]:overflow-x-scroll">
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr>
                                <td className="p-[10px]">Menu Name</td>
                                <td className="p-[10px]">All</td>
                                <td className="p-[10px]">View</td>
                                <td className="p-[10px]">Add</td>
                                <td className="p-[10px]">Edit</td>
                                <td className="p-[10px]">Delete</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                menuPermissionList.map((item) =>


                                    item.menu_list.map((menu) => (
                                        <tr key={menu.id} className="border">
                                            <td className="p-[10px]">{menu.menu_name}</td>
                                            <td className="p-[10px]">
                                                <input
                                                    type="checkbox"
                                                    checked={menu.view_permit && menu.add_permit && menu.edit_permit && menu.delete_permit ? true : false}
                                                    onChange={(e) => updateMenuPermission(e.target.checked, 'all', menu.menu_id)}
                                                />
                                            </td>
                                            <td className="p-[10px]">
                                                <input
                                                    type="checkbox"
                                                    checked={menu.view_permit}
                                                    onChange={(e) => updateMenuPermission(e.target.checked, 'view', menu.menu_id)}
                                                />
                                            </td>
                                            <td className="p-[10px]">
                                                <input
                                                    type="checkbox"
                                                    checked={menu.add_permit}
                                                    onChange={(e) => updateMenuPermission(e.target.checked, 'add', menu.menu_id)}
                                                />
                                            </td>
                                            <td className="p-[10px]">
                                                <input
                                                    type="checkbox"
                                                    checked={menu.edit_permit}
                                                    onChange={(e) => updateMenuPermission(e.target.checked, 'edit', menu.menu_id)}
                                                />
                                            </td>
                                            <td className="p-[10px]">
                                                <input
                                                    type="checkbox"
                                                    checked={menu.delete_permit}
                                                    onChange={(e) => updateMenuPermission(e.target.checked, 'delete', menu.menu_id)} />

                                            </td>
                                        </tr>
                                    ))

                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}