import { useEffect, useState } from "react";
import { purchaseRequirementEndPoint, purchaseRequirementStatusEndPoint } from "../../../service/api";
import { getAxios, postAxios, putAxiosWithToken } from "../../../service/apiservice";
import { datetimetodate } from "../../../utilities/datetime";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { approveStatus, deniedStatus, pendingStatus } from "../../../constant";
import { toast } from "react-hot-toast";
import Popup from "../../../components/Popup";
import { converttojson } from "../../../utilities/jsonvalidation";

export default function ViewPurchaseRequirementsApproval() {

    const navigate = useNavigate();

    const [reqDetails, setReqDetails] = useState({});

    const [deniedPopup, setDeniedPopup] = useState(false);
    const [deniedMsgPopup, setDeniedMsgPopup] = useState(false);

    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            document.getElementById('backnav').click()
        }
    };

    useKeyPress(['Escape'], onKeyPress);

    useEffect(() => {
        getPurchaseRequirementDetails()
    }, [])

    const getPurchaseRequirementDetails = async () => {
        var response = await getAxios({
            url: purchaseRequirementEndPoint + localStorage.getItem('purchase_req_id') + '/'
        })

        if (response != null) {
            setReqDetails(response.data)
        }
    }

    const updateReqStatus = async (status) => {

        if (status === approveStatus) {
            let statusBody = {
                status: approveStatus
            }

            var response = await putAxiosWithToken({
                url: purchaseRequirementStatusEndPoint + localStorage.getItem('purchase_req_id') + '/',
                body: statusBody
            })

            if (response != null) {
                toast.success(response.message);
                getPurchaseRequirementDetails();
            }
        } else if (status === deniedStatus) {

            if (document.getElementById('reason')?.value.length < 1) {
                toast.warn('Denied Reason Required!');
                document.getElementById('reason').focus();
            } else {
                let statusBody = {
                    status: deniedStatus,
                    denied_reason: document.getElementById('reason').value
                }

                var response = await putAxiosWithToken({
                    url: purchaseRequirementStatusEndPoint + localStorage.getItem('purchase_req_id') + '/',
                    body: statusBody
                })
    
                if (response != null) {
                    document.getElementById('reason').value = ''
                    setDeniedPopup(false)
                    toast.success(response.message);
                    getPurchaseRequirementDetails();
                }
            }            
        }
    }
    return (
        <>
            <div className="my-[50px]">
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Requirement Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Requirement Id</p>
                            <div>{reqDetails?.requirement_id}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Order Date</p>
                            <div>{reqDetails.created_at && datetimetodate(reqDetails.created_at)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Requirement Created By</p>
                            <div>{reqDetails?.created_by}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Order Status</p>
                            <div onMouseEnter={() => {setDeniedMsgPopup(true)}} onMouseLeave={() => {setDeniedMsgPopup(false)}} className="relative cursor-pointer">
                                <div className={`${deniedMsgPopup ? 'block' : 'hidden'} absolute top-[25px] bg-white p-[10px] rounded-lg shadow-lg`}>{reqDetails?.denied_reason}</div>
                                <div style={{color: reqDetails?.order_status_color}} className="capitalize w-fit">{reqDetails?.order_status_name}</div>
                            </div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Required Date</p>
                            <div>{reqDetails.order_req_date && datetimetodate(reqDetails.order_req_date)}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                            <div>{reqDetails.with_gst ? 'With' : 'Without'}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier</p>
                            <div>{reqDetails?.supplier_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier Id</p>
                            <div>{reqDetails?.supplier_id}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Delivery Address Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Address</p>
                            <div>{reqDetails?.address}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Country</p>
                            <div>{converttojson(reqDetails?.country)?.name}</div>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">State</p>
                            <div>{converttojson(reqDetails?.state)?.name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">City</p>
                            <div>{reqDetails?.city}</div>
                        </div>
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Pincode</p>
                            <div>{reqDetails?.pincode}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Particular Details</p>
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr className="border">
                                <td className="p-[10px] w-[50px]">S.No</td>
                                <td className="p-[10px] w-[200px]">Product</td>
                                <td className="p-[10px] w-[100px]">Rate</td>
                                <td className="p-[10px] w-[100px]">Order Qty</td>
                                <td className="p-[10px] w-[100px]">Net. Weight</td>
                                <td className="p-[10px] w-[100px]">GST %</td>
                                <td className="p-[10px] w-[100px]">GST Total</td>
                                <td className="p-[10px] w-[100px]">Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reqDetails?.particulars?.map((item, index) => (
                                    <tr className="border">
                                        <td className="p-[10px] w-[50px]">{index + 1}</td>
                                        <td className="p-[10px] w-[200px]">{item.product_name}</td>
                                        <td className="p-[10px] w-[100px]">{item.purchase_price}</td>
                                        <td className="p-[10px] w-[100px]">{item.quantity}</td>
                                        <td className="p-[10px] w-[100px]">{item.nt_weight} {item.measurement}</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_percent} %</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_total}</td>
                                        <td className="p-[10px] w-[100px]">{item.grand_total}</td>

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {
                        reqDetails?.particulars?.length > 0 && <div>
                            <div className="flex flex-col items-end ">
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Amount</div>
                                    <div>{reqDetails.total_amount}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Gst Amount</div>
                                    <div>{reqDetails.gst_amount}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Grand Total</div>
                                    <div>{reqDetails.grand_total}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {reqDetails?.order_status === pendingStatus && <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                    <button
                        id="backnav"
                        className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                        onClick={(e) => { e.preventDefault(); setDeniedPopup(true) }}
                    >Denied</button>
                    <button
                        onClick={(e) => { e.preventDefault(); updateReqStatus(approveStatus) }}
                        className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                    >Accept</button>
                </div>
                }
            </div>

            <Popup title={'Purchase Requirement Denied'} isOpen={deniedPopup} closeModal={() => setDeniedPopup(!deniedPopup)}>
                <textarea rows={5} id="reason" className="w-full border outline-none rounded-lg my-[25px]">

                </textarea>
                <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                    <button
                        id="backnav"
                        className="capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                        onClick={(e) => { e.preventDefault(); setDeniedPopup(false) }}
                    >Close</button>
                    <button
                        onClick={(e) => { e.preventDefault(); updateReqStatus(deniedStatus) }}
                        className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                    >Send</button>
                </div>
            </Popup>
        </>
    );
}