import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { gstPercentEndPoint, gstTypeListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function GstValueForm({ getData, formMode, currentGstValue, setFormMode, setCurrentGstValue }) {

    const [gstTypeDropdown, setGstTypeDropdown] = useState([]);

    const schema = yup
        .object({
            gst_type: yup.string().required(),
            percent: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            percent: currentGstValue?.percent,
            gst_type: currentGstValue?.gst_type,
        } : {
            percent: "",
            gst_type: "",
        }
    })

    useEffect(() => {
        getGstTypeDropdownData()
    },[])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const getGstTypeDropdownData = async () => {
        var response = await getAxios({
            url: gstTypeListEndPoint
        })

        if (response !== null) {
            setGstTypeDropdown(response.data.list) 
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createGstValue(data)
            } else {
                updateGstValue(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createGstValue = async (body) => {
        var response = await postAxiosWithToken({
            url: gstPercentEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateGstValue = async (body) => {
        var response = await putAxiosWithToken({
            url: gstPercentEndPoint + currentGstValue?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentGstValue({
            percent: "",
            gst_type: "",
        })
    }

    return (
        <>
            <div>
                <form className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">GST Type</p>
                        <select
                            placeholder="gst type"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="gst_type"
                            {...register('gst_type')}
                        >
                            <option value={""}>--select gst type--</option>
                            {
                                gstTypeDropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.type_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.gst_type?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">GST Percent(%)</p>
                        <input
                            type="text"
                            placeholder="GST Percent"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="percent"
                            id="forminit"
                            {...register('percent')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.percent?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 xl:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >Save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}