import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { brandEndPoint, divisionListEndPoint, categoryListEndPoint, subCategoryListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function BrandForm({ getData, formMode, currentBrand, setFormMode, setCurrentBrand }) {

    const [divisionDropdown, setDivisionDropdown] = useState([]);
    const [categoryDropdown, setCategoryDropdown] = useState([]);
    const [subCategoryDropdown, setSubCategoryDropdown] = useState([]);

    const [selectedDivision, setSelectedDivision] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");

    const schema = yup
        .object({
            brand_name: yup.string().required(),
            division: yup.string().required(),
            category: yup.string().required(),
            sub_category: yup.string().required()
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            brand_name: currentBrand.brand_name,
            sub_category: currentBrand.sub_category,
            division: currentBrand.division,
            category: currentBrand.category,
        } : {
            brand_name: "",
            sub_category: "",
            division: "",
            category: ""
        }
    })

    useEffect(() => {
        getDivisionDropdownData()
    },[])

    useEffect(() => {
        if (formMode === 'update') {
            setSelectedDivision(currentBrand.division)
            setSelectedCategory(currentBrand.category)
            setSelectedSubCategory(currentBrand.sub_category)

            getCategoryDropdownData(currentBrand.division)
            getSubCategoryDropdownData(currentBrand.category)
            
        }
    },[currentBrand])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const getDivisionDropdownData = async () => {
        var response = await getAxios({
            url: divisionListEndPoint
        })

        if (response !== null) {
            setDivisionDropdown(response.data.list) 
        }
    }

    const getCategoryDropdownData = async (value) => {
        var response = await getAxios({
            url: categoryListEndPoint + "?division=" + value
        })

        if (response !== null) {
            setSelectedDivision(value)
            setCategoryDropdown(response.data.list) 
        }
    }

    const getSubCategoryDropdownData = async (value) => {
        var response = await getAxios({
            url: subCategoryListEndPoint + "?division=" + selectedDivision + "&?category=" + value
        })

        if (response !== null) {
            setSelectedCategory(value)
            setSubCategoryDropdown(response.data.list) 
        }
    }

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createBrand(data)
            } else {
                updateBrand(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createBrand = async (body) => {
        var response = await postAxiosWithToken({
            url: brandEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateBrand = async (body) => {
        var response = await putAxiosWithToken({
            url: brandEndPoint + currentBrand?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentBrand({
            brand_name: "",
            division: "",
            category: "",
            sub_category: ""
        })
        setSelectedCategory("")
        setSelectedDivision("")
        setSelectedSubCategory("")
    }

    return (
        <>
            <div>
                <form className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">Division</p>
                        <select
                            placeholder="division"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="division"
                            {...register('division')}
                            value={selectedDivision}
                            onChange={(e) => {getCategoryDropdownData(e.target.value)}}
                        >
                            <option value={""}>--select division--</option>
                            {
                                divisionDropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.division_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.division?.message}</p>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">Category</p>
                        <select
                            placeholder="category"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="category"
                            {...register('category')}
                            value={selectedCategory}
                            onChange={(e) => getSubCategoryDropdownData(e.target.value)}
                        >
                            <option value={""}>--select category--</option>
                            {
                                categoryDropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.category_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.category?.message}</p>
                    </div>
                    <div className="sm:my-[5px] ">
                        <p className="mb-[5px] text-[15px]">Sub Category</p>
                        <select
                            placeholder="sub category"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="sub_category"
                            {...register('sub_category')}
                            value={selectedSubCategory}
                            onChange={(e) => setSelectedSubCategory(e.target.value)}
                        >
                            <option value={""}>--select sub-category--</option>
                            {
                                subCategoryDropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.sub_category_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.sub_category?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Brand Name</p>
                        <input
                            type="text"
                            placeholder="brand name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="brand_name"
                            id="forminit"
                            {...register('brand_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.brand_name?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 lg:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >Save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}