import { Fragment, useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxios, postAxiosWithToken } from "../../../service/apiservice";
import { purchaseRequirementEndPoint, salesExecutivesListEndPoint, statusTypeListEndPoint } from "../../../service/api";
import { datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import Pagination from "../../../components/Pagination";
import { getStatusTypeDropdownData } from "../../../service/commonservice";
import { pendingStatus, purchaseReqModule } from "../../../constant";

export default function SalesExecutive() {


    const navigate = useNavigate();

    const [executiveList, setExecutiveList] = useState([]);
    const [currentExecutive, setCurrentExecutive] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [search, setSearch] = useState("");
    const [searchPhone, setSearchPhone] = useState("");
    const [searchCreatedBy, setSearchCreatedBy] = useState("");
    const [targetStatus, setTargetStatus] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    
    const [statusDropDown, setStatusDropDown] = useState([]);

    useEffect(() => {
        let defaultBody = {
            target_status: targetStatus === "" ? null : JSON.parse(targetStatus),
            search: search,
            phone: searchPhone,
            created_by: searchCreatedBy,
            page: currentPage,
            items_per_page: perPage
        }
        getExecutivesListData(defaultBody)
        getStatusTypeDropdownData({
            module: purchaseReqModule,
            based_on: ""
        }, setStatusDropDown);
    }, [])

    const getExecutivesListData = async (body) => {
        var response = await postAxiosWithToken({
            url: salesExecutivesListEndPoint,
            body: body
        })

        if (response !== null) {
            setExecutiveList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    // Filter Functionalities

    const targetStatusHandler = async (e) => {
        let statusBody = {
            target_status: e.target.value === "" ? null : JSON.parse(e.target.value),
            search: search,
            phone: searchPhone,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setTargetStatus(e.target.value === "" ? null : JSON.parse(e.target.value))
        setCurrentPage(1)
        getExecutivesListData(statusBody)
    }

    const searchHandler = async (e) => {
        let statusBody = {
            target_status: targetStatus === "" ? null : JSON.parse(targetStatus),
            search: e.target.value,
            phone: searchPhone,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setSearch(e.target.value)
        setCurrentPage(1)
        getExecutivesListData(statusBody)
    }

    const searchPhoneHandler = async (e) => {
        let statusBody = {
            target_status: targetStatus === "" ? null : JSON.parse(targetStatus),
            search: search,
            phone: e.target.value,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: perPage
        }
        setSearchPhone(e.target.value)
        setCurrentPage(1)
        getExecutivesListData(statusBody)
    }

    const searchCreatedByHandler = async (e) => {
        let statusBody = {
            target_status: targetStatus === "" ? null : JSON.parse(targetStatus),
            search: search,
            phone: searchPhone,
            created_by: e.target.value,
            page: 1,
            items_per_page: perPage
        }
        setSearchCreatedBy(e.target.value)
        setCurrentPage(1)
        getExecutivesListData(statusBody)
    }



    // Pagination

    const pageHandler = async (page) => {
        let statusBody = {
            target_status: targetStatus === "" ? null : JSON.parse(targetStatus),
            search: search,
            phone: searchPhone,
            created_by: searchCreatedBy,
            page: page,
            items_per_page: perPage
        }
        setCurrentPage(page)
        getExecutivesListData(statusBody)
    }

    const entriesHandler = async (count) => {
        let statusBody = {
            target_status: targetStatus === "" ? null : JSON.parse(targetStatus),
            search: search,
            phone: searchPhone,
            created_by: searchCreatedBy,
            page: 1,
            items_per_page: count
        }
        setPerPage(count)
        setCurrentPage(1)
        getExecutivesListData(statusBody)
    }


    return (
        <>
            <div className="relative">
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll overflow-y-visible relative">

                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr>
                                <td className="p-[10px] w-[200px]">Executive Name</td>
                                <td className="p-[10px] w-[200px]">Mobile No</td>
                                <td className="p-[10px] w-[100px] text-center">Customers</td>
                                <td className="p-[10px] w-[200px]">Ongoing Target</td>
                                <td className="p-[10px] w-[100px] text-center">Targets</td>
                                <td className="p-[10px] w-[100px] text-center">Achieved</td>
                                <td className="p-[10px] w-[100px] text-center">Remaining</td>
                                <td className="p-[10px] w-[150px]">From</td>
                                <td className="p-[10px] w-[150px]">To</td>
                                <td className="p-[10px] w-[150px]">Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr className="border">
                                <td className="p-[10px] min-w-[200px]">
                                    <input
                                        type="text"
                                        value={search}
                                        onChange={searchHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[200px]">
                                    <input
                                        type="text"
                                        value={searchPhone}
                                        onChange={searchPhoneHandler}
                                        className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px] min-w-[100px]"></td>
                                <td className="p-[10px] min-w-[200px]"></td>
                                <td className="p-[10px] min-w-[100px]"></td>
                                <td className="p-[10px] min-w-[100px]"></td>
                                <td className="p-[10px] min-w-[100px]"></td>
                                <td className="p-[10px] min-w-[150px]"></td>
                                <td className="p-[10px] min-w-[150px]"></td>
                                <td className="p-[10px] min-w-[150px]"></td>
                            </tr>
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={11}>Loading...</td>
                                </tr> :
                                    executiveList.length === 0 ? <tr><td className="text-center border" colSpan={11}>No Data</td></tr> :
                                        executiveList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                
                                                <td className="p-[10px] capitalize flex gap-5 items-center">
                                                    <p onClick={() => { localStorage.setItem('sales_executive_id', item.user); navigate('view-sales-executive')}} className="text-sky-600 cursor-pointer">{item.first_name}</p>
                                                </td>
                                                <td className="p-[10px]">{item.phone}</td>
                                                <td className="p-[10px] text-center">{item.customers}</td>
                                                <td className="p-[10px] capitalize">{item.target_type_name??"-"}</td>
                                                <td className="p-[10px] text-center">
                                                    {item.target??"-"}
                                                </td>
                                                
                                                <td className="p-[10px] text-center">
                                                    {item.achieved??"-"}
                                                </td>
                                                <td className="p-[10px] text-center">
                                                    {item.remaining??"-"}
                                                </td>
                                                <td className="p-[10px]">
                                                    {item.from_date??"-"}
                                                </td>
                                                <td className="p-[10px]">
                                                    {item.to_date??"-"}
                                                </td>
                                                <td className={`${item.status !== pendingStatus ? "text-white": ""} px-[10px] capitalize `}>
                                                    <div style={{ backgroundColor: item.status_color }} className="px-[15px] rounded-lg text-center py-[5px]">
                                                        {item.status_name??"-"}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className="mb-[50px]">
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={pageHandler}
                        entries={perPage}
                        setEntries={entriesHandler}
                    />
                </div>
            </div>


        </>
    );
}