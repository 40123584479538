import { useEffect, useState } from "react";
import LogoImage from "../../assets/images/logo.svg";
import { getAxios } from "../../service/apiservice";
import { activeUserPermissionEndPoint } from "../../service/api";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

export default function SideBar({ isDark, classess, position }) {

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const [activeMenu, setActiveMenu] = useState('');
    const [activeMenuDropdown, setActiveMenuDropdown] = useState('');

    const [menuList, setMenuList] = useState([])

    useEffect(() => {
        getMenuList()
    }, [])

    const getMenuList = async () => {
        var response = await getAxios({
            url: activeUserPermissionEndPoint
        })

        if (response !== null) {
            if (response.status === 200) {
                setMenuList(response.data)
            }
        }
    }

    useEffect(() => {
        highlightMenu()
    }, [pathname, menuList])

    const highlightMenu = () => {
        for (var i of menuList) {
            if (i.menu_list !== undefined) {
                for (var j of i.menu_list) {
                    if (j.menu_path === pathname) {
                        setActiveMenuDropdown(i.id)
                        setActiveMenu('menu'+i.id)
                    }
                }
            }
        }
    }
    return (
        <>
            <div className={`${isDark ? "bg-dark-bg" : "bg-white"} ${classess}`}>
                <div className={`${position === 'left' ? "-right-7" : "-left-7"} absolute top-0 lg:hidden`}
                    onClick={() => {
                        document.getElementById('menubar').className = 'hidden lg:block'
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-6 -6 24 24">
                        <path fill="currentColor"
                            d="m7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485L2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535l3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z" />
                    </svg>
                </div>
                <div onClick={() => navigate('/')} className="h-[55px] w-[100px] mx-auto mt-[25px] bg-white">
                    <img src={LogoImage} alt="no-logo" />
                </div>

                <div className={`${isDark ? "bg-dark-bg" : "bg-white"} overflow-y-scroll overflow-x-hidden h-[85vh] my-[25px] py-[10px]`}>
                    {
                        menuList.map((item) => (

                            <div
                                key={item.id}
                                className={`${isDark ? "bg-dark-bg" : "bg-white"} my-3 md:my-0 py-[10px]`}>
                                <div
                                    className={`${activeMenuDropdown === item.id ? isDark ? "text-secondary" : "text-primary" : ""} font-[500] text-[20px] px-[15px] capitalize font-500 text-[15px] cursor-pointer`}
                                >{item.menu_group_name}</div>

                                <div
                                 onClick={() => {
                                    document.getElementById('menubar').className = 'hidden md:block'
                                }}
                                    id={"menu" + item.id}
                                    className={`mt-[10px] w-full`}>
                                    {
                                        item.menu_list.map((subItem) => (
                                            <NavLink 
                                            key={subItem.menu_path} to={subItem.menu_path}
                                            className={({ isActive, isPending }) =>
                                        isActive ? isDark ? "text-primary" : "text-primary" : ""
                                    }>
                                                <div
                                                    className={` ${pathname.split('/').includes(subItem.menu_name.toString().toLowerCase().split(' ').join('-')) ? "bg-secondary" : ""} py-[5px] relative px-[15px] my-3 hover:text-primary cursor-pointer capitalize flex gap-3`}
                                                    key={subItem.id}
                                                >   
                                                    <div className="w-[25px] h-[25px] text-primary"><img width="100%" src={subItem.menu_icon} alt={subItem.menu_name} /></div>
                                                    <p>{subItem.menu_name}</p>
                                                    <div className={`${pathname.split('/').includes(subItem.menu_name.toString().toLowerCase().split(' ').join('-')) ? "block" : "hidden"} w-[5px] h-[25px] bg-primary absolute right-[7px] rounded-l-lg`}></div>
                                                </div>
                                            </NavLink>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}