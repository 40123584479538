import { useNavigate } from "react-router-dom";
import { purchaseReturnEndPoint } from "../../../service/api";
import { getAxios } from "../../../service/apiservice";
import { datetimetodate } from "../../../utilities/datetime";
import { useEffect, useState } from "react";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { downloadImageAsJpeg, downloadImageAsPng } from "../../../utilities/download";

export default function ViewPurchaseReturnSummary({ summary = [] }) {
    const navigate = useNavigate();

    const [returnDetails, setReturnDetails] = useState({});

    const [returnImageDetails, setReturnImageDetails] = useState({});

    const [activeDetail, setActiveDetail] = useState(null);
    const [downloadIndex, setDownloadIndex] = useState(null);

    const [summaryList, setSummaryList] = useState(true);

    useEffect(() => {
        console.log(summary)
        if (summary.length > 0) {
            getPurchaseReturnDetails(summary[0].id, 0)
        }
    }, [summary])

    const getPurchaseReturnDetails = async (id, index) => {
        var response = await getAxios({
            url: purchaseReturnEndPoint + id + '/'
        })

        if (response != null) {
            setReturnDetails(response.data);
            setActiveDetail(index);
        }
    }

    return (
        <>
            <div className="my-[50px]">{console.log(summary)}
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Purrchase Return Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Return Id</p>
                            <div>{returnDetails?.return_id}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Total Items</p>
                            <div>{returnDetails?.total_items}</div>
                        </div>
                        <div className="my-[5px]" >
                            <p className="mb-[5px] text-[15px] font-[600]">Amount</p>
                            <div className="capitalize w-fit">{returnDetails?.amount}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">GST Amount</p>
                            <div>{returnDetails?.gst_amount}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Created By</p>
                            <div>{returnDetails?.created_by_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Created At</p>
                            <div>{returnDetails.created_at && datetimetodate(returnDetails.created_at)}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Supplier Details</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier</p>
                            <div>{returnDetails?.supplier_name}</div>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px] font-[600]">Supplier Id</p>
                            <div>{returnDetails?.supplier_id}</div>
                        </div>
                    </div>
                </div>
                <div className="border p-[15px] rounded-xl shadow-lg mb-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Particular Details</p>
                    <table className="w-full">
                        <thead className="border bg-primary text-white">
                            <tr className="border">
                                <td className="p-[10px] w-[50px]">S.No</td>
                                <td className="p-[10px] w-[200px]">Product</td>
                                <td className="p-[10px] w-[100px]">Rate</td>
                                <td className="p-[10px] w-[100px]">Order Qty</td>
                                <td className="p-[10px] w-[100px]">Net. Weight</td>
                                <td className="p-[10px] w-[100px]">GST Total</td>
                                <td className="p-[10px] w-[100px]">Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                returnDetails?.particulars?.map((item, index) => (
                                    <tr className="border">
                                        <td className="p-[10px] w-[50px]">{index + 1}</td>
                                        <td className="p-[10px] w-[200px]">{item.product_name}</td>
                                        <td className="p-[10px] w-[100px]">{item.purchase_price}</td>
                                        <td className="p-[10px] w-[100px]">{item.quantity}</td>
                                        <td className="p-[10px] w-[100px]">{item.nt_weight} {item.measurement}</td>
                                        <td className="p-[10px] w-[100px]">{item.gst_amount}</td>
                                        <td className="p-[10px] w-[100px]">{item.total_amount}</td>

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {
                        returnDetails?.particulars?.length > 0 && <div>
                            <div className="flex flex-col items-end ">
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Gst Amount</div>
                                    <div>{returnDetails.gst_amount}</div>
                                </div>
                                <div className="flex w-[275px] justify-between items-center border p-[5px]">
                                    <div>Grand Total</div>
                                    <div>{returnDetails.amount}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className={`${summaryList ? 'translate-x-0' : 'translate-x-[300px] sm:translate-x-[500px]'} z-[3] cursor-pointer fixed top-0 right-0 flex items-center transform transition duration-200 ease-in-out`}>
                    <div onClick={() => setSummaryList(!summaryList)} className="w-[15px] rounded-l uppercase h-fit border border-primary break-all bg-secondary p-[5px] text-[9px]">summary Details</div>

                    <div className="overflow-y-scroll h-[95vh] bg-white w-[300px] sm:w-[500px]">
                        <div className="flex items-center justify-between m-[5px]">
                            <p className="text-[18px] font-[600]">Purchase Return</p>
                            {/* <div className="border border-primary bg-secondary text-primary rounded-lg px-[10px] py-[5px] ">Download All</div> */}
                        </div>
                        <div className="">
                            {
                                summary.map((item, index) => (
                                    <div onClick={() => getPurchaseReturnDetails(item.id, index)} className={`${activeDetail === index ? 'bg-primary text-white' : ''} flex items-center justify-between p-[15px]`} key={'summary' + index}>
                                        <div>
                                            <div className="capitalize">{item.return_id}</div>
                                            <div>{item.entry_no}</div>
                                            <div>{item.created_by && datetimetodate(item.created_by, 3)}</div>
                                        </div>

                                        <div className="flex gap-3">
                                            {/* <div className={`${activeDetail === index ? "text-white" : "text-primary"}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M214.67 72H200V40a8 8 0 0 0-8-8H64a8 8 0 0 0-8 8v32H41.33C27.36 72 16 82.77 16 96v80a8 8 0 0 0 8 8h32v32a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-32h32a8 8 0 0 0 8-8V96c0-13.23-11.36-24-25.33-24ZM72 48h112v24H72Zm112 160H72v-48h112Zm40-40h-24v-16a8 8 0 0 0-8-8H64a8 8 0 0 0-8 8v16H32V96c0-4.41 4.19-8 9.33-8h173.34c5.14 0 9.33 3.59 9.33 8Zm-24-52a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z" /></svg>
                                        </div> */}
                                            <div className="relative">
                                                <div onClick={() => { getPurchaseReturnDetails(item.id, index); setDownloadIndex(index) }} className={`${activeDetail === index ? "text-white" : "text-primary"}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m12 15.577l-3.538-3.539l.707-.719L11.5 13.65V5h1v8.65l2.33-2.33l.708.718L12 15.577ZM6.615 19q-.69 0-1.152-.462Q5 18.075 5 17.385v-2.423h1v2.423q0 .23.192.423q.193.192.423.192h10.77q.23 0 .423-.192q.192-.193.192-.423v-2.423h1v2.423q0 .69-.462 1.152q-.463.463-1.153.463H6.615Z" /></svg>
                                                </div>

                                                <div className={`${downloadIndex === index ? "block" : "hidden"} absolute right-0 border rounded-lg shadow-lg w-[100px] bg-white text-black`}>
                                                    <div onClick={() => downloadImageAsPng("downloadasimage", returnImageDetails?.purchase_order_id)} className="p-[5px]">PNG</div>
                                                    <div onClick={() => downloadImageAsJpeg("downloadasimage", returnImageDetails?.purchase_order_id)} className="p-[5px]">JPEG</div>
                                                    {/* <div onClick={() => downloadImage('pdf')} className="p-[5px]">SVG</div> */}
                                                </div>
                                            </div>
                                            <div onClick={() => getPurchaseReturnDetails(item.id, index)} className={`${activeDetail === index ? "text-white" : "text-primary"}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M3 13c3.6-8 14.4-8 18 0" /><path fill="currentColor" d="M12 17a3 3 0 1 1 0-6a3 3 0 0 1 0 6Z" /></g></svg>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}