import { useEffect, useRef, useState } from "react";
import { customerProductEndPoint, productListByCustomerEndPoint } from "../../../service/api";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Popup";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function AddCustomerProducts({ customerProductList = [], isLoading = true, getData }) {

    const navigate = useNavigate();

    const gstDropDowRef = useRef(null);

    const [gstList, setGstList] = useState([]);

    const [productDropDown, setProductDropDown] = useState([]);

    const [currentProduct, setCurrentProduct] = useState({});

    const [errors, setErrors] = useState({});

    const [selectedProduct, setSelectedProduct] = useState({
        product: "",
        product_name: "",
        division_name: "",
        category_name: "",
        sub_category_name: "",
        brand_name: "",
        measurement: "",
        nt_weight: "",
        amount: 0.0,
        gst_value: [],
        gst_percent: 0,
        gst_amount: 0.0,
        discount_percent: 0.0,
        discount_amount: 0.0,
        grand_total: 0.0
    });
    const [selectedGstValue, setSelectedGstValue] = useState([]);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getProductListDropDown();
    }, [customerProductList])

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            addProduct(event)
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            clearData()
        }
    };


    useKeyPress(['e', 'Enter'], onKeyPress);

    const getProductListDropDown = async () => {
        var response = await getAxios({
            url: productListByCustomerEndPoint + localStorage.getItem('customer_form_id') + "/"
        })

        if (response !== null) {
            setProductDropDown(response.data.list)
        }
    }

    const addSeletedGstValue = (value) => {

        if (selectedGstValue.length < 2 && !selectedGstValue.includes(value)) {
            setSelectedGstValue([...selectedGstValue, value])
        } else {
            const data = [...selectedGstValue];
            data.splice(0, 1);
            data.push(value)
            setSelectedGstValue(data);
        }
    }

    const removeGstValue = (index) => {
        const data = [...selectedGstValue];
        data.splice(index, 1);
        setSelectedGstValue(data);
    }

    const addProduct = (e) => {
        e.preventDefault();

        if (selectedProduct.product !== "" && selectedProduct.gst_value != []) {

            let createBody = {
                product: selectedProduct.id,
                gst_values: selectedGstValue,
                customer: localStorage.getItem('customer_form_id'),
                discount_percent: selectedProduct.discount_percent
            }

            createProduct(createBody)

        }
    }

    const createProduct = async (body) => {
        var response = await postAxiosWithToken({
            url: customerProductEndPoint,
            body: body
        })

        if (response !== null) {
            toast.success(response.message)
            clearData()
            getData()
        }
    }

    useEffect(() => {
        window.addEventListener('click', function handleClickOutsideBox(event) {
            const box = document.getElementById('gstinput');

            if (!box?.contains(event.target)) {
                if (gstDropDowRef.current) {

                    gstDropDowRef.current.className = 'hidden'
                }
            }
        });
    })

    useEffect(() => {
        addGstValue()
    }, [selectedGstValue])

    const addGstValue = () => {
        var totalPercent = 0
        for (var i of Object.values(selectedGstValue)) {
            totalPercent = (Number(i.value) + Number(totalPercent)).toFixed(2)
        }

        var gstPrice = selectedProduct.amount * (parseFloat(totalPercent) / 100)
        var grandTotal = selectedProduct.amount + gstPrice
        setSelectedProduct({
            ...selectedProduct,
            gst_percent: isNaN(totalPercent) ? 0.0 : totalPercent,
            gst_amount: isNaN(gstPrice.toFixed(2)) ? 0.0 : gstPrice.toFixed(2),
            grand_total: grandTotal.toFixed(2)
        })
    }

    const clearData = () => {
        setSelectedProduct({
            product: "",
            product_name: "",
            division_name: "",
            category_name: "",
            sub_category_name: "",
            brand_name: "",
            measurement: "",
            nt_weight: "",
            amount: 0.0,
            gst_value: [],
            gst_percent: 0,
            gst_amount: 0.0,
            discount_percent: 0.0,
            discount_amount: 0.0,
            grand_total: 0.0
        });
        setSelectedGstValue([]);
    }

    const onValueUpdate = (text) => {
        const { name, value } = text;

        if (name === 'discount_percent') {

            let discountPercent = isNaN(parseFloat(value) / 100) ? 0.0 : parseFloat(value) / 100
            let discountAmount = discountPercent * selectedProduct.mrp
            let amount = selectedProduct.mrp - discountAmount.toFixed(2)
            let gstAmount = (isNaN(amount) ? 0.0 : amount) * (selectedProduct.gst_percent / 100)

            setSelectedProduct((prevState) => ({
                ...prevState,
                [name]: value,
                discount_amount: isNaN(discountAmount.toFixed(2)) ? 0.00 : discountAmount.toFixed(2),
                amount: amount.toFixed(2),
                gst_amount: gstAmount.toFixed(2),
                grand_total: ((isNaN(amount) ? 0.0 : amount) + (isNaN(gstAmount) ? 0.0 : gstAmount)).toFixed(2)
            }))
        }
    }

    const deleteProduct = async () => {
        var response = await deleteAxiosWithToken({
            url: customerProductEndPoint + currentProduct.id + '/'
        })

        if (response !== null) {
            getData();
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    return (
        <div>
            <form onSubmit={addProduct}>
                <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                    <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Add Product</p>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div className="sm:my-[5px] ">
                            <p className="mb-[5px] text-[15px]">Product</p>
                            <select
                                placeholder="product"
                                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                name="product"
                                onChange={(e) => {
                                    var tempData = JSON.parse(e.target.value)
                                    setSelectedProduct({ ...tempData, quantity: 1, gst_percent: 0, amount: tempData.mrp });

                                    let tempGstList = []
                                    for (var i of Object.entries(tempData.gst_value)) {
                                        var tempDict = {}
                                        tempDict['key'] = i[0]
                                        tempDict['value'] = i[1]
                                        tempGstList.push(tempDict)
                                    }
                                    setGstList(tempGstList);
                                }}
                                value={selectedProduct.product}
                            >
                                <option value={""}>--select product--</option>
                                {
                                    productDropDown.map((option) => (
                                        <option className="capitalize" key={"product" + option.product_name} value={JSON.stringify(option)}>{option.product_name}</option>
                                    ))
                                }
                            </select>
                            <p className="text-red-500 font-[400] text-[13px]">{errors.product}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Division</p>
                            <input
                                type="text"
                                placeholder="division"
                                className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="division"
                                disabled
                                value={selectedProduct?.division_name}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.division}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Category</p>
                            <input
                                type="text"
                                placeholder="category"
                                className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="category"
                                disabled
                                value={selectedProduct?.category_name}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.category}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Sub Category</p>
                            <input
                                type="text"
                                placeholder="sub category"
                                className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="sub_category"
                                disabled
                                value={selectedProduct?.sub_category_name}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.sub_category}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Brand</p>
                            <input
                                type="text"
                                placeholder="brand"
                                className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="brand"
                                disabled
                                value={selectedProduct?.brand_name}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.brand}</p>
                        </div>
                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Unit</p>
                            <input
                                type="text"
                                placeholder="unit"
                                className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="measurement"
                                disabled
                                value={String(selectedProduct?.nt_weight) + String(selectedProduct?.measurement)}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.unit}</p>
                        </div>

                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">MRP</p>
                            <input
                                type="text"
                                placeholder="MRP"
                                className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="amount"
                                disabled
                                value={selectedProduct?.amount}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.amount}</p>
                        </div>


                        <div className="my-[5px] relative">
                            <p className="mb-[5px] text-[15px]">Gst</p>
                            <div id="gstinput">
                                <div onClick={() => {
                                    if (document.getElementById('gstdropdown').className === 'hidden') {
                                        document.getElementById('gstdropdown').className = 'block absolute top-[85px] bg-white shadow-xl border border-gray-300 w-full rounded-lg overflow-hidden'
                                    } else {
                                        document.getElementById('gstdropdown').className = 'hidden'
                                    }
                                }} className="sm:mb-[5px] border flex items-center border-gray-300 rounded-lg w-full h-[48px] px-[5px] text-[17px] outline-none">
                                    {
                                        selectedGstValue.length > 0 ? selectedGstValue.map((item, index) => (
                                            <div onClick={() => removeGstValue(index)} className="cursor-pointer p-[5px] bg-sky-200 mx-1 my-2 p-1 px-3 rounded-md hover:bg-red-600 hover:text-white">{item.key} {item.value} %</div>
                                        )) : <div className="flex items-center text-gray-400 ml-2">gst</div>
                                    }
                                </div>
                                <div id="gstdropdown" ref={gstDropDowRef} className="hidden ">
                                    {
                                        gstList.map((item) => (
                                            <div onClick={() => { addSeletedGstValue(item) }} className={`${selectedGstValue.includes(item) ? 'bg-sky-200' : ''} cursor-pointer p-[10px] hover:bg-sky-200`}>{item.key} {item.value} %</div>
                                        ))
                                    }
                                </div>
                            </div>
                            <p className="text-red-500 font-[400] text-[13px]">{errors.quantity}</p>
                        </div>

                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">GST Amount</p>
                            <input
                                type="text"
                                placeholder="gst amount"
                                className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="gst_amount"
                                disabled
                                value={selectedProduct.gst_amount}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.gst_amount}</p>
                        </div>

                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Discount Percent</p>
                            <input
                                type="text"
                                placeholder="discount percent"
                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="discount_percent"
                                value={selectedProduct.discount_percent}
                                onChange={(e) => onValueUpdate(e.target)}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.discount_percent}</p>
                        </div>

                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Discount Amount</p>
                            <input
                                type="text"
                                placeholder="discount amount"
                                className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="discount_amount"
                                disabled
                                value={selectedProduct.discount_amount}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.discount_amount}</p>
                        </div>

                        <div className="my-[5px]">
                            <p className="mb-[5px] text-[15px]">Grand Total</p>
                            <input
                                type="text"
                                placeholder="grand total"
                                className="cursor-not-allowed sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                name="grand_total"
                                disabled
                                value={selectedProduct.grand_total}
                            />
                            <p className="text-red-500 font-[400] text-[13px]">{errors.grand_total}</p>
                        </div>
                    </div>
                    {selectedGstValue.length > 0 && <div className="min-[423px]:flex gap-4 mt-0 sm:mt-[35px]">

                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); }}
                            className="max-[423px]:mt-[10px] border border-primary capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>

                        <button
                            type="submit"
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >Add<span className="lowercase">(enter)</span></button>
                    </div>}
                </div>
            </form>

            <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
                <table className="w-full">
                    <thead className="border bg-primary text-white">
                        <tr>
                            <td className="p-[10px]"></td>
                            <td className="p-[10px] w-[400px]">Item Name</td>
                            <td className="p-[10px] w-[200px]">Net Weight</td>
                            <td className="p-[10px] w-[200px]">MRP</td>
                            <td className="p-[10px] w-[200px]">Discount %</td>
                            <td className="p-[10px] w-[200px]">Discount ₹</td>
                            <td className="p-[10px] w-[200px]">GST %</td>
                            <td className="p-[10px] w-[200px]">GST ₹</td>
                            <td className="p-[10px] w-[200px]">Selling Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={9}>Loading...</td>
                            </tr> :
                                customerProductList.length === 0 ? <tr><td className="text-center border" colSpan={9}>No Data</td></tr> :
                                    customerProductList.map((item, index) => (
                                        <tr key={item.id} className="border">
                                            <td className="p-[10px]">
                                                <div onClick={() => { setIsDeleteOpen(true); setCurrentProduct(item) }} className="cursor-pointer hover:scale-125">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                </div>
                                            </td>
                                            <td className="p-[10px] capitalize">
                                                <p onClick={() => { localStorage.setItem('product_form_id', item.id); navigate('/products/product/view-product') }} className="text-sky-600 cursor-pointer">{item.product_name}</p>
                                            </td>
                                            <td className="p-[10px]">{item.nt_weight} {item.unit}</td>
                                            <td className="p-[10px] capitalize">{item.mrp}</td>
                                            <td className="p-[10px]">{item.discount_percent} %</td>
                                            <td className="p-[10px]">₹ {item.discount_amount}</td>
                                            <td className="p-[10px]">{item.gst_percent} %</td>
                                            <td className="p-[10px]">₹ {item.gst_amount}</td>
                                            <td className="p-[10px]">₹ {item.grand_total}</td>
                                        </tr>
                                    ))
                        }

                    </tbody>
                </table>
            </div>

            <div>
                <Popup title={'Delete product'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentProduct?.product_name} product?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteProduct() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </div>
    );
}